
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import 'assets/select.css';
@import 'assets/menu/pure-min.css';
@import 'assets/menu/1.18.13.css';
@import "~bootstrap/dist/css/bootstrap.min.css";
@import 'assets/circle.css';
@import "assets/reset.css";

.btn-block+.btn-block {
    margin-top: 0rem;
}