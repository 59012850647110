
/*--------------------- font-face ---------------------*/
@font-face {
	font-family: ourfont;
  	src:url('font/OpenSans-Regular.eot'); /* IE9 Compat Modes */
  	src:url('font/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('font/OpenSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('font/OpenSans-Regular.woff') format('woff'), /* Pretty Modern Browsers */
		url('font/OpenSans-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
		url('font/OpenSans-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
	}

/*--------------------- temp hide - demo ---------------------*/
.header_bar #p2,
.header_bar #p3{
	display:none;
}
/*--------------------- color : orange (images - btn icon) ---------------------*/

.p_select{
	background-image: url(img/photo_mask_select.png);
    background-repeat: no-repeat;
    background-size: cover;
	}

.p_normal_big{
	background-image: url(img/photo_mask_big.png);
    background-repeat: no-repeat;
    background-size: cover;
	}

.p_normal{
	background-image: url(img/photo_mask_small.png);
    background-repeat: no-repeat;
    background-size: cover;
	}

.p_normal_grey{
	background-image: url(img/photo_mask_small_grey.png);
    background-repeat: no-repeat;
    background-size: cover;
	}

.p_normal_grey_2{
	background-image: url(img/photo_mask_small_grey_2.png);
    background-repeat: no-repeat;
    background-size: cover;
	}

.p_pending{
	background-image: url(img/photo_small_pending.png);
    background-repeat: no-repeat;
    background-size: cover;
	}

.p_cancelpending{
	background-image: url(img/photo_small_pending.png);
    background-repeat: no-repeat;
    background-size: cover;
	}

.p_approved{
	background-image: url(img/photo_small_approved.png);
    background-repeat: no-repeat;
    background-size: cover;
	}

.p_rejected{
	background-image: url(img/photo_small_rejected.png);
    background-repeat: no-repeat;
    background-size: cover;
	}

.m_home{
	background:url(img/m_home.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_leave{
	background:url(img/m_leave.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_appointment{
	background:url(img/m_appointment.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_calendar{
	background:url(img/m_calendar.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_appraisal{
	background:url(img/m_appraisal.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_profile{
	background:url(img/m_profile.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_report{
	background:url(img/m_report.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_ot{
	background:url(img/m_ot.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_inouttime{
	background:url(img/m_inouttime.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_approvalcentre{
	background:url(img/m_approvalcentre.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_teaminfo{
	background:url(img/m_teaminfo.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_leave_delegation_approval{
	background:url(img/m_leave_delegation_approval.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_request{
	background:url(img/m_request.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
	}

.m_ot_claim{
	background:url(img/m_ot_claim.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
	}

.m_roster{
	background:url(img/m_roster.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_time_off{
	background:url(img/m_time_off.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
	}
.m_ot_balance{
	background:url(img/m_ot_balance.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
	}

.m_roster{
	background:url(img/m_roster.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_claim{
	background:url(img/m_claim.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_claim_maintenance{
	background:url(img/m_claim_maintenance.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.m_ot{
	background:url(img/m_ot.png) no-repeat center;
	background-size:contain;
	height: 36px;
	width: 100%;
}

.mm_application{
	background: #fff url(img/mm_application.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_balanceinquiry{
	background: #fff url(img/mm_balanceinquiry.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_leaveapplicationinquiry{
	background: #fff url(img/mm_leaveapplicationinquiry.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_pendingapproval{
	background: #fff url(img/mm_pendingapproval.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_applicationinquiry{
	background: #fff url(img/mm_applicationinquiry.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_punch_inquiry{
	background: #fff url(img/mm_punch_inquiry.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_approvallog{
	background: #fff url(img/mm_approvallog.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_my_profile{
	background: #fff url(img/mm_myprofile.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_profile_amend_inquiry{
	background: #fff url(img/mm_profileamendinquiry.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_bday{
	background: #fff url(img/mm_bday.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_newjoins{
	background: #fff url(img/mm_newjoins.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_LTR{
	background: #fff url(img/mm_LTR.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_payslip{
	background: #fff url(img/mm_payslip.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_taxform{
	background: #fff url(img/mm_taxform.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_ALB{
	background: #fff url(img/mm_ALB.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_delegation_setting{
	background: #fff url(img/mm_delegation_setting.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_team_roster{
	background: #fff url(img/mm_team_roster.png) no-repeat 7px 50%;
    background-size: 18px;
	}
.mm_roster_change{
	background: #fff url(img/mm_roster_change.png) no-repeat 7px 50%;
    background-size: 18px;
	}
.mm_roster_change_inquiry{
	background: #fff url(img/mm_roster_change_inquiry.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_commission{
	background: #fff url(img/mm_commission.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_duty_roster_import_and_export{
	background: #fff url(img/mm_duty_roster_import_and_export.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_claim_application{
	background: #fff url(img/mm_claim_application.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_claim_application_inquiry{
	background: #fff url(img/mm_claim_application_inquiry.png) no-repeat 7px 50%;
    background-size: 18px;
	}/*
                        <a rel="external" href="claim_type_maintenance.html"><div class="btn_menu_item mm_claim_type_maintenance"> Claim Type</div></a>
                        <a rel="external" href="claim_analysis_maintenance.html"><div class="btn_menu_item mm_claim_analysis_maintenance"> Analysis</div></a>
                        <a rel="external" href="claim_project_maintenance.html"><div class="btn_menu_item mm_claim_project_maintenance"> Project</div></a>
                        <a rel="external" href="claim_record_report.html"><div class="btn_menu_item mm_claim_record_report"> Claim
						*/
.mm_claim_department_maintenance{
	background: #fff url(img/mm_claim_department_maintenance.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_claim_position_maintenance{
	background: #fff url(img/mm_claim_position_maintenance.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_claim_staff_maintenance{
	background: #fff url(img/mm_claim_staff_maintenance.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_claim_type_maintenance{
	background: #fff url(img/mm_claim_type_maintenance.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_claim_analysis_maintenance{
	background: #fff url(img/mm_claim_analysis_maintenance.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_claim_project_maintenance{
	background: #fff url(img/mm_claim_project_maintenance.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_claim_record_report{
	background: #fff url(img/mm_claim_record_report.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_report_attendance{
	background: #fff url(img/mm_report_attendance.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_report_rest_date_and_lateness{
	background: #fff url(img/mm_report_rest_date_and_lateness.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.mm_claim_form_report{
	background: #fff url(img/mm_claim_form_report.png) no-repeat 7px 50%;
    background-size: 18px;
	}

.t_my_applications{
	background:url(img/title_my_applications.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_systemintro{
	background:url(img/title_systemintro.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_outstanding{
	background:url(img/title_outstanding.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_bday{
	background:url(img/title_bday.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_newjoins{
	background:url(img/title_newjoins.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_notice{
	background:url(img/title_notice.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_teamLTR{
	background:url(img/title_teamLTR.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_teamALB{
	background:url(img/title_teamALB.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_company{
	background:url(img/title_company.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_leaveapplicationinquiry{
	background:url(img/title_leaveapplicationinquiry.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_application{
	background:url(img/title_leaveapplication.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_pendingapproval{
	background:url(img/title_pendingapproval.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_approvalapplicationinquiry{
	background:url(img/title_approvalapplicationinquiry.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_inouttime{
	background:url(img/title_inouttime.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_punch_inquiry{
	background:url(img/title_punch_inquiry.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_approvallog{
	background:url(img/title_approvallog.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_personalprofile{
	background:url(img/title_personalprofile.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_payslip{
	background:url(img/title_payslip.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_taxform{
	background:url(img/title_taxform.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_calendar{
	background:url(img/title_calendar.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_edit{
	background:url(img/title_edit.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_addnew{
	background:url(img/title_addnew.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_changepersonalphoto{
	background:url(img/title_changepersonalphoto.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_profileamendinquiry{
	background:url(img/title_profileamendinquiry.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

    /* ========= personal profile ========= */

.t_general{
	background:url(img/title_general.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_employment{
	background:url(img/title_employment.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_address{
	background:url(img/title_address.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_bank{
	background:url(img/title_bank.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_dependents{
	background:url(img/title_dependents.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_qualification{
	background:url(img/title_qualification.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_ot_claim{
	background:url(img/title_ot_claim.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_time_off{
	background:url(img/title_time_off.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_ot_balance{
	background:url(img/title_ot_balance.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_duty_roster_import_export{
	background:url(img/title_duty_roster_import_export.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_roster_change{
	background:url(img/title_roster_change.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_roster_change_inquiry{
	background:url(img/title_roster_change_inquiry.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_report_attendance{
	background:url(img/title_report_attendance.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_report_rest_date_and_lateness{
	background:url(img/title_report_rest_date_and_lateness.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

    /* ========= delegation ========= */

.t_delegationsetting{
	background:url(img/title_delegation_setting.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

    /* ========= roster ========= */

.t_team_roster{
	background:url(img/title_team_roster.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_commission{
	background:url(img/title_commission.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_month_roster{
	background:url(img/title_month_roster.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_shift_schedule_summary{
	background:url(img/title_shift_schedule_summary.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_shift_code_summary{
	background:url(img/title_shift_code_summary.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_working_hour_summary{
	background:url(img/title_working_hour_summary.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_leave_summary{
	background:url(img/title_leave_summary.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}
.t_time{
	background:url(img/title_time.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

    /* ========= claim ========= */

.t_claim_application{
	background:url(img/title_claim_application.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_claim_application_inquiry{
	background:url(img/title_claim_application_inquiry.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_claim_department_maintenance{
	background:url(img/title_claim_department_maintenance.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_claim_position_maintenance{
	background:url(img/title_claim_position_maintenance.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_claim_staff_maintenance{
	background:url(img/title_claim_staff_maintenance.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_claim_type_maintenance{
	background:url(img/title_claim_type_maintenance.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_claim_analysis_maintenance{
	background:url(img/title_claim_analysis_maintenance.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_claim_project_maintenance{
	background:url(img/title_claim_project_maintenance.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_claim_record_report{
	background:url(img/title_claim_record_report.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_claim_form_report{
	background:url(img/title_claim_form_report.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_filter{
	background:url(img/title_filter.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

.t_manage_claim_type_category{
	background:url(img/title_manage_claim_type_category.png) no-repeat center;
	background-size:contain;
	height: 36px;
	}

/*--------------------- color : orange (images - pop detail) ---------------------*/

.d_box_sub_icon_leave{
	background:url(img/leave_detail_box_icon.gif) no-repeat center;
	background-size:contain;
	}

.d_box_sub_icon_appointment{
	background:url(img/appointment_detail_box_icon.gif) no-repeat center;
	background-size:contain;
	}

.d_box_sub_icon_request{
	background:url(img/request_detail_box_icon.gif) no-repeat center;
	background-size:contain;
	}

.d_box_sub_icon_claim{
	background:url(img/claim_detail_box_icon.gif) no-repeat center;
	background-size:contain;
	}

.d_box_top_photo_mask{
    background: url(img/leave_detail_box_top_photo_mask.png) no-repeat left;
    background-size: contain;
	}

.d_box_top{
	background: #FA9914 url(img/leave_detail_box_top.png) repeat-x left top;/*for intertrust ( #556db2 ). flex ( #FA9914 )*/
	background-size: 342px 122px;
	}

/*--------------------- color : orange (images - widget sample) ---------------------*/

#widget_outstandingapproval{
	background:#fff url(img/widget_outstandingapproval.png) no-repeat 50% 34%;
	background-size:contain;
	}

#widget_myapplications{
	background:#fff url(img/widget_myapplications.png) no-repeat 50% 34%;
	background-size:contain;
	}

#widget_birthday{
	background:#fff url(img/widget_birthday.png) no-repeat 50% 50%;
	background-size:contain;
	}

#widget_newjoins{
	background:#fff url(img/widget_newjoins.png) no-repeat 50% 34%;
	background-size:contain;
	}

#widget_notice{
	background:#fff url(img/widget_notice.png) no-repeat 0% 56%;
	background-size:contain;
	}

#widget_teamLTR{
	background:#fff url(img/widget_teamLTR.png) no-repeat 50% 26%;
	background-size:contain;
	}

#widget_teamALB{
	background:#fff url(img/widget_teamALB.png) no-repeat 50% 26%;
	background-size:contain;
	}

@media (max-width: 1173px) {

#widget_outstandingapproval{
	background-size:120%;
	}

#widget_myapplications{
	background-size:120%;
	}

#widget_birthday{
	background-size:120%;
	}

#widget_newjoins{
	background-size:120%;
	}

#widget_notice{
	background-size:120%;
	}

#widget_teamLTR{
	background-size:100%;
	}

#widget_teamALB{
	background-size:100%;
	}
}

/*--------------------- color : orange (txt) ---------------------*/

.maincolor_bg{
	background-color:#F6921E;
	}

.maincolor_bg2{
	background-color:#ffad38 !important;
	color:#fff !important;
	}

.maincolor_txt{
	color:#F6921E;
	}

.maincolor_border{
	border: 1px solid #F6921E;
	}

.maincolor_border_bottom{
	border-bottom: 1px solid #F6921E;
	}

.maincolor_photo_mask_selected{
	position: absolute;
    height: 80px;
    width: 80px;
	background-image:url(img/photo_mask_big.png);
	background-repeat:no-repeat;
	background-size:cover;
	}

.maincolor_btn2{
    background-color: #F6921E;
	color:#fff;
	}

.maincolor_btn2:hover,
.maincolor_btn2:focus{
    background-color: #ffad38;
	cursor:pointer;
	}

.btn_linestyle_white{
    background-color: #F6921E;
	color:#fff;
	width: 196px;
    border: 1px solid #f8bd82;
    line-height: 15px;
	}

.btn_linestyle_white:hover,
.btn_linestyle_white:focus{
    background-color: #ffad38;
	cursor:pointer;
	}

.maincolor_btn:hover,
.maincolor_btn:focus{
    background-color: #F6921E;
	border: 1px solid #F6921E;
	color:#fff;
	cursor:pointer;
	}

.maincolor_btn_1{
    background-color: #F6921E;
	color:#fff;
	}

.maincolor_btn_1:hover,
.maincolor_btn_1:focus{
    background-color: #FFAD38;
	cursor:pointer;
	}
.maincolor_btn_1:disabled {
	background-color: #ececec;
    border: 1px solid #cccccc;
 	border-radius: 6px;
}

.c100.orange .bar, .c100.orange .fill {
    border-color: #f6921e !important;
}

ul.tabs li.maincolor_btn-selected{
	background: #F6921E;
	border:1px solid #F6921E;
	color: #fff;
	cursor:default;
	}

.form-control{
	padding: 6px 9px;
    font-size: 11pt;/*10*/
	}

.form-control,
.select2-search input[type="text"] {
  height: 44px;
  /*padding: 8px 12px;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.467;*/

  /*color: #F6921E;*/
  border: 1px solid #e1e1e1; /* 2px solid bdc3c7*/
  border-radius: 6px;
  box-shadow: none;
  -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
          transition: border .25s linear, color .25s linear, background-color .25s linear;
  }

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
	color:#9a9a9a;
	}

.form-group.focus .form-control,
.form-control:focus,
.form-group.focus .select2-search input[type="text"],
.select2-search input[type="text"]:focus {
  border-color: #f6921e;
  outline: 0;
  box-shadow: none;
  }

.checkbox input[type="checkbox"].custom-checkbox:checked + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:checked + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:checked + .icons .icon-checked,
.radio input[type="radio"].custom-radio:checked + .icons .icon-checked {
  color: #ffad38;
  filter: none;
  opacity: 1;
  -webkit-filter: none;
  }

.bc_orange{
	border:1px solid #F6921E;
	color:#F6921E;
	}
	/*
.color_label_pending{
	background-color: #fff;
    border: 1px solid #F6921E;
    color: #F6921E;
	}*/

/*--------------------- xxxxxxxxxxxxx ---------------------*/
/*--------------------- xxxxxxxxxxxxx ---------------------*/
/*--------------------- xxxxxxxxxxxxx ---------------------*/



/*--------------------- col setting ---------------------*/
.col > div,
.col > ng-repeat{
    border: 1px solid #e6e6e6;
	background-color: #fff;
	border-top:none;
	display: block;
	}
.col > div:first-child,
.col > ng-repeat:first-child/**/{
	font-size: 9pt;
	background-color: #f1f1f1;
    color: #9a9a9a;
	}
.col > div > div,
.col > ng-repeat > div{
	float:left;
    padding: 6px;
	word-break: break-word;
	}
.col > div > div:last-child,
.col > ng-repeat > div:last-child{
	float:none;
	width:auto;
    height: 0;
    padding: 0;
    border: none;
	}


.col3_n_btn1 > div > div:nth-child(1),
.col3_n_btn1 > div > div:nth-child(2){
	width:calc((100% - 44px) / 2);
	}
.col3_n_btn1 > div > div:nth-child(3){
	width: 44px;
	padding: 10px 0;
    margin-top: 4px;
	}

.col2_n_btn1 > div > div:nth-child(1){
	width:calc(100% - 44px);
	}
.col2_n_btn1 > div > div:nth-child(2){
	width: 44px;
	padding: 10px 0;
    margin-top: 4px;
	}
.col2_n_btn1 > div .name{
	width: calc(100% - 42px);
    float: left;
    padding-left: 10px;
	}

.col2 > div > div:nth-child(1),
.col2 > div > div:nth-child(2),
.col2 > ng-repeat > div:nth-child(1),
.col2 > ng-repeat > div:nth-child(2){
	width:calc(100% / 2);
	}
.col2{
	background-color:#fff;
	}
/*.col2 > div:first-child(1){
	width:calc(100% / 2);
	}*/

/*--------------------- flag_add_content ---------------------*/

.flag_add_content .input_login_position{
	width:250px;
	text-align:left;
	}

.flag_add_content .input_login_position .input_field_profile_dropdown{
	width:100%;
	}

.flag_add_content .input_login_position .input_field_profile_dropdown div{
	margin-left:224px;
	}

.flag_add_content .input_login_position span{
	text-align:left;
	}

/*--------------------- Preview area ---------------------*/
.preview_area{
	padding: 10px;
    margin: -10px;
	margin-top:20px;
    border-radius: 6px;
	border: 1px solid #E1E1E1;
	background-color:#FCFCFC;

    -webkit-box-shadow: 0px 0px 5px #e6e6e6;
    -moz-box-shadow: 0px 0px 5px #e6e6e6;
    box-shadow: 0px 0px 5px #e6e6e6;
	}

.preview_area > div:nth-child(1){
	margin: -10px -10px;
    margin-bottom: 10px;
    border-radius: 6px 6px 0 0;
    padding: 6px 10px;
    color: #fff;
	}

.hr_preview{
	border-top: 1px solid #e6e6e6;
    margin: 10px 0;
	}

span{
	margin:0;
	}

/*--------------------- mult-form ---------------------*/
.form_area{
    display: block;
	padding-bottom:2px;
	}

.form_area .form_content{
	position: relative;

    margin: 10px 0;

    border-radius: 6px;
    border: 1px solid #e6e6e6;
	background-color:#FCFCFC;
    -webkit-box-shadow: 0px 0px 5px #CDCDCD;
    -moz-box-shadow: 0px 0px 5px #CDCDCD;
    box-shadow: 0px 0px 5px #CDCDCD;
	}

.form_content .form_content_title{
	min-height:44px;
	padding:0px 10px;/*2px 10px*/
	padding-top:8px;
	word-wrap: break-word;
	cursor:pointer;
	background-image: url(img/pattern_bar_w.png);

	padding-right: 42px;
    border-bottom: 4px solid #D9D9D9;
    border-radius: 6px 6px 0 0px;
	}

.form_content .form_content_title div:nth-child(2) > span:nth-child(1){
	float: left;
	margin-right:10px;
	}

.form_content .form_content_title div:nth-child(2) > span:nth-child(2){
	float: left;
	display:inline-block;
	margin-top:2px;
	}

.form_content .form_content_title div:nth-child(2) > span:nth-child(2) div{
	float: left;
	}

.form_content .form_content_title > div:nth-child(1){
	float: left;

	width:66px;
    margin-left: -4px;
    margin-right: 8px;
    text-align: center;

	color: #9a9a9a;
    border: 1px solid #9a9a9a;
    background: transparent;
    border-radius: 6px;

	/*height:45px;
    margin-top: -3px;
    margin-bottom: -10px;
    padding: 10px;
	color:#fff;
    border-radius: 6px 0 6px 0;
	border-bottom: 2px solid #A7A7A7;
	border-right: 2px solid #A7A7A7;*/
	}

.form_content .form_content_title > div:nth-child(2){
	/*position: absolute;*/
	display: inline-block;
    width: calc(100% - 70px);/* calc(100% - 110px)*/
    left: 72px;
    top: 1px;
	}

.form_content .form_content_input{
	padding:8px;/*10*/
	}

.form_area .delete_a_record{
	position: absolute;
    margin: 0;
    top: 7px;
    right: 7px;
	left: auto;
	z-index:0; /* ie 11 */
	}

/*--------------------- timepicker ---------------------*/

.time_field{
    height: 44px;
	border-radius:6px;
	}

.time_field > div:nth-child(even){
	margin-top:12px;
	}

.time_field select{
	padding-left: 10px;
	width: 44px;
	background-image:none;
	}

.time_field > div:nth-child(5) > select{
	width: 81px;

	background-image: url(img/list_arrow_down.png);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: 83% 50%;
	}


.time_field select + div{
	position: absolute;
    margin-top: -38px;
    margin-left: 58px;
	}

.pop_functions_two_btn_center button{
	width:100%;
	}

.time_field div:nth-child(5) select{
    padding-right: 28px;
	}


.time_field.normal{
	margin:0 !important;
	}
.time_field.normal select{
	min-width:74px;
	}

/*--------------------- tab - remark (application/withdrawal) ---------------------*/
.remark_tabs {
	position: relative;
	height: 74px;
    clear: both;
    margin-bottom: 38px;
    margin-top: -17px;
}

.remark_tabs .tab {
  float: left;
  font-size:9pt;
}

.remark_tabs .first {
	margin-left:66px;/*72*/
}

.remark_tabs .tab label {
  padding: 4px 6px; /*6px 9px*/
  border: 1px solid #E1E1E1;
  background-color: #FFF;
 color: #9a9a9a;
  margin-left: -1px;
  position: relative;
  left: 1px;
  border-radius: 6px 6px 0 0;
  margin-left: 4px;
	}
.remark_tabs .tab label:hover,
.remark_tabs .tab label:focus{
    background-color: #e6e6e6;
	cursor:pointer;
	transition: all .6s ease 0s;
	}

.remark_tabs .tab [type=radio] {
  display: none;
}

.remark_tabs .content {
  position: absolute;
  height:100% !important;
  top: 22px;
  left: 0;
  right: 0;
  bottom: 0;
  margin:0;
  padding: 2px 4px;
  padding-top:6px;
  border-radius: 6px;
}

.remark_tabs [type=radio]:checked ~ label {
    background-color: #F1F1F1;
    border-bottom: 2px solid #9a9a9a;
    z-index: 2;
    font-size: 9pt;
    font-weight: bold;
    font-family: sans-serif;

	-webkit-box-shadow: 0px 0px 8px #ccc;
    -moz-box-shadow: 0px 0px 8px #ccc;
    box-shadow: 0px 0px 8px #ccc;
  /*background-color: #F1F1F1;
  border-bottom: 1px solid #F1F1F1;
  z-index: 2;
  	font-size: 9pt;
    font-weight: bold;
    font-family: sans-serif;*/
}

.remark_tabs [type=radio]:checked ~ label ~ .content {
  z-index: 1;
}

.remark_tabs.normal{
	height:256px !important;
	margin-top: 10px;
	}
.remark_tabs.normal .first{
	margin:0;
	}
.remark_tabs.normal label{
	font-size:9pt;
	}
.remark_tabs.normal .content{
	background-color:#fff;
	padding-top: 6px;
	}



/*--------------------- calendar category filter ---------------------*/

ul.filter_calendar_reset {
    margin-bottom: 4px;
    height: 50px;
    padding: 0px;
    list-style: none;
	}

ul.filter_calendar_reset li {
	float: left;
	display: inline;
	background-color:rgba(255,255,255,0.9);
	cursor:pointer;
	}

ul.filter_calendar_reset li a {
    float: left;
    margin: 4px;
    color: #9a9a9a;
    border: 1px solid #e6e6e6;
    padding: 10px 8px;
    line-height: 1.471;
    border-radius: 6px;
	text-align:center;

	text-decoration: none;
	display: block;
	}

ul.filter_calendar_reset li a:hover,
ul.filter_calendar_reset li a:focus{
	background: #F3F3F3;
	border:1px solid #e6e6e6;
	}

ul.filter_calendar_reset li .active{
	background: #F3F3F3;
	border:1px solid #e6e6e6;
	}

ul.filter_calendar_reset li:nth-child(1) a{ /* = f_all */
	width:52px;
	}

/*--------------------- Calendar date bar ---------------------*/

.calendar_date_bar{
	float:left;
	margin-top:4px;
	margin-bottom:4px;
	margin-right:8px;
	/*width:188px;*/
	}
.calendar_date_bar button{
	width: 32px;
    height: 43px;
	padding:0;
}

.calendar_date_bar div:nth-child(2){
	float:left;
	width: 76px;
	line-height: 20pt;
    margin-top: 6px;
    border: 1px solid #e6e6e6;
	text-align:center;
	background-color: #F5F5F5;
}
.calendar_date_bar div:nth-child(2):hover,
.calendar_date_bar div:nth-child(2):focus{
    background-color: #e6e6e6;
	/*color:#9a9a9a !important;*/
	}
.calendar_date_bar div:nth-child(1) select,
.calendar_date_bar div:nth-child(2) select{
	height: 30px;
	/*padding: 0 14px;*/
	padding: 4px;
	color: #9a9a9a;
	font-size:10pt;
	background-image: none;
	/* background-color: transparent; */
	background-color: whitesmoke;
	width: 100%;
	padding: 0;
	overflow: hidden;
	border-radius: 0;
	text-align: center;
	text-align-last:center;
	}
.calendar_date_bar div:nth-child(1) select li,
.calendar_date_bar div:nth-child(2) select li{
	color: #9a9a9a;
	}

/*--------------------- Calendar ---------------------*/

.calendar_remark{
	float:left;
	width:94px;/*calc(100% - 476px);*/
	white-space: nowrap;
	}

.calendar_remark > div{
	float:left;
	width: auto;
	margin-left:8px;
	padding:0;
    text-align: center;
	}

.filter_calendar_reset{
	float:right;
	}

.filter_calendar_reset img{
	margin-right:2px;
	}

.month {
    padding: 0;
    width: 100%;
    background-color: #1abc9c;
}

.month ul {
    margin: 0;
    padding: 0;
}

.month ul li {
    color: white;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.month .prev {
    float: left;
    padding-top: 10px;
}

.month .next {
    float: right;
    padding-top: 10px;
}

.weekdays {
    margin: 0;
    padding: 0;
	font-size: 9pt;
	/*margin-bottom:2px;*/
}

.weekdays li { /* for Me */
    display: inline-block;
	width: calc(100% / 7 - 3.5px);
    text-align: center;
	background-color: #e6e6e6;
	padding: 2px 0;
}

/* Override new calendar */
.cal-month-view .cal-header .cal-cell {
	display: inline-block;
	width: calc(100% / 7 - 3.5px);
    text-align: center;
	background-color: #e6e6e6;
	padding: 2px 0;
}
.cal-month-view .cal-cell {
	margin-right: 3px;
}

.cal-month-view .cal-days {
	border: none;
}

.cal-month-view .cal-day-cell {
    min-height: 0px !important;
}

.mat-calendar-body-cell-content:hover {
	background-color: #e6e6e6 !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
	color: #F6921E;
	border: none;
}

.mat-calendar-body-selected {
	background-color: #F6921E;
	color: white;
}

.weekdays_2 li { /* for simon */
	margin-right:3px;/*3.6*/
    /*display: inline-block;
	width: calc(100% / 7 - 4px);
    text-align: center;
	background-color: #e6e6e6;*/
}

.days {
    padding: 0;
    margin: 0;
}

.days > div{ /*For simon : reset .days 's label <div> to <a> format*/
	display:inline;
	}

.days > a > div{ /*reset .days 's label <div> to <a> format*/
	display:inline;
	}

.days li {
	list-style-type: none;
    display: inline-block;
    /* width: calc(100% / 7 - 4px); */
	width: 100%;
    text-align: center;
    /*margin: 1px -1px;  2px 0px */
    padding: 2px 0;
    border: 1px solid #e6e6e6;
    height: 188px;/*144*/
    line-height: 22pt;
	margin-top: 3px;

	margin-right: 3.8px;
	float:left;
	}

.days li:hover,
.days li:focus{
    background-color:#FAFAFA;
    border: 1px solid #e6e6e6;
	cursor:pointer;
	}

.days li .selected {
	width:30px;
	height:30px;
	margin: 0 auto;
	margin-bottom: 4px;
	border-radius:100px;
    background: #f6921e;
    color: #fff;
}

.days li > div:nth-child(1) {
	width: 30px;
    height: 30px;
    margin: 0 auto;
	border: 1px solid #fff; /* reset */
	border-radius:50px;
	}

.days li .today {
	width: 30px;
    height: 30px;
    margin: 0 auto;
    border-radius: 100px;
    background-color: #e6e6e6;
    /* background-color: #F3F3F3; */
    /* color: #9a9a9a; */
	}

.label_box_area > .day_status_remark{
	font-weight: normal;
	font-size: 7.5pt;
	line-height: 0;
    margin-top: 4px;
	color: #9a9a9a;
	}

.day_status_remark > div{
	float:right;
	}

.day_status_remark > div:nth-child(1){
	margin-right:2px;
	}

/* .days > div:nth-child(7n+1) > div > li{
	color:#e8534f;
	} */

/* mat-select */
.mat-select-trigger {
	min-height: 44px;
}

.mat-select-value {
	color: white;
	padding: 4px;
}

.mat-select-placeholder {
	color: white;
	padding: 4px;
}

/* For mwl-calendar-cell */
.cal-month-view .cal-header .cal-cell:first-child {
	color:#e8534f;
}

.cal-month-view .cal-day-cell:nth-child(7n+1) {
	color:#e8534f;
}

.days > a:nth-child(7n+1) li {
	color:#e8534f;
	}

.weekdays li:nth-child(1){
	color:#e8534f;
	}
/*
.label_box_area_title{
	position:absolute;
	width:100%;
	background: url(img/label_cal_title.png) repeat-x center;
    background-size: contain;
	line-height: 22px;
    height: 20px;
	margin-top:-20px;
	color: initial;
	}*/

.label_box_area{
	position: relative;
    width: 100%;
    margin: 0 auto;
	}

.label_box{
    width: 100%;
    margin: 0 auto;
	padding: 0; /* 4px 0 */
	line-height: 16px;
	}
/*
.label_box > div > div:nth-child(1){
	font-weight: normal;
	font-size: 7.5pt;
	display:none;
	}*/

.label_box > div > div:nth-child(1){
    font-family: sans-serif;
	font-size: 11pt;
	font-weight: bold;
	}

.label_box > div{
	float:left;
	}

.label_box_area .c_leave > div,
.label_box_area .c_appointment > div{
	float:left;
	width:calc(100% / 2 - 7px);
	margin-top:2px;
	padding:3px 0;
	}

.label_box_area .c_leave > div:nth-child(1),
.label_box_area .c_appointment > div:nth-child(1),
.label_box_area .day_status_remark > div:nth-child(1){
	width:12px;
	}

.label_box_area .c_leave > div:nth-child(2){
	border-right: 1px solid #fff;
	background-color:#42c1e9;
	border-radius:6px 0 0 6px;
	}

.label_box_area .c_leave > div:nth-child(3){
	background-color:#42c1e9;
	border-radius: 0 6px 6px 0;
	}

.label_box_area .c_appointment > div:nth-child(2){
	border-right: 1px solid #fff;
	background-color:#cb91f0;
	border-radius:6px 0 0 6px;
	}

.label_box_area .c_appointment > div:nth-child(3){
	background-color:#cb91f0;
	border-radius: 0 6px 6px 0;
	}

.label_me .c_leave{
	background-image:url(img/label_personal_leave.png);
	background-size:8px;
	background-repeat: no-repeat;
	background-position: 2px 9px;
	}

.label_me .c_appointment{
	background-image:url(img/label_personal_appointment.png);
	background-size:8px;
	background-repeat: no-repeat;
	background-position: 2px 9px;
	}

/*.label_me .c_leave_other{
	background-image:url(img/label_personal_appointment.png);
	background-size:8px;
	background-repeat: no-repeat;
	background-position: 2px 9px;
	}*/

.label_subordinate .c_leave{
	background-image:url(img/label_subordinate_leave.png);
	background-size:8px;
	background-repeat: no-repeat;
	background-position: 2px 9px;
	}

.label_subordinate .c_appointment{
	background-image:url(img/label_subordinate_appointment.png);
	background-size:8px;
	background-repeat: no-repeat;
	background-position: 2px 9px;
	}

.label_group .c_leave{
	background-image:url(img/label_group_leave.png);
	background-size:8px;
	background-repeat: no-repeat;
	background-position: 2px 9px;
	}

.label_group .c_appointment{
	background-image:url(img/label_group_appointment.png);
	background-size:8px;
	background-repeat: no-repeat;
	background-position: 2px 9px;
	}

	/*
.label_box > div:nth-child(1){
    width: 45%;
	}

.label_box > div:nth-child(2){
    width: 55%;
	border-left: 1px solid #fff;
	}*/

.label_box_area .c_leave{
	/*background:#fff url(img/icon_calendar_leave.png) no-repeat 4px;
	background-size:20px;*/
	color:#fff;
	}

.label_box_area .c_appointment{
	/*background:#fff url(img/icon_calendar_appointment.png) no-repeat 4px;
	background-size:20px;*/
	color:#fff;

    font-family: sans-serif;
	font-size: 11pt;
	font-weight: bold;
	}

.label_remarks{
	padding-right: 10px;
	padding-bottom: 10px;
	width:100%;
	text-align:right;
	}

.label_remarks img,
.label_remarks_other img{
	vertical-align:baseline; /* ie 11 */
	}

.label_remarks_other{
	display:none;
	color: #4d4d4d;
    font-size: 9pt;
	text-align: center;
    padding: 4px 10px;
	}

.label_box_area .day_status_remark > div{
	float:left;
	width:calc(100% / 2 - 7px);
	margin-bottom:2px;
	padding:3px 0;
	}

.label_box_area .day_status_remark > div:nth-child(1){
	width:10px;
	}

.label_reset{
	margin: 0px 4px;
	}
.label_reset > .label_box > div:nth-child(1){/*hide arrow*/
	width: 0;
	}
.label_reset > .label_box > div:nth-child(2){
	width: calc(50% - 4px);
	}
.label_reset > .label_box > div:nth-child(3){
	width: calc(50% - 4px);
	}

	/* -- other label box (late,early_leave,absent) -- */
.label_other{
	width:100%;
	}
.label_other > .label_box{
	margin-top: 6px;
	background-size:12px;
	background-repeat: no-repeat;
	background-position: 2px 2px;
	}
.label_other .label_box > div{
	width:100%;
	margin-top:2px;
	padding:2px 0;
	text-align:left;
	padding-left:18px;
	color: #FB6D51;
	}
.label_other .label_box > div:last-child{
	margin:0;
	padding:0;
	padding-left:18px;
	}
.c_late{
	background-image:url(img/label_personal_late.png);
	}
.c_early_leave{
	background-image:url(img/label_personal_early_leave.png);
	}
.c_absent{
	background-image:url(img/label_personal_absent.png);
	}

/* Add media queries for smaller screens */
@media screen and (max-width: 940px) {

.weekdays li{
	width: calc(100% / 7 - 8px);
    margin-left: 4px;
	}

.days li .active {
	padding: 2px;
	}

ul.filter_calendar_reset { /* For : calendar category filter */
    margin: 4px auto;
    width: 298px;
    float: initial;
    clear: both;
	}

.calendar_date_bar {  /*For : calendar date bar*/
	margin-left:6px;
	}

.label_remarks{
	text-align:center;
	}
}

/* ladel's number hide when small screens */
@media screen and (max-width: 1013px) {
.weekdays{
    background-color: #e6e6e6;
  	}

.days li {
	height:76px;/*59*/
	border:1px solid #ffffff;
	border-bottom:1px solid #e6e6e6;
	padding-top: 0;
    margin-top: 0;
	}

.days li:hover,
.days li:focus{
	border:1px solid #999999;
	border-radius:6px;
	background-color:transparent;
	}

.label_box div {
	display:none;
	}

.label_box_area .c_leave{
	background:#42c1e9;
	}

.label_box_area .c_appointment{
	background:#cb91f0;
	}

.label_subordinate .c_leave,
.label_subordinate .c_appointment{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 4px 0 4px;
	border-color: transparent #ffffff #ffffff #ffffff;
	}

.label_me .c_leave,
.label_me .c_appointment{
	border-radius:100px;
	}

.label_group .c_leave,
.label_group .c_appointment{
	width: 8px;
	height: 8px;
	}

.label_other{
	width:28px !important;
	}
.label_other .label_box{
    background-position: top;
	width: 12px;
    height: 12px;
	margin-bottom: 4px;
	margin-top: 0;
	}
.label_other .label_box:first-of-type{
    margin-right: 2px;
	}

.label_remarks_other{
	display:block;
	}

.label_box_area{
	text-align: center;
    padding: 0;
    margin: 0 auto;
    width: 20px;
	margin-top: 2px;
    margin-bottom: 4px;
	}

.label_box{
	width: 8px;
    height: 8px;
    padding: 0;
    margin: 0 auto;
    margin-right: 2px;
	}

.label_box_area div:nth-child(1){
	margin-left: 0;
	}
/*
.label_box_area_title{
	display:none;
	}*/

.label_remarks{
	display:block;
	}

.day_status_remark{
	display:none;
	}

}

/*--------------------- personal profile ---------------------*/

.personal_profile_item{
	float:left;
	border:1px solid #e6e6e6;
	width:300px;
	border-radius: 6px;
    margin-left: 10px;
    margin-bottom: 20px;
	text-align:left;
	padding:10px;

	-webkit-box-shadow: 0px 0px 5px #e6e6e6;
    -moz-box-shadow: 0px 0px 5px #e6e6e6;
    box-shadow: 0px 0px 5px #e6e6e6;
	}
	/*
.personal_profile_area > div > div:nth-child(2n+1){
	clear:both;
	}*/

.personal_profile_area .form_content{
	/*display:none;*/
	}

.personal_profile_area .f_general,
.f_employment,
.f_address,
.f_bank,
.f_dependents,
.f_qualification{
	display:none;
	}

.f_qualification .form_content_title,
.form_content_popup .form_content_title{
	height: auto;
	min-height:44px;
	}

.personal_profile_area .btn_add_profile_data{
	display:inline-block;
    margin-top: 5px;
	margin-bottom: -5px;
	min-width:100px;
	}

.personal_profile_area .btn_add_profile_data .one_btn{
	float: left;
	width:102px;
	}

.form_area .form_content_title{/*.personal_profile_area .form_content_title*/
	padding-top: 11px;
	background-color:#ececec;
	color:#4c4c4c;

    padding: 11px 10px;

	background-image:url(img/pattern_bar_w.png);
	/*
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;*/
	}

.personal_profile_area .f_general .form_content_title{ /* give space to edit button */
    padding-right: 42px;
	}

.form_content_popup .form_content_title{
	padding-top: 11px;
	background-color:#ececec;
	color:#4c4c4c;

    padding: 11px 10px;
    padding-right: 70px; /*42  58*/

	min-height: 44px;
    cursor: pointer;

	background-image:url(img/pattern_bar_w.png);
	}
	/* ======= for claim ======*/
.form_content_claim_application .form_content_title{
	padding: 0px 10px;
    padding-top: 8px;
	}
.form_content_claim_application .form_content_title > div:nth-child(2){
	width: calc(100% - 100px);
	}
	/* ========================*/

.form_content_popup .form_content_title > div:nth-child(1){ /* No. X */
	/*margin-top:-12px;-11*/
	}

	/*
.personal_profile_area .form_content_title:hover,
.personal_profile_area .form_content_title:focus{
	background-color:#E4E4E4;
	}*/

.personal_profile_area .form_content_title-selected,
.form_content_popup .form_content_title-selected,
.form_content .form_content_title-selected{
	background-color: #e6e6e6;/*D7D7D7*/
	background-image: none;
	}

.form_content_popup .form_content_input_field{
	float:left;
	width:248px; /* 256px */
	min-height:72px;
	margin-right:12px;
	margin-bottom:10px;
	}

.form_content_popup .form_content_input_field{
	float:left;
	width:248px; /* 256px */
	min-height:72px;
	margin-right:12px;
	margin-bottom:10px;
	}

.form_content_input_field_dropdown_icon{
	position: absolute;
    margin-top: -38px;
    margin-left: 230px;
	}

	/*
.form_content_popup .form_content_input_field .txt_box_1{
	margin-top:0;
	width:100%;
	}*/

.form_content_popup .form_content_input_field_full{
	float:left;
	width:100%;
	max-width:524px;
	margin-bottom:10px;
	}

.form_content_popup .form_content_input_field_full > textarea{
	max-width:524px;
	}

.form_content_popup .btn_attachment_edit{
	max-width:248px;
	margin-top:0;
	}

.personal_profile_area .form_content_title-selected + div + .form_content_input,
.personal_profile_area .form_content_title-selected + .form_content_input,
.form_content .form_content_title-selected + .form_content_input{
	display:block;
	}

.personal_profile_area .form_content_input,
.form_content_popup .form_content_input{ /*default close*/
	display:none;
	}
	/*
.pp_dependents_content .form_content_popup .form_content_input,
.pp_qualification_content .form_content_popup .form_content_input{ default closed
	display:none;
	}*/

	/*
.personal_profile_area .form_content_input .same_line,
.pop_functions_profile_amend_inquiry .pop_functions_box_content_tc .same_line{*/
.same_line{
    margin: 6px 0;
    float: left;

	width: 50%;
	width: calc(100% / 2 - 8px);
    margin-right: 8px;
    word-break: break-word;
	}

/*
.personal_profile_area .form_content_input .full_line,
.pop_functions_profile_amend_inquiry .pop_functions_box_content_tc .full_line{*/
.full_line{
	width: 100%;
	margin: 6px 0;
    float: left;
	word-wrap: break-word;
	}

.personal_profile_area hr,
.pop_functions_profile_amend_inquiry hr{
	margin: 6px 0;
	}

.personal_profile_area .attachment_photo{
	margin: 0;
	margin-top:4px;
	}

.btn_edit_inf{
	background-color: #ececec;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    font-size: 16pt;
    line-height: 18pt;
    text-align: center;
    position: absolute;
    left: initial;
    top: 2px;
    right: 2px;
    padding-top: 3px;
    cursor: pointer;
	/*
	background-color:#ececec;
    width: 52px;
    height: 44px;
    border-radius: 0 6px 6px 0;
    font-size: 16pt;
    line-height: 18pt;
    text-align: center;
    position: absolute;
    left: initial;
    top: 0px;
    right: 0px;
    padding-top: 5px;
    cursor: pointer;*/
	}

.btn_edit_inf:hover,
.btn_edit_inf:focus{
	background-color:#FCFCFC;
	}

.btn_attachment_edit{
	margin-top: 4px;
	}

.btn_attachment_edit div{
	float:left;
	width: calc(100% / 2 - 3px);
	}

.btn_attachment_edit div:nth-child(1){
	margin-right:6px;
	}

.attachment_file_area_edit_mode{
	max-width: initial;
    margin-right: initial;
    margin-top: 6px;
    padding: 10px;
	border-radius: 6px;
	}

.attachment_file_area_edit_mode .attachment_photo_touch{ /* reset it */
    top: -44px;
	}

.attachment_file_area_edit_mode .attachment_file_txt{
    width: calc(100% - 112px);
	}
/*
.attachment_file_area_edit_mode .btn_attachment_position{
	width:initial;
	}*/

.detail_box_bottom_area_profile{
    /*height: 534px;*/
    text-align: left;
	}

.detail_box_bottom_area_profile .box_content_inquiry{
	margin: 0;
	}

	/*
.detail_box_bottom_area_profile .box_content_inquiry .form_area .form_content{
	margin: 10px;
	padding:0;
	}*/

.pp_address_content .pop_functions_approval .pop_functions_two_btn_center,
.pp_dependents_content .pop_functions_approval .pop_functions_two_btn_center,
.pp_qualification_content .pop_functions_approval .pop_functions_two_btn_center{
	width: auto; /**/
	}

.pp_bank_content .bankcode_dropdown{
	float:left;
	width: 76px;
	}

.pp_bank_content .bankcode_dropdown .btn_dropdown_1{
	background-position: 90% 50%;
	padding-right: 24px;
	width: 74px;
	}

.pp_bank_content .bankcode_dropdown div{
	margin-left: 58px;
	}

.pp_bank_content .bankcode_dropdown + input{
	float:left;
	width: calc(100% - 68px - 12px);
	margin-left:4px;
	}

.pp_bank_content .bankcode_dropdown + input + textarea{
	display: inline;
	}

.btn_3select1{
	margin-bottom:14px;
	/*width:248px;*/
	}

.btn_3select1 div{
	width:85px;
	margin:0;
	background-color:#9a9a9a;
	}

.btn_3select1 > .active{
	background-color:#F6921E !important;
	}

.btn_3select1 div:nth-child(1){
	border-radius: 6px 0 0 6px;
	}

.btn_3select1 div:nth-child(2){
	border-radius: 0;
	border-left:1px solid #fff;
	border-right:1px solid #fff;
	}

.btn_3select1 div:nth-child(3){
	border-radius: 0 6px 6px 0;
	}

.btn_3select1 + div{
	/*display:none;*/
	}


	/*
.profile_p1{
	height:72px;
	color:#fff;
	padding:10px;
	text-align:center;
	border-radius: 6px 6px 0 0;

	margin-top: -11px;
    margin-left: -11px;
    width: 300px;

	line-height: 38pt;
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

.profile_p2{
	height:180px;
	}

.profile_p3{
	background-color: #f6f6f6;
	padding:10px;
	border-radius: 6px;
	}

.profile_p3 > div{
	float:left;
	margin-bottom:10px;
	width:100%;
	}

.personal_profile_area .form_content_input > div:last-child{
	float: initial;
	clear:both;
	margin-bottom:0;
	}

.profile_p2 .txt_date_1_inf{
    position: absolute;
	margin-top: 26px;
    margin-left: 26px;
    width: 56px;
    text-align: center;
	}

.profile_p2 .txt_date_1{
    position: absolute;
	margin-left: 88px;
    margin-top: 30px;

	width: 190px;
	height: 75px;
    overflow: hidden;
	}

.profile_p2 .txt_date_2_inf{
    position: absolute;
	margin-top: 110px;
    margin-left: 26px;
    width: 56px;
    text-align: center;
	}

.profile_p2 .txt_date_2{
    position: absolute;
	margin-left: 88px;
    margin-top: 114px;

	width: 190px;
	height: 54px;
    overflow: hidden;
	}	*/

.btn_edit{
	position:absolute;
	margin-left:244px;
	margin-top:-7px;

	width: 36px;
    height: 36px;
    border-radius: 100px;
    line-height: 27px;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
	/*position: absolute;
    margin-top: 14px;
    margin-left: 221px;
    float: right;
    line-height: 16px;
    padding: 4px 8px;
    border-radius: 6px;
    border: 1px solid #CDCDCD;
    color: #9a9a9a;
	class : txt_size_2 btn_opacity btn_edit
	*/
	}

.btn_edit:hover,
.btn_edit:focus {
	background-color:#F3F3F3;
	}

.input_profile_singleline{
	width: 248px; /*232*/
	height: 44px;
    line-height: 36px;
	}

.input_profile_manyline{
	width: 248px;/*232px*/
	height: 70px;
	}

.edit_profile_box{
	margin-left:14px; /*36px*/
	}

.edit_profile_box div > span{
	/*margin-left:10px;*/
	}

.edit_profile_box .pop_functions_two_btn_center{
	margin-left:-12px;
	}

.edit_profile_box > div{
	margin-top:12px;
	}

.edit_profile_box .btn_date{
	padding: 10px 12px;
    padding-right: 33px;
	}

.edit_profile_box .input_icon_date{
	margin-top:-34px;
	margin-left: 204px;
	}
	/*
.edit_profile_box_label{
	background-color: #f6f6f6;
    border-radius: 6px;
    margin-left: -14px;
    padding-bottom: 10px;
    padding: 10px 15px;
	height:102px;
	width:261px;
	}

.edit_profile_box_label textarea,
.edit_profile_box_label input{
	width:100%;
	}*/

.input_field_profile_dropdown{
	width:248px;
	margin-top:4px;
	}

.edit_profile_box .time_field{
	margin-top:6px;
	}

.input_field_profile_dropdown input{
	height:44px;
	padding:10px;
	}

.input_field_profile_dropdown div{
	position: absolute;
    margin-top: -38px;
    margin-left: 211px;
	}

/*--------------------- intro - swipe img ---------------------*/

.intro_area_img{
	width:280px;
	height:262px;
	overflow: hidden;
	position: relative;
	}

.intro_area_img_home{
	width:280px;
	height:272px;/*262px*/
	overflow: hidden;
	position: relative;
	margin-left: calc(50% - 280px/2);
	}

.imgs {
	float: left;
	display: inline;
	padding: 0;
	margin: 0;
	width:calc(283.3px * 6); /*1417px 1700px*/

	transition-property: transform;
	transition-duration: 0.5s;
	transition-timing-function: ease-out;

	/*apply a transform to kick in the hardware acceleration.  Without this, the first
	time we add the transform you get odd rendering of the divs (half missing) */
	transform: translate(0, 0);
	}

.imgs_claim_style {
	width:calc(283.3px * 4); /*1417px 1700px*/
	}

.imgs img {
	padding: 0;
	margin: 0;
	width:280px;
	height:262px;

	/*apply a transform to kick in the hardware acceleration.  Without this, the first
	time we add the transform you get odd rendering of the divs (half missing) */
	transform: translate(0, 0);
	}

.intro_area button{
    margin: 0 2px;
    background-color: #FFF;
    color: #9a9a9a;
    border: 1px solid #e6e6e6;
    padding: 9px 9px;
    line-height: 1.471;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
	cursor:pointer;
	}

.intro_area button:hover,
.intro_area button:focus{
    background-color: #ececec;
}

.img_counter{
    width: 100%;
    left: 0;
    margin-top: 3px;
	}

#t_left{
	display:none;
	}


/*--------------------- team info ---------------------*/

.grid_bar_area .width_staff{
	width:calc(322px / 3 - 14px);
	}

.label_teaminfo_txt_date{
	position: absolute;
    z-index: 1;
    padding: 1px 4px;
    margin-top: -12px;

    text-align: center;
    color: #fff;
    border-radius: 4px;
    line-height: 12pt;

	/*position: absolute;
	z-index:1;
    margin-left: 37px;
    margin-top: -12px;
	text-align:center;
    color: #fff;
    border-radius: 4px;
	width: 44px;
    overflow: hidden;
    line-height: 12pt;*/
	}

/* ==================== input placeholder reset ==================== */

input::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
	color: #CDCDCD;
	}
input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: #CDCDCD;
	opacity:  1;
	}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: #CDCDCD;
	opacity:  1;
	}
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #CDCDCD;
	}

/*--------------------- mult_select_input ---------------------*/

/* Custom CSS NPM Multi Select */ 
.multiselect-dropdown .dropdown-btn {
	background-color: #F6921E;
	color: white;
}

.multiselect-dropdown .dropdown-btn .selected-item {
	border: unset !important;
	background: #e9e9e9 !important;
	color: black !important;
	max-width: unset !important;
}

.multiselect-dropdown .dropdown-btn .selected-item a {
	color: #F6921E !important;
}

.dropdown-list {
    background: #F6921E !important;
}

.dropdown-list .filter-textbox {
	color: black !important;
	background-color: white !important;
}

.dropdown-menu{
	width:100%;
}

.multiselect-item-checkbox input[type=checkbox] + div {
	color: white !important;
}

.multiselect-item-checkbox:hover {
	background-color: dodgerblue !important;
}



/* End of Custom CSS NPM Multi Select */ 

.dropdown-menu{
	width:100%;
	}

.mult_select_input{
	/*max-width: 522px;
    min-width: 248px;*/
    max-width: 506px;
    background-color: #F6921E;
	min-height: 44px;
    border-radius: 6px;
	}

@media (max-width: 48em){
	.mult_select_input{
		width: 248px;
		}
}

.mult_select_input .dropdown-menu > li > div,
.mult_select_input .ui-select-bootstrap .ui-select-choices-row>span{
	padding: 3px 6px;
    white-space: normal;
	}

.mult_select_input .ui-select-multiple{
    background-color: transparent;
    border: none !important;
    line-height: 24pt;
	padding: 0;
	}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item{
	outline: 0;
	margin: 0 4px;
	padding: 6px;
	line-height:13px;
    background-color: #fff !important;
    color: #9a9a9a;

    white-space: normal;
    text-align: left;
    margin: 2px;
	}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close{
	margin-left:6px;
	border-left: 1px solid #F6921E;
	color: #F6921E;
    opacity: 1;
	}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close:hover,
.ui-select-multiple.ui-select-bootstrap .ui-select-match .close:focus{
	color: #9a9a9a;
	outline:0;
	}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
	margin: 4px 6px;
    padding-left: 6px;
    width: calc(100% - 20px) !important;
    border: none !important;
    height: 24pt;
    outline: 0;
    border-radius: 6px;
    background-color: transparent;
	color: #fff;
    border: 1px solid rgba(255,255,255,0.4);
}

.ui-select-choices li:hover,
.ui-select-choices li:focus{
    background-color: #e6e6e6;
	cursor:pointer;
	}

.ui-select-search::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
.ui-select-search::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
.ui-select-search:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
.ui-select-search:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

	/*------ to simon law -------*/
	.mult_select_input .btn-default{
    	background-color: #F6921E !important;
	}
	.mult_select_input .text-muted{
    	color: #fff !important;
	}


/*--------------------- new calendar ---------------------*/

.input_icon_date_new{ /* same as CSS : #datepicker-1, #datepicker-2, .btn_date */
	background-image: url(img/input_date.png);
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 96% 50%;

	height: 35px; /*for simon*/
	}

.input_new_calendar{
	background-color: #F6921E;
    border-radius: 6px;
    padding-top: 4px;
    height: 44px;
	width:248px;
	margin-bottom: 6px;
	}

.input_new_calendar:hover,
.input_new_calendar:focus{
    background-color: #FFAD38;
	cursor:pointer;
	}

.input_new_calendar_small{
	background-color: #F6921E;
    border-radius: 6px;
    padding-top: 4px;
    height: 44px;
	width:174px;
	margin-bottom: 6px;
	}
.input_new_calendar_small .md-button.md-icon-button{
	margin-left: 134px;
	margin-top: -2px;
    position: absolute;
	}

.input_new_calendar_readonly,/*angler*/
.input_calendar_readonly{/*me*/
	cursor: not-allowed;
    background-color: #eee;
    opacity: 1;
    background-image: none;
    border: 1px solid #E1E1E1 !important;
	}
.input_new_calendar_readonly .input_icon_date_new,/*angler*/
.input_calendar_readonly{/*me*/
	background-image: none !important;
	}

.input_new_calendar_readonly .am-date-picker__input,/*angler*/
.input_calendar_readonly{/*me*/
	cursor: not-allowed;
	color: #9a9a9a;
    opacity: .9;
	}


.layout, .layout-column, .layout-row {
    box-sizing: initial;
    display: flex;
	}

.am-date-picker__container{
    border-radius: 6px;
    overflow: hidden;
    min-width: 300px;
    height: 390px;
    min-height: 340px;

	position:absolute;
	left:calc(100% /2 - 300px /2);
	top:calc(100% /2 - 340px /2);
	}

.am-date-picker__actions{
	position: absolute;
    bottom: 6px;
	}

.am-date-picker__actions > .btn-group{
	margin:0 14px;
	height:44px;
	}

.am-date-picker__actions > .btn-group > .btn{
	background-color:#fff;
	border: 1px solid #e6e6e6;
	color: #4d4d4d;
    text-align: center;
	width: 58px;
	}

.am-date-picker__actions > .btn-group > .btn:hover,
.am-date-picker__actions > .btn-group > .btn:focus{
	background-color:#e6e6e6;
	}

@media (max-height: 522px){
.am-date-picker__container{
    /*overflow: scroll;
	width:312px;*/
	top:4px;
	}
}

.am-date-picker__weekdays > span{
	display:inline-block;
	width: 14%;
	}

.md-scroll-mask{
	z-index:10000;
	background-color: rgba(0,0,0,0.5);
	position:fixed;
	}

.md-dialog-container{
	z-index:10000;
	}

md-dialog{
	-webkit-box-shadow: 0px 0px 20px #666666;
    -moz-box-shadow: 0px 0px 20px #666666;
    box-shadow: 0px 0px 20px #666666;
	}

.layout-column{
	display:none;
	}

.am-date-picker__actions .layout-row{
	display:none;
	}

.layout-align-start-center > *, .layout-align-center-center > *, .layout-align-end-center > *, .layout-align-space-between-center > *, .layout-align-space-around-center > *{
	width:118px;
	}

.am-date-picker__content > div:nth-child(1){
	/*padding-left:calc(100% /2 - 112px);*/
	padding-left:10px;
	padding-right:10px;
	}

.am-date-picker__day--is-today > .ng-scope{
	color:#F6921E;
	}

.md-toolbar.md-default-theme:not(.md-menu-toolbar), .md-toolbar:not(.md-menu-toolbar) {
    background-color: #F6921E;
	}

md-input-container{
	width:100%;
	}

.am-date-picker__calendar-icon{
	right:0;
	top:-2px;
    left: initial;
	}

md-input-container{
	margin:0;
	}

md-input-container .md-input{
	padding: 12px;
    margin-top: -6px;
    height: 44px;
    border: none;
    margin-left: -2px;
    border-radius: 6px;
	font-weight: bold;
    /*width: 250px;*/
	}

.md-button.md-icon-button{
	margin:0;
	}

.input_new_calendar > md-icon.md-default-theme, md-icon{
	color:#fff;
	}
/*
.am-date-picker__animate-year-selection > md-icon.md-default-theme, md-icon{
	color:#F6921E;
	border:1px solid #F6921E;
	border-radius:100px;
	}*/

.am-date-picker__month-year .md-icon-button{
	border:1px solid #e6e6e6;
	border-radius:100px;
	}
/*
.am-date-picker__days > .md-button.md-default-theme:not([disabled]).md-icon-button:hover,
.am-date-picker__days > .md-button:not([disabled]).md-icon-button:hover{
	background-color: transparent;
	}	*/

.md-button.md-default-theme:not([disabled]).md-icon-button:hover, .md-button:not([disabled]).md-icon-button:hover,
.md-button.md-default-theme:not([disabled]).md-icon-button:focus, .md-button:not([disabled]).md-icon-button:focus{
	background-color:#e6e6e6;
	}

.md-input-container.md-default-theme .md-input, md-input-container .md-input{
	color:#fff;
	}

.weekday0{
	color: #e8534f;
	}

.md-button.md-default-theme.md-primary, .md-button.md-primary{
   color: #4d4d4d;
	}

.md-button.md-default-theme.md-primary.md-raised, .md-button.md-primary.md-raised, .md-button.md-default-theme.md-primary.md-fab, .md-button.md-primary.md-fab{
	background-color:#F6921E;
	font-weight:bold;
	}

.md-button.md-default-theme.md-primary.md-raised:not([disabled]):hover, .md-button.md-primary.md-raised:not([disabled]):hover, .md-button.md-default-theme.md-primary.md-fab:not([disabled]):hover, .md-button.md-primary.md-fab:not([disabled]):hover,
.md-button.md-default-theme.md-primary.md-raised:not([disabled]):focus, .md-button.md-primary.md-raised:not([disabled]):focus, .md-button.md-default-theme.md-primary.md-fab:not([disabled]):focus, .md-button.md-primary.md-fab:not([disabled]):focus {
	background-color:#FFAD38;
   }

.am-date-picker__weekdays span:nth-child(1){
	color:#e8534f;
	opacity:1;
	}

.am-date-picker__input{
	/*padding-top: 6px;*/
    padding-left: 8px;
	margin-top: -6px;
	line-height: 44px;
    color: #fff;
	}

mat-form-field {
	display: initial !important;
}

/* Override Angualr 11 datepicker */
.mat-form-field-wrapper {
	position: initial !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
	padding: 0px !important;
}

.mat-form-field-infix >.mat-input-element {
	padding-bottom: 0.25rem;
	padding-left: 0.75rem;
	margin-top: -0.5em;
	color: white;
	cursor: pointer;
}

.mat-button-wrapper > svg{
	color: white;
}

/*

/* Angular 11 datepicker footer */
.datepicker-footer {
    width: 100%;
    padding: 0 1.125rem 1.125rem 1.125rem;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
}

/*******************************/

.md-button.md-icon-button md-icon, button.md-button.md-fab md-icon {
    background: url(img/title_leaveapplication.png) no-repeat;
    background-size: contain;
}	*/

/*--------------------- swipe mode ---------------------*/

.swipe_mode{
	display:none;
	position: absolute;
    z-index: 1;
    right: -10px;
    height: 100px;
    width: 100%;
    margin-top: -29px;
    /*height: 100%;
    border-radius: 6px;
	border: 1px solid #CCCCCC;*/
    background-color: rgba(230,230,230,0.9);
    opacity: 0;
	}

@media (min-width: 1175px){
.swipe_mode{
	margin-top:-24px;
	}
}

.swipe_mode div{
	margin:40px auto;
	}

.swipe_txt_hide{
	position: absolute;
    top: -34px;
    width: 100%;
    text-align: center;
	}

.btn_swipe_close{
	width: 30px;
    height: 30px;
    position: absolute;
    right: 1px;
    top: 1px;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 50px;
    font-size: 16pt;
    line-height: 18pt;
    text-align: center;
	}

	/*-------- block swipe loading --------*/
.ui-loading .ui-loader{display: block;}
.ui-loader{display: none; position: absolute; opacity: .85; z-index: 100; left: 50%; width: 200px;
	margin-left: -130px; margin-top: -35px; padding: 10px 30px;}

/*--------------------- form checking ---------------------*/

input:required:invalid, input:focus:invalid {
  	-moz-box-shadow: none;
}
/*
input:required:valid {
	background: #F5F5F5 url(img/tick_valid.png) no-repeat right;
    background-size: 14px;
    background-position: 98% 23px;
}*/

/*--------------------- application form - adjust ---------------------*/
.balance_inquiry_result_area{
	margin: auto;
    width: 272px;
	}

#btn_balance_inquiry_search_content{
	margin-top: 70px; /*0px*/
	margin-right: 35px;
	padding:6px;
    width: 280px;
    border: 1px solid #e6e6e6;
	border-radius:6px;
    background-color: #fff; /*rgba(255, 255, 255, 0.8)*/

	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	opacity: 0;

    position: absolute;
	margin-left: 390px; /*290px*/
	display:none;
	}

#btn_balance_inquiry_search_content .alert_box_content_title{
	margin:-6px;
	}

#btn_balance_inquiry_search_close{
	width: 22px;
    height: 22px;
    position: absolute;
    right: 3px;
    top: 2px;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 50px;
    font-size: 12pt;
    line-height: 13pt;
    text-align: center;
	}
/*
.btn_balance_inquiry_search_result{
	padding: 4px;
	margin:-6px;
    margin-bottom: 0;
	}

.btn_balance_inquiry_search_result > div:nth-child(1){
	background-color:#fff;
	}

.btn_balance_inquiry_search_result > div:nth-child(2){
	background-color:#fff;
	}

.btn_balance_inquiry_search_result > div:nth-child(3){
	background-color:#fff;
	}

.btn_balance_inquiry_search_result_break{
	height:4px;
	}
*/
.btn_balance_title{
	float: left;
    box-sizing: border-box;
    width: 128px;
    padding: 6.5px 6px;
    /*margin: 0 2px;*/
	height:31px;
    background-color: #f9f9f9;
	border-bottom:1px solid #e6e6e6;
	border-left: 1px solid #E7E7E7;
	font-size: 9pt;
	color:#fff;
	}

.btn_balance_title_num{
	float: left;
    box-sizing: border-box;
    width: calc(100% - 128px);
    padding: 5px 2px;
    /*margin: 0 2px;*/
	height:31px;
	text-align:center;
	white-space: nowrap;
	font-weight:bold;
    background-color: #f9f9f9;
	border-bottom:1px solid #e6e6e6;
	border-right: 1px solid #E7E7E7;
	color:#fff;
	}

.attachment_file_area{
	max-width: 436px;/*369*/
	margin-right: 30px; /*20px*/
    padding: 10px;
    border-radius: 6px;
	 }

.attachment_file_area > .control{
	float:right;
	width:100%;
	}

.attachment_file_txt{
	position: relative;
	float:left;
	min-width:100%;/*242px*/
	margin-top: 25px;
	margin-bottom: 10px;
	margin-top:6px;
	word-break: break-word;
	}

.attachment_file_txt div{
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

.txt_detail_sub_adjust{
	width:36px;
	line-height: 28px;
	}

.attachment_file_txt > div:nth-child(1){ /* photo preview */
	margin-right:10px;
	margin-top:0;
	}

.attachment_file_txt > div:nth-child(2){ /* photo name */
	width:calc(100% - 66px);
    white-space: normal;
	}

.attachment_file_txt > div:nth-child(3){ /* photo size */
	position: absolute;
    top: 54px;
	word-break: break-all;
    width: 60px;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: center;
	}

.attachment_file_txt > div:nth-child(5){ /* bar loading */
	background-color:#e6e6e6;
	border-radius:6px;
	border:1px solid #fff;
	margin-top: 18px;
	margin-right:0;
	}

.btn_attachment_position{
    float: right;
	width: 106px;
	margin: 4px;
    /*margin-left: 10px;*/
	/*margin-bottom:6px; no more than one btn so delete it*/
	}

.confirm_application_position{
	margin: 0 10px;
   /* width: 250px;*/
	}

.confirm_application_position .box_1_staff{
	text-align: left;
    height: 76px;
    margin-left: -4px;
    margin-right: -15px;
	}

.confirm_application_position .box_1_staff_name{
	width:calc(100% - 80px - 10px);
	margin-top: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

.confirm_application_position .txt_label{
	margin-top:4px;
	}

.confirm_application_position .txt_detail_sub_detail{
	width:calc(100% - 36px);
	white-space: nowrap;
	}

.confirm_application_position .txt_data_confirm{/*OLD*/
	width:148px;
	}

.confirm_application_position .attachment_photo{
	margin-right:6px;
	}

.input_field_small{
	width: 125px;/*136*/
	margin-right:6px;
	}

.input_field_large{
	width: 248px; /* 256 */
	/*height:44px;*/
	margin-bottom:6px;
	}
.input_n_unit > select{
    float: left;
	width: 80px;
    margin-right: 6px;
	}
.input_n_unit > input{
    float: left;
	width: calc(100% - 86px);
	}

.input_field_large select{
	padding-right:30px;
	}

.input_field_large div{
	position: absolute;
    margin-top: -38px;
	margin-left: 230px;
	}

.input_field_large .input_icon_date{
	position: absolute;
    margin-top: -34px;
	margin-left: 226px;
	}

.date_to_line{
    position: absolute;
	width: 10px;
    margin-left: 248px;
    margin-top: 22px;
	border-top:1px solid #F6921E;
	}

.input_field_auto{
	width: 100%;
	margin-bottom:6px;
	}

.input_field_auto select{
	padding-right:36px;
	}

.input_field_auto div{
	position: absolute;
    margin-top: -36px; /* -38px */
	margin-left: 230px;
	}

.input_field_auto .input_icon_date{
	position: absolute;
    margin-top: -34px;
	margin-left: 220px;
	}

.input_field_medium{
	width: 174px;
	margin-bottom:6px;
	}

.input_field_medium select{
	padding-right:30px;
	}

.input_field_medium div{
	position: absolute;
    margin-top: -38px;
	margin-left: 148px;
	}

.input_field_medium .input_icon_date{
	position: absolute;
    margin-top: -34px;
	margin-left: 148px;
	}

.input_field_medium_2{
	width: 196px;
	margin-bottom:6px;
	}

.input_field_medium_2 div{
	position: absolute;
    margin-top: -38px;
	margin-left: 172px;
	}

.input_field_medium_2 .input_icon_date{
	position: absolute;
    margin-top: -34px;
	margin-left: 168px;
	}

.input_section{
	width: 98px;
	}

.input_section div{
	position: absolute;
    margin-top: -37px;
    margin-left: 79px;
	}

.btn_two_margin_left{
	margin-left:6px;
	}

.btn_two_margin_left_m{
	margin-left:6px;
	}

.input_field_small_2{
	width:85px;
	}

.input_field_big{
	width:266px;
	}

/*--------------------- attachment photo ---------------------*/

.detail_box_attachment_area{
	margin-top: 6px;
    height: 62px;
    min-height: 62px;
    border-radius: 6px;
    border: 1px solid #E1E1E1;
	overflow-y: auto;
    overflow-x: hidden;
	}

.attachment_photo{
    position: relative;
	float: left;
    width: 50px;
    height: 50px;
    padding: 4px 2px;
    /* margin-right: 4px; */
    /* margin-top: 6px; */
    border: 1px solid #e6e6e6;
    background-color: #fff;
    text-align: center;
    border-radius: 6px;
    margin: 5px 5px;
	}

.attachment_photo div:nth-child(1){
	height: 55px;
    width: 55px;
    margin-left: -5px;
    margin-top: -5px;
    padding: 5px;
	}

.attachment_photo img{
	max-width:40px;
	max-height:40px;
	}

.attachment_photo_touch{
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 6px;
	}

.attachment_photo_touch:hover,
.attachment_photo_touch:focus{
	background:url(img/btn_photo_preview.png) no-repeat center;
	background-size:contain;
	cursor:pointer;
	}


/*--------------------- list detail box ---------------------*/

.detail_box_top{
	position:relative;
	height: 122px;
	margin:-10px;
    padding-left: 42px;
    padding-top: 42px;
	}

.detail_box_top_photo_mask{
	position:absolute;
	width: 78px;
	height: 78px;
	z-index:1;
	}

.detail_box_top_photo{
    position: absolute;
    height: 78px;
    width: 78px;

	background: #fff url(img/leave_detail_box_top.png);
    background-repeat: no-repeat center;
    background-size: contain;
	}

.detail_box_bottom_area{
	float:left;
	width:279px; /*280*/
	min-height: 476px; /*475*/
    margin-top: 15px;
    padding: 10px;
	text-align: left;
	background-color: #f6f6f6;
	}

.detail_box_bottom_area_margin_right{
	margin-right:10px;
	}

.detail_box_bottom_area .mt0_m > div{
    margin-top: 16px;
	}

.detail_box_bottom_1point{
	height: 214px;
	width:342px;
    margin-left: -20px;
    margin-top: -15px;
    padding-top: 6px;
	background: url(img/detail_box_bottom_1point.png) repeat-x left top;
    background-size: contain;
    text-align: left;
	background-color:#fff;
	}

.detail_box_bottom_1point > .detail_box_session1{
    margin-top: 11px;
	}

.detail_box_bottom_1point > .detail_box_sub_icon{
    margin-top: 32px;
	}


.detail_box_bottom_1point.thin{
	height: 135px;
	background-image: url(img/detail_box_bottom_1point_thin.png);
	}
.detail_box_bottom_1point.thin_2{
	height: 154px;
	background-image: url(img/detail_box_bottom_1point_thin_2.png);
	}
.detail_box_bottom_1point.thin .detail_box_session1{
    margin-top: 0;
	}
.detail_box_bottom_1point.thin .detail_box_sub_icon{
    margin-top: 12px;
	}
.detail_box_bottom_1point.thin .detail_box_session2{
    margin-top: -38px;
	margin-left: 100px;
    width: 50px;
	}
.detail_box_bottom_1point.thin .detail_box_date_num{
    margin-top: -26px;
	}
.detail_box_bottom_1point.thin .detail_box_type{
    margin-top: 0;
	}
.btn_punch_zoom{
	position: absolute;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    top: -4px;
    right: 0;
	background-size:contain;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
	}
.btn_punch_zoom:hover,
.btn_punch_zoom:focus{
    background-color: #e6e6e6;
	cursor:pointer;
	transition: all .6s ease 0s;
	}
.adjusted_punch_detail_left{
    margin: 0 !important;
    max-height: 306px;
    overflow: auto;
	}

.detail_box_bottom_2point{
	height: 214px;
	width:342px;
    margin-left: -20px;
    margin-top: -15px;
    padding-top: 6px;
	background: url(img/leave_detail_box_bottom.png) repeat-x left top;
    background-size: contain;
    text-align: left;
	background-color:#fff;
	}

.detail_box_bottom_2point_no_type{/*_no_type*/
	height: 214px;
	width:342px;
    margin-left: -20px;
    margin-top: -15px;
    padding-top: 6px;
	background: url(img/leave_detail_box_bottom_no_type.png) repeat-x left top;
    background-size: contain;
    text-align: left;
	background-color:#fff;
	}

.detail_box_bottom_2point_leave_balance{
	height: 167px;
	width:300px;
    margin-left: -20px;
    margin-top: -15px;
    padding-top: 12px;
	background: url(img/leave_balance_detail_box_bottom.png) repeat-x left top;
    background-size: contain;
    text-align: left;
	background-color:#fff;
	}

.detail_box_name{
	position: absolute;
    width: 478px;
    z-index: 2;
	left: 124px;
    top: 45px;

    text-align: left;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

.btn_catalog_arrow{
	border:1px solid #e6e6e6;
	padding:4px;
    border-radius: 6px;
	}

.btn_catalog_arrow:hover,
.btn_catalog_arrow:focus {
	background-color:#F3F3F3;
	cursor:pointer;
	}

.detail_box_catalog_area{
    position: relative;
    width: 134px;
    height: 50px;
	margin-left: 14px;
	margin-top: -4px;
	padding: 3px 8px;
	color: #fff;
    border-radius: 6px;
    text-align: center;
	}

.detail_box_catalog_area div{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	max-height:50px;
	overflow:hidden;

    padding: 2px 6px;
    border-radius: 6px;

	white-space: nowrap;
	}

.detail_box_catalog_area_balance{
    color: #fff;
	width: 164px;
	height: 44px;
	text-align: center;
	}

.detail_box_catalog_area_balance .detail_box_catalog_txt{
	margin:10px 0;
	padding: 3px 8px;
    border-radius: 6px;
	text-align:center;
	}

.approver_label span{
	padding: 3px 6px;
    color: #fff;
    border-radius: 4px;
    text-align: left;
	line-height: 30px;
	}

.detail_box_session1{
	width: 66px;
    text-align: center;
    margin-left: 84px;
	margin-top:-2px;
	}

.detail_box_date1{
	width: 154px;
    margin-left: 152px;
    margin-top: -15px;
	}

.detail_box_left_session1{
	position: absolute;
    margin-top: -6px;/*4*/
    width: 82px;
    color: #9a9a9a;
    padding: 0 4px;
	text-align:center;
	}

.detail_box_session2{
	width: 66px;
    text-align: center;
    margin-left: 84px;
    margin-top: 17px;
	}

.detail_box_date2{
	width: 154px;
    margin-left: 152px;
    margin-top: -14px;
	}

.detail_box_date1,
.detail_box_date2{
	font-size:12pt !important;
	}

.detail_box_sub_icon{
	width:48px;
	height:48px;
	margin-left:57px;
	margin-top:22px;
	}

.detail_box_sub_icon_txtonly{
	width:48px;
	height:48px;
	margin-left:57px;
	margin-top:8px;
	}

.detail_box_date_num{
    margin-left: 152px;
    margin-top: -54px;
	}

.detail_box_type{
    margin-left: 152px;
    margin-top: -1px;/*-4*/
	width:148px;
	height:42px;
	overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
	/*overflow:hidden;
    text-overflow: initial;
    white-space: initial;*/
	}

.detail_box_other_balance > div{
	margin-top:12px;
	}

.detail_box_other > div:nth-of-type(1),
.detail_box_other > div:nth-of-type(2) {
	margin-top:10px;/*12*/
	}

.detail_staff_remark{
    height: 104px; /* 66 */
	}

.approver_staff_content{
    float: left;
    width: calc(100% - 48px - 4px);
    margin-left: 4px;
	}

.approver_staff_content > .txt_box_1{
    margin-top: 4px;
	}

.approver_staff_remark_input{
    height: 104px;
	}

.department_area{
	margin-top: 4px;
    line-height: 34px;
	height:83px;
	}

.department_area span{
	background-color:#f6f6f6;
	}

.txt_department_label{
	padding: 3px 4px;
    border-radius: 6px;
	margin-top:10px;
    margin-right: 2px;
	}

.detail_box_balance_detail{
	float:left;
	width:33.3%;
	margin-top:-6px;
	}

.detail_box_approver_remarks_area{
	height: 386px;
	min-height:62px;
    margin-top: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 2px;
    /* padding-bottom: 21px; */
    /* border: 1px solid #999999; */
    border-radius: 6px;
    border: 1px solid #E1E1E1;
	}

.submit_remarks_area{
    background-color: #f6f6f6;
    padding: 10px;
	text-align:left;
    border-radius: 6px;
	margin-top: 10px;
	}

.detail_box_approver_remarks_area > .flag_next_approvers{
	width: calc(100% - 19px);
    margin-left: 13px;
    margin-bottom: 6px;
	}


/*--------------------- filter ---------------------*/
/*
#container1 {
    height: 100%;
    width:100px;
    border: 1px solid green;

    overflow: hidden;
    position: relative;
}
#container2 {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;  exact value is given through JavaScript

    border: 1px solid blue;
    overflow: auto;
}*/
/*
.filter_area{
    position: relative;
    overflow: hidden;
	width: 100%;
	height: 50px;
	background-color:#F7F7F7;
	}

.filter_area > ul{

    position: absolute;
    overflow-x: auto;
	overflow-y:hidden;

	top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
	}

.filter_area > ul::-webkit-scrollbar{
	display:none;
	}*/

ul#filter_options,
ul.common_filter {
	float:left;
	margin-bottom: 6px;
    padding: 0px;
    list-style: none;
	font-size:10pt;

	/*--delete------width: 320px;
    margin: 10px 0px;
	margin-top:0;
    display: inline-flex;*/
	}

ul#filter_options:hover,
ul#filter_options:focus,
ul.common_filter:hover,
ul.common_filter:focus{
	background-color:transparent;
	}

ul#filter_options li,
ul.common_filter li{
	position:relative;
	float: left;
	display: inline;
	margin-right: -1px;
	cursor:pointer;
	/*background-color:rgba(255,255,255,0.9);*/
	/*background-image:url(img/btn_num_corner.png);
	background-repeat:no-repeat;
	background-position:right;
	background-size:32px;*/
	}

ul#filter_options li a,
ul.common_filter li a{
    /*float: left;*/
    margin: 4px;
    color: #9a9a9a;
    border: 1px solid #e6e6e6;
    padding: 11px 11px; /*11 6*/
    line-height: 15pt;/* 1.471 */
    border-radius: 6px;
	text-align:center;

	white-space:nowrap;
	display: block;

	max-width: 308px;
    overflow: hidden;
	}

ul#filter_options li a:hover,
ul#filter_options li a:focus,
ul.common_filter li a:hover,
ul.common_filter li a:focus{
	background: #F6921E;
	border: 1px solid #F6921E;
	color:#fff;
	}

ul#filter_options li.active a,
ul.common_filter li.active a{
	background: #F6921E;
	border: 1px solid #F6921E;
	color:#fff;
	}

ul#filter_options .f_all,
ul.common_filter .f_all{
	/*width:52px;*/
	}

/*--------------------- filter (personal profile) ---------------------*/

.btn_reset_profile ul#filter_options{
	bottom: 0;
    position: fixed;
    max-width: none;
    width: calc(100% - 220px);
    margin: 0;
    z-index: 4;
    left: 220px;
	padding: 0;/*4px 0*/

    background-color: #fff;
    -webkit-box-shadow: 0px 0px 8px #ccc;
    -moz-box-shadow: 0px 0px 8px #ccc;
    box-shadow: 0px 0px 8px #ccc;
    border-top: 1px solid #CDCDCD;
	}

/* old style
.btn_reset_profile ul#filter_options{
	float:none;
	height:auto;

	padding: 4px 0;
    margin: 20px 0;
    max-width: 884px;
    background-color: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	}*/

.btn_reset_profile ul#filter_options li{
	margin-right: 0;

	width: calc(100% / 6 - 0.5px);
    /*border-right: 1px solid #F0F0F0;*/
	}

.btn_reset_profile ul#filter_options li:nth-child(6){
	border:none;
	}

.btn_reset_profile ul#filter_options li a{
	/*font-size:10pt;*/
	max-width:none;
	margin: 0;/*0 4px*/
	padding: 3px 0;/*6px 0*/
	line-height:14pt;
	border: none;
    color: #9a9a9a;
    text-align: center;
    border-radius: 0;
	}

.btn_reset_profile ul img{
	margin: 2px;
	width: 20px;
	}

.btn_reset_profile ul#filter_options li a:hover,
.btn_reset_profile ul#filter_options li a:focus {
	background: #ececec;
	/*border: 1px solid #cccccc;*/
	color:#9a9a9a;
	}

.btn_reset_profile ul#filter_options li.active a {
	background: #ececec;
	/*border: 1px solid #cccccc;*/
	color:#9a9a9a;
	}

@media (max-width: 840px){ /*1036px*/

ul#filter_options{
	margin-left: -8px;
    min-width: 316px;
	}

.btn_reset_profile ul#filter_options{
	margin:0;
	padding:0;
	border-radius:0;

	position: fixed;
    bottom: 0px;
    left: 0;
    z-index: 4;

	-webkit-box-shadow: 0px 0px 8px #ccc;
    -moz-box-shadow: 0px 0px 8px #ccc;
    box-shadow: 0px 0px 8px #ccc;

	/*margin-left:220px;
	width:calc(100% - 220px);*/
	width:100%;
	border-top: 1px solid #CDCDCD;
	}


.btn_reset_profile ul#filter_options li{
	width: calc(100% / 6 - 0.5px);
	}

.btn_reset_profile ul#filter_options li a{
	width: 100%;
	border:none;
	border-radius:0;
	margin:0;

	padding-top:3px;
	padding-bottom:6px;
	}

.btn_reset_profile ul#filter_options li a:hover,
.btn_reset_profile ul#filter_options li a:focus {
	border:none;
	}

.btn_reset_profile ul#filter_options li.active a {
	border:none;
	}

.btn_reset_profile ul#filter_options > li > a > div > span{
	display:none;
	}

.btn_reset_profile ul img{
	margin: 5px;
	margin-top: 12px; /*9*/
	}

.btn_reset_profile ul#filter_options li.active a {
	border-top: none
	}

}

@media (max-width: 767px){

.btn_reset_profile ul#filter_options{
	margin-left:0;
	width:100%;
	}

.btn_reset_profile ul#filter_options li a{
	font-size:7.6pt;
	}

}

/*--------------------- from - checkbox muit-select ---------------------*/

.mult_select_checkbox{
	display:none;
	position:absolute;
    font-size: 30px;
	margin-top: 8px;
    margin-left: -22px;
	}

.mult_select_approval_area{
	display:none;
	width: calc(100% - 220px);
    left: 220px;
	padding-left:70px;

	position: fixed;
    _position: absolute;
    _z-index: 10;
    _position: absolute;

    z-index: 10;
	bottom: 0;
    text-align: left;

	-webkit-box-shadow: 0px 0px 8px #ccc;
    -moz-box-shadow: 0px 0px 8px #ccc;
    box-shadow: 0px 0px 8px #ccc;

	background-color: rgba(255,255,255,0.9);
	}

.mult_select_approval_area .pop_functions_two_btn_center{
	display:inline-flex;
	width:322px;
	}

.mult_select_approval_area .header_bar_icon{
	margin-left: 5px;
	margin-right: 2px;
    width: 36px;
    height: 36px;
    border-radius: 100px;
    line-height: 34px;
    text-align: center;
	}

/*--------------------- switch button ---------------------*/

.onoffswitch {
    position: relative;
	width: 144px;
	/*margin-left: -2px;*/
    -webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
	overflow: hidden;
	cursor: pointer;
	border-radius: 8px;
    /*border: 2px solid #FFFFFF;
	margin-top: -2px;*/
}
.onoffswitch-inner {
    display: block;
	width: 200%;
	margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
	float: left;
	width: 50%;
	height: 44px;
	padding: 0;
	line-height: 44px;
    color: white;
    box-sizing: border-box;
}
/*
.onoffswitch-inner:before {
    content: "Teammate";
    padding-left: 12px;
    background-color: #F6921E;
	color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "Personal";
	padding-right:20px;
    background-color: #FFAD38;
	color: #FFFFFF;
    text-align: right;
}*/
.onoffswitch-inner:before{
   	content: attr(data-content-before);
	padding-left:10px;
   	background-color: #FFAD38;
	color: #FFFFFF;
   	text-align: left;
}
.onoffswitch-inner:after {
   	content: attr(data-content-after);
   	padding-left: 10px;/*44 move effect*/
   	background-color: #F6921E;
	color: #FFFFFF;
   	text-align: left;
}

.onoffswitch-switch {
    display: block;
    position: absolute;
	width: 20px;
	height: 20px;
	margin: auto 10px;
	top: 0;
	bottom: 0;
	background-image:url(img/input_id_w.png);
	background-repeat:no-repeat;
	background-size: 18px;
    background-position: center;

	right: 0px; /*102px move effect*/
    transition: all 0.3s ease-in 0s;

	/*
	background-color: #FFFFFF;
    border: 2px solid #FFFFFF;
	border-radius: 28px;*/
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

/*--------------------- switch button_yes_no ---------------------*/

.onoffswitch_yes_no {
	position: relative;
	/*margin-left: -2px;*/
    -webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select: none;

    float: left;
    width: 70px;/*80*/
    margin-right: 4px;
}
.onoffswitch_yes_no-input_new_calendar { /* reset input_new_calendar */
	width: calc(100% - 74px);/*calc(100% - 82px)*/
}
.onoffswitch_yes_no-checkbox {
    display: none;
}
.onoffswitch_yes_no-label {
    display: block;
	overflow: hidden;
	cursor: pointer;
	border-radius: 8px;
    /*border: 2px solid #FFFFFF;
	margin-top: -2px;*/
}
.onoffswitch_yes_no-inner {
    display: block;
	width: 200%;
	margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch_yes_no-inner:before, .onoffswitch_yes_no-inner:after {
    display: block;
	float: left;
	width: 50%;
	height: 44px;
	padding: 0;
	line-height: 44px;
    color: white;
    box-sizing: border-box;
}
.onoffswitch_yes_no-inner:before{
   	content: attr(data-content-before);
	padding-left:10px;
   	background-color: #FFAD38;
	color: #FFFFFF;
   	text-align: left;
}
.onoffswitch_yes_no-inner:after {
   	content: attr(data-content-after);
   	padding-left: 10px;/*44 move effect*/
   	background-color: #F6921E;
	color: #FFFFFF;
   	text-align: left;
}

.onoffswitch_yes_no-switch {
    display: block;
    position: absolute;
	width: 20px;
	height: 20px;
	margin: auto 10px;
	top: 0;
	bottom: 0;
	/*background-image:url(img/input_id_w.png);*/
	background-repeat:no-repeat;
	background-size: 18px;
    background-position: center;

	right: 0px; /*102px move effect*/
    transition: all 0.3s ease-in 0s;

	/*
	background-color: #FFFFFF;
    border: 2px solid #FFFFFF;
	border-radius: 28px;*/
}

.onoffswitch_yes_no-checkbox:checked + .onoffswitch_yes_no-label .onoffswitch_yes_no-inner {
    margin-left: 0;
}
.onoffswitch_yes_no-checkbox:checked + .onoffswitch_yes_no-label .onoffswitch_yes_no-switch {
    right: 0px;
}

/*--------------------- form date ---------------------*/
.btn_date{
	height:44px;
	padding: 10px 11px;
    font-size: 15px;
    line-height: 1.6;
    border: none;
	text-align:left;

    -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
    transition: border .25s linear, color .25s linear, background-color .25s linear;
    -webkit-font-smoothing: subpixel-antialiased;

	}

.btn_date::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
.btn_date::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
.btn_date:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
.btn_date:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

#ui-datepicker-div{
	display:none;
    width: 248px;
	margin-top: 4px;
    border-radius: 6px;
	background-color:#F3F4F5;
	border: 1px solid #BDC3C7;

    -webkit-box-shadow: 0px 0px 20px #ccc;
    -moz-box-shadow: 0px 0px 20px #ccc;
    box-shadow: 0px 0px 20px #ccc;
	}

.ui-datepicker-header{
	line-height:44px;
	margin: 0px 8px;
	}

.ui-datepicker-prev,
.ui-datepicker-next{
	margin:10px 0;
	width: 44px;
    text-align: center;
	}

.ui-datepicker-prev{
	float:left;
	}

.ui-datepicker-next{
	float:right;
	border-left: 1px solid #DADBDC;
	}

.ui-datepicker-prev{
	border-right: 1px solid #DADBDC;
	}

.ui-datepicker-prev:hover,
.ui-datepicker-prev:focus,
.ui-datepicker-next:hover,
.ui-datepicker-next:focus,
.ui-datepicker-title select:hover,
.ui-datepicker-title select:focus{
	border-radius:6px;
	background-color:#DADBDC;
	cursor:pointer;
	}

.ui-datepicker-title{
	text-align:center;
	padding: 10px 0;
	}

.ui-datepicker-title select{
	padding: 8px;
	line-height: 21px;
    margin-right: 10px;
    border: 1px solid #DADBDC;
	background-color:#fff;
	outline:0;
    -webkit-appearance: none;
    -moz-appearance: none;
	/*
	background-image: url(img/header_setting.png);
	background-repeat:no-repeat;
	background-position:50% 96%;
	background-size: 8px;*/
	}

.ui-datepicker-calendar{
	width: 100%;
    margin: 0 auto;
	margin-bottom: 6px;
    background-color: #fff;
	}

.ui-datepicker-calendar td, .ui-datepicker-calendar th{
	border:1px solid #EAEAEA;
	text-align:center;
	cursor:pointer;
	line-height:32px;
	}

.ui-datepicker-calendar th{
	font-size: 9pt;
	line-height: 20px;
	color:#9E9E9E;
	background-color:#EAEAEA;
	}

.ui-datepicker-calendar td:hover,
.ui-datepicker-calendar td:focus{
	background:#DADBDC;
	color:#4d4d4d
	}

.ui-datepicker-current-day{
	background-color:#DADBDC;
	}

#datepicker-1, #datepicker-2, .btn_date{
	background-image:url(img/input_date.png);
	background-repeat:no-repeat;
	background-size:18px;
	background-position:calc(100% - 8px) 50%;
	padding-right:34px;
	}

.ui-datepicker-calendar tr td{
	width:calc(100% / 7);
	}

.ui-datepicker-calendar tr td:nth-child(1){
	color:#e74c3c;
	}

.ui-datepicker-calendar tr:nth-child(1) th:nth-child(1){
	color:#e74c3c;
	}

td.ui-datepicker-other-month.ui-datepicker-unselectable.ui-state-disabled{
	border:0;
	}

td.ui-datepicker-other-month.ui-datepicker-unselectable.ui-state-disabled:hover,
td.ui-datepicker-other-month.ui-datepicker-unselectable.ui-state-disabled:focus{
	background:none;
	cursor:default;
	}

/*--------------------- form dropdown > li > a ---------------------*/
.btn_dropdown_1{
    color: #fff;
    width: 100%;   /* 196px */
	max-width:248px;
	height:44px;
    line-height: 24px;
	text-align: left;
	-webkit-appearance:none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
	padding-right:24px;

	background-image:url(img/list_arrow_down.png);
	background-repeat:no-repeat;
	background-size:12px;
	background-position:calc(100% - 10px) 50%;
}

.btn_dropdown_calendar{
	background-image:url(img/input_date.png);
	background-size:18px;
}

.dropdown-menu_1 > li > a{
	line-height:32px; /* = height:48px*/
	}

.btn_dropdown_calendar_grey{
	max-width: 284px;
	/*height: 36px;
    line-height: 24px;*/
    padding: 0 10px !important;
	padding-right: 30px !important;
    background-color: #F5F5F5;
    color: #9a9a9a;
    border: 1px solid #e6e6e6;
    background-image: url(img/list_arrow_down_g.png);
	background-size:10px;
	}

.btn_dropdown_calendar_grey:hover,
.btn_dropdown_calendar_grey:focus{
    background-color: #e6e6e6;
	color:#9a9a9a !important;
	}
	/*
.btn_dropdown_1_arrow{
	position:absolute;
    margin-top: -23px;
    margin-left: 168px;
    background: url(img/list_arrow_down.png) no-repeat center;
    background-size: contain;
    width: 16px;
    height: 8px;
	}	*/

.btn_dropdown_1_arrow {
    position: absolute;
    margin-top: 18px;
    margin-left: 77px;
    background: url(img/list_arrow_down.png) no-repeat center;
    background-size: contain;
    width: 16px;
    height: 8px;
	}

.btn_sub_title{
	padding-left: 10px;
    line-height: 22px;
	font-size:9pt;
	}

select::-ms-expand{
	display:none
	}

/*--------------------- form dropdown > li > div ---------------------*/
.dropdown-menu li div {
    padding: 12px;
    white-space: normal;
}

.dropdown-menu > li > div {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 37px;/*1.42857143*/
	font-size:15px;
    color: #333;
    white-space: nowrap;
}

/*--------------------- form checkbox ---------------------*/

.checkbox_reset_1{
	margin-top:16px;
	margin-bottom:10px;
	text-align: left;
    margin-left: 14px;
	}

.checkbox_reset_1 input[type=checkbox] {
	display:none;
}

.checkbox_reset_1 input[type=checkbox] + label{
	height: 20px;
	width: 20px;
	display:inline-block;
	padding: 0;
	border-radius:6px;
    /*margin-left: -110px;*/
	background:#fff;
	border:1px solid #9a9a9a;
	cursor:pointer;
}

.checkbox_reset_1 input[type=checkbox]:checked + label{
	background:#9a9a9a url(img/arrow_checkbox.png) no-repeat center;
	background-size:60%;
	display:inline-block;
	padding: 0;
	cursor:pointer;
}

.checkbox_reset_txt{
    position: absolute;
	max-width: 255px;
    padding-left: 26px;
    margin-top: -2px;
	}

/*--------------------- tabs ---------------------*/

		ul.tabs{
			margin: 0px;
			padding: 0px;
			list-style: none;
		}
		ul.tabs_year_bar{ /* team info */
			width:300px;
			margin:auto;
			}
		ul.tabs li{
			display: inline-block;
			padding: 10px 15px;
			cursor: pointer;
		}

		.tab-content{
			display: none;
		}

		.tab-content.maincolor_btn-selected{
			display: inherit;
		}

/*--------------------- data list - all grid bar chart (big) ---------------------*/

.grid_bar_area{
	padding:10px;
	padding-top:0;
	text-align:center;
	}

.grid_bar_area .bar_chart_item{
	background-image: url(img/grid_2_big.png);
	background-size:contain;
	}

@media(max-width: 940px){
.grid_bar_area .bar_chart_item{
	background-image: url(img/grid_2.png);
	}
}


/*--------------------- data list - bar h chart ---------------------*/

.bar_h_chart_area{
	width:300px;
	height:208px;
	margin:20px auto;
	}

.bar_h_chart_item{
	width:calc(300px / 3);
	height:100%;
	float:left;
	}

.bar_h_chart_data{
	position:relative;
	height:calc(208px - 36px);

	border-bottom: 1px solid #e6e6e6;
	text-align:center;
	background-image:url(img/grid_1.png);
	background-size: contain;
	}

.bar_h_chart_bar_day{
	position: absolute;
	width:100px;/*74*/
	/*left: calc(50% - 74px/2);*/
	}

.bar_h_chart_bar{
	position: absolute;
    bottom: 0;
	width:40px;
	left: calc(50% - 40px/2);
	/*background-image:url(img/bar_h_light.png);*/
	background-repeat:no-repeat;
	background-position: bottom;
    background-size: 84% 100%;

	border-radius:6px 6px 0 0;
	margin:0 auto;
	}

.bar_h_chart_name{
	height:40px;
    margin-top: 4px;
	}

/*--------------------- data list - bar chart ---------------------*/

.bar_chart_area{
	float:left;
	width:100%;
	height: 74px;/*84*/
    box-sizing: content-box;
	}

.bar_chart_name{
    /* float: left;
	margin-top: 22px; */

	position: absolute;
    margin-left: 50px;
    margin-top: 19px;/*49*/
    padding: 0 4px;
    width: calc(100% - 86px);/*336px*/

    text-align: left;
    font-size: 9pt;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

.bar_chart_photo{
	float:left;
	width:48px;
	height:100%;
	margin-top:19px;
    /*border-right: 1px solid #e6e6e6;*/
	}

.bar_chart_item{
	float: left;
    width: calc(100% - 48px); /*calc(100% - 85px - 48px)*/
    height: 100%;
    padding-top: calc(52px / 2 - 10px / 2); /* HEIGHT of photo and name VS bar_chart_data */
	background-image:url(img/grid_2.png);
	background-size:contain;
	margin-top:17px;
	}

.bar_chart_data{
	float:left;
	height:10px;
	border-radius:0 6px 6px 0;
	/*background-image:url(img/bar_v_light.png);*/
	background-repeat:no-repeat;
	background-position: left;
    background-size: 100% 50%;
	}

.bar_chart_num{
	position: absolute;
    margin-top: 14px;
    margin-left: 5px;
    line-height: 11pt;
	}

.bar_chart_num_arrow{
	position: absolute;
	margin-left: 60px;
    margin-top: 4px;
	background:url(img/bar_arrow.png) no-repeat center;
	background-size:contain;
	height: 14px;
	width: 14px;
	}

/*
.bar_chart_num{
	float:left;
	position: relative;
	width:76px;
    margin-left: -76px;
    margin-top: -52px;
	padding:4px 2px;
	background-color:#f3f3f3;
	border-radius:6px 6px 0 6px;
	}

.bar_chart_num_arrow{
	position: absolute;
	margin-left: 60px;
    margin-top: 4px;
	background:url(img/bar_arrow.png) no-repeat center;
	background-size:contain;
	height: 14px;
	width: 14px;
	}*/

/*--------------------- Next Approver ---------------------*/

.btn_next_approver {
	width: 100%;
	border: none;
	background-color: #F6921E;
	color: white;
	border-radius: 5px;
	margin-top: 4px;
	padding: 10px;
}

/*--------------------- data list - circle ---------------------*/

.page{
	margin:0 auto;
	width:292px;
	}

.box_circle{
	float:left;
    width: 50%;
	margin: 14px auto;
	text-align:center;
	}

.box_circle .c100{
	margin-left: 34px;
	}

.txt_circle{
    font-size: 18pt;
    position: absolute;
    z-index: 2;
    text-align: center;
	width: 100%;
    margin-top: 20px;
	}


/*------------------ data list - home ------------------*/

.box_grid_1{
	float:left;
	width:31.6%;
	margin-left: 5px;
	margin-top: 9px;
	padding:4px 4px;
	text-align:center;
	}

.box_1_fix_width{
	width:292px;
	margin: 8px auto;
	}

.box_1_auto_width{/*
	width:calc(100% - 20px);
	margin:10px;*/
	padding:10px;
	text-align:left;
	}

.box_1_staff{
	text-align:left;
	margin: 10px 0;
	/*margin: 18px 10px;
	height: 76px;*/
	/*margin-left: -6px;*/
	}

.grid_bar_area .box_1_staff{
	margin: 18px 10px;
	height: 76px;
	/*margin-left: -6px;*/
	}

	/*
.box_1_staff:hover,
.box_1_staff:focus{
	opacity:0.6;
	cursor:pointer;
	}*/

.box_1_staff_name{
	float:left;
	margin-top: 20px;
	margin-left: 10px;
	}

.box_1_staff_name_limit{
	float: left;
    margin-top: 20px;
    margin-left: 10px;
    max-width: 200px;
    max-height: 63px;
    overflow: hidden;
	}

.box_1_staff_name_limit div:nth-child(1){
    max-height: 44px;
    overflow: hidden;
	}

.box_1_loading{
	position:absolute;
	width: 100%;
    height: 100%;
    z-index: 3;
    margin: -6px;
    border-radius: 6px;

	background: url(img/loader.gif) center no-repeat;
    background-color: rgba(251, 251, 251, 0.8);
    background-size: 50px;
	}

.box_1_loading div{
	display: block;
    position: absolute;
    left: calc(50% - 33px);
    top: calc(50% + 34px);
	}

.box_h_1{
	height: 250px;
	}

.box_h_2{
	height: 435px;/*412*/
	}

.box_notice_area{
    overflow: auto;
    height: 306px;
	}

.txt_detail_box_content{
	margin-left: 88px;
	}

.txt_detail_notice_title{
	text-align:left;
    margin-left: 4px;
    margin-bottom: 10px;

	font-weight: bold;
    font-family: sans-serif;
    font-size: 12pt;
	}

.txt_detail_notice_date{
	text-align:left;
	line-height: 26pt;
    margin-left: 4px;
	}

/*--------------------- data list ---------------------*/

.list_data{
	text-align: center;
	margin-top: 6px;
}

.personal_profile_area{ /* For personal profile page
	margin-top:-6px;*/
	}

.inquiry_box_two_btn_center{
	margin-left: -4px;
	}

.box_1{
	position:relative;
	float:left;
	width:400px; /* 312px   del : min-width:48%*/
	margin: 20px 20px;
	padding: 6px 6px;
	/*height:228px;*/
	background-color:#fff;
    border-radius: 6px;

    -webkit-box-shadow: 0px 0px 2px #ccc;
    -moz-box-shadow: 0px 0px 2px #ccc;
    box-shadow: 0px 0px 2px #ccc;
	}

.box_1_full{
	width: 840px;
	height: auto;
	min-height:435px;
    padding-bottom: 30px;
	}
.box_1_full .page{
	width: 100%;
	}
.box_1_full .box_circle{
	width: 148px;
	}
.box_1_full .box_circle .txt_box_label_name{
	max-height: 40px;
    overflow: hidden;
    text-overflow: inherit;
    white-space: normal;
	}

.box_1_bottom_text{
	position: absolute;
    bottom: 29px;
    left: calc(50% - 226px / 2);
	}

.box_title{
	width: 100%;
    line-height: 36px;
    text-align: left;
    padding-left: 40px;
	margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

.box_title_reset_notice{
	top: 10px;
    position: absolute;
    width: calc(100% - 20px);
	}

.box_title_icon{
    position: absolute;
    margin-left: -40px;
    height: 36px;
    width: 36px;
    margin-top: 1px;
	}

.box_content_inquiry{
	/*margin-left:36px;*/
	margin:0 28px;
	margin-bottom:8px;
	}

.box_content_inquiry_field{
	float:left;
	margin-right:10px;
	margin-top:10px;
	}

.box_content_inquiry_field textarea{
	width:248px;
	}

.box_title_sub{
	margin-left:40px;
	text-align:left;
	}

.txt_result{
	margin-left:36px;
	margin-top:4px;
	font-size:10pt;
	}

.txt_result_title{
	float: left;
    width: 50px;
	white-space: nowrap;

    margin-top: 3px;
	}

.txt_result_item_area{
	float: left;
    width: calc(100% - 50px);

    margin-top: 3px;
	}

.txt_result_item{
	float: left;
    margin-right: 3px;
    margin-bottom: 3px;
    padding: 2px 4px;
	border-radius:6px;
	white-space:nowrap;
	}

.txt_result_item:hover,
.txt_result_item:focus{
	opacity:0.7;
	cursor:pointer;
	}

.box_more{
	position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
	background-color:#fff;
    border-top: 1px solid #e6e6e6;
	color: #9a9a9a;
    line-height: 36px;
	margin-top: 10px;
	text-align:center;
	border-radius: 0 0 6px 6px;
	cursor:pointer;
	}

.box_more:hover,
.box_more:focus{
    background-color: #F3F3F3;
	}

.title_bar_icon{
    width: 34px;
    height: 34px;
    border-radius: 100px;
    line-height: 28px;
    text-align: center;
    /* border: 1px solid #e6e6e6; */
    cursor: pointer;
	}

.title_bar_icon:hover,
.title_bar_icon:focus {
	border:1px solid #e6e6e6;
	background-color:#F3F3F3;
	}

.list_1_box{
	position:relative;  /* for swipe_mode box position base */
    text-align:left;
	width:100%;
	padding: 23px 8px;
	margin-top: 0;
	margin: 2px 2px;
	border: 1px solid #ffffff;
	border-bottom: 1px solid #e6e6e6;
	background-color:#fff;
	line-height:20px;
	cursor:pointer;
	/*
	-webkit-box-shadow: 0px 0px 8px #ccc;
    -moz-box-shadow: 0px 0px 8px #ccc;
    box-shadow: 0px 0px 8px #ccc;*/
	}

.list_1_box:hover,
.list_1_box:focus {
	border: 1px solid #999999;
	border-radius: 6px;
	}


.list_1_box_content:hover,
.list_1_box_content:focus {
	opacity:0.6;
	}

.txt_photo_name{
	line-height:15px;
    margin-top: 4px;
	}

.txt_photo_area_big{
	margin:0 auto;
	width:80px;
	height:80px;
	background-color:#fff;

	background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
	}

.txt_photo_mask_big{
	position:relative;
    height: 80px;
    width: 80px;
	}

.txt_photo_area_small{
	margin:0 auto;
	width:42px;
	height:42px;
	background-color:#fff;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.txt_photo_mask_small{
	position: relative;
    height: 42px;
    width: 42px;
	}

.txt_photo_area_medium{
	float:left;

	margin:0 auto;
	width:42px;
	height:42px;
	background-color:#fff;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.txt_photo_mask_medium{
	position: absolute;
    height: 48px;
    width: 48px;
	}

.txt_photo_mask_num{
	position:relative;
    height: 24px;
    width: 24px;
    margin-top: -80px;
    margin-left: 56px;
    color: #fff;
    font-size: 10pt;
	text-align:center;
    line-height: 17pt;
    border-radius: 100px;
    font-weight: bold;
	}

.txt_content_area_1line{
	 float:left;
     margin-left: 5px;
     margin-top: 10px;
     margin-bottom: 6px;
	 width:calc(100% - 48px - 5px);
	 }

.txt_content_area_1line div{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

.page_leave_balance_inquiry .txt_content_area_1line{ /* leave balance inquiry */
	margin-top: 4px;
	}

.page_leave_balance_inquiry .txt_content_area_1line .txt_name{ /* leave balance inquiry */
	width: 388px;/*calc(100% - 114px)*/
	margin-right:10px;
	overflow: hidden;
    white-space: normal;
	}

.page_leave_balance_inquiry .txt_content_area_1line .txt_jobposition{ /* leave balance inquiry */
	width: 386px;
	}

.txt_content_area_2line{
	 float:left;
     margin-left: 5px;
     margin-top: 4px;
     margin-bottom: 4px;
	 width: calc(100% - 48px - 5px);
	 }

.txt_content_area_2line div{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	word-wrap: break-word;
	}

.txt_name{
	float:left;
	width:100%;
	color:#000;
	font-size:11pt;
    font-weight: bold;
    font-family: sans-serif;
	}

.txt_name_size_3{
	color:#000;
	font-size:9pt;
    font-weight: bold;
    font-family: sans-serif;
	}

.txt_name_withlabel{
	float: left;
    width: 100%;
    color: #000;
    font-size: 11pt;
    font-weight: bold;
    font-family: sans-serif;
	}

.txt_date{
	float:left;
    width: 284px;
	white-space:nowrap;
}

.txt_days{
	float:left;
	width: 106px; /*118 76*/
	margin-right:10px;
}

.txt_type{
	float:left;
	width: 228px; /*204 208*/
	margin-right:10px;
}

.txt_status{
	float:left;
	width: 144px; /*148*/
    margin-right: 10px;
}

.txt_remark{
	float:left;
	width: 160px;
}

.txt_position{
	float:left;
	width: 160px;
}
       /*------- For claim application inquiry SHK inquiry -------*/

.page_claim_application_inquiry_shk .txt_days,
.page_claim_application_inquiry_shk .txt_date,
.page_claim_application_inquiry_shk .txt_type{
	overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    word-wrap: break-word;
	}
.page_claim_application_inquiry_shk .txt_days{
	width: 100%;
	}
.page_claim_application_inquiry_shk .txt_date{
	margin-top:10px;
	/*white-space:normal;*/
	width: 280px;
	margin-right:10px;
	}
.page_claim_application_inquiry_shk .txt_type{
	margin-top: 10px;
	width: 200px;
	}

       /*------- For ot inquiry -------*/

.page_ot_application_inquiry .txt_date,
.page_ot_application_inquiry .txt_type,
.page_ot_application_inquiry .txt_status,
.page_pending_approval .f_preot .txt_date,
.page_pending_approval .f_postot .txt_date{
	margin-top:10px;
	overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    word-wrap: break-word;
	}

.page_ot_application_inquiry .txt_date,
.page_pending_approval .f_preot .txt_date,
.page_pending_approval .f_postot .txt_date{
	white-space:normal;
	width: 280px;
	margin-right:10px;
	}
.page_ot_application_inquiry .txt_type,
.page_pending_approval .f_preot .txt_type,
.page_pending_approval .f_postot .txt_type{
	width: 94px;
	}
.page_ot_application_inquiry .txt_status,
.page_pending_approval .f_preot .txt_status,
.page_pending_approval .f_postot .txt_status{
	width: 96px;
	}

       /*------- For punch inquiry -------*/

.page_punch_inquiry .txt_jobposition,
.page_punch_inquiry .txt_type,
.page_punch_inquiry .txt_status{
	width: calc(100% / 3 - 10px);
	}

       /*------- For leave balance -------*/

.txt_fullname{
	float:left;
	width:250px;/*172*/
	margin-right:20px;
}

.txt_jobposition{
	float:left;
	width:272px;
	margin-right:10px;/*20*/
}

.txt_a_balance{
	float:left;
    margin-top: -23px;
	margin-right:50px;
	text-align:center;
}



       /*------- For approval -------*/

.txt_label_catalog{
	float:right;
	margin-top:-2px;
	padding:0 4px;
    color: #9a9a9a;
	border: 1px solid #e6e6e6;
	border-radius:6px;
    position: absolute;
    right: 4px;
    top: 6px;
	}

.txt_approvaldate{
	float:left;
	width:192px;/*194*/
	}

.txt_jobposition_approval{
	width:142px;
	}

.page_pending_approval .txt_jobposition{
	width: 100%; /*116px*/
	margin-right:0;
    /*line-height: 11pt;  show all line
    padding-top: 5px;
	white-space: initial;*/
	}

.page_pending_approval .txt_type{
	width: 380px;
	margin-right:0;
	}

.page_pending_approval .f_claim .txt_date{
	width: 160px;
	margin-right:10px;
	}
.page_pending_approval .f_claim .txt_days{
	width: 124px;
	}
.page_pending_approval .f_claim .txt_type{
	width: 214px;
	margin-right:10px;
	}
.page_pending_approval .f_claim .txt_status{
	width: 264px;
	margin-right:0;
	}
.page_pending_approval .f_appraisal .txt_date{
	width: 216px;
	}
.page_pending_approval .f_appraisal .txt_days{
	width: 302px;
	}
.page_pending_approval .f_appraisal .txt_type{
	width: 264px;
	}



       /*------- appointment inq -------*/

.txt_add_time_area{
	float:left;
	width:450px; /*412*/
	}

.txt_content_area_2line .txt_add_time{
    width: auto; /*190px*/
	overflow: initial;
	}
	/*
.txt_add_time + div + div{
	margin-right:20px;
	}*/

.txt_add_time_hypen{
	float: left;
	margin: 0 4px;
    width: 6px;
    height: 12px;
    border-bottom: 1px solid #9a9a9a;
	}

.page_approval_application_inquiry .txt_type{
    width: 212px; /*202*/
	}
	/*
.page_approval_application_inquiry .txt_date{
    width: 308px;
	}*/

.detail_box_type_appointment{
	margin-left: 152px;
    margin-top: -34px;
	width:148px;
	height:42px;
	overflow:hidden;
	}

.approver_staff_remark_2{
	height:56px;
	}



       /*------- For Profile Amend Inquiry -------*/

.page_profile_amend_inquiry .txt_name{
	float:left;
	width:210px;
	margin-right:20px;
	}

.page_profile_amend_inquiry .txt_content_area_2line{
	margin-top:12px;
	}



       /*------- For delegation setting -------*/

.page_delegation_setting .txt_jobposition{
	width: 100%;
    margin-bottom: 6px;
	}
.page_delegation_setting .txt_date{
    margin-bottom: 6px;
	}


/*--------------------- reset filter ---------------------*/

.f_leave, f_pending, f_approved, f_rejected{ /* ===== default filter tab to show "leave" first ===== */
	display:block;
	}

.no_filter .list_1_box{/* ===== default NO filter tab ( = f_all ) ===== */
	display:block;
	}

/*--------------------- list data - control bar ---------------------*/


.list_bar{
    width: 100%;
    height: auto;
	margin: 10px 0;
    /*padding-bottom: 8px;*/
    }

input[type=text]#myInp,
input[type=text].myInp{
	float:left;
	position:absolute;
    width: 36px;
    height: 36px;
	margin-left: 10px;
	border: 1px solid #e6e6e6;
    font-size: 16px;
    background-color: #fff;
    background-image:url(img/input_search.png);
	background-size:18px;
    background-position: 8px 8px;
    background-repeat: no-repeat;
    padding: 10px 0px;
    padding-left: 34px;
    -webkit-transition: width .4s ease-in-out;
    transition: width .4s ease-in-out;
    border-radius: 300px;
	cursor:pointer;

	-webkit-box-sizing: border-box;
 	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

input[type=text]#myInp:focus,
input[type=text]#myInp:hover,
input[type=text]#myInp:active{
	position: absolute;
    width:210px; /*207*/
    background-color: #fff; /* F3F3F3*/
	outline: 0;
	cursor:text;

   border-radius: 300px;
}

.btn_search{
	display:none;
	position: absolute;
    height: 28px;
    width: 28px;
    margin-top: 4px;
    margin-left: 176px;
    /* right: -45px; */
    line-height: 13pt;
    color: #fff;
    background-color: #9a9a9a;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    border: none;


    background-image: url(img/btn_arrow_right_w.png);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

#search_bar_btn{
    display: none;
	position: absolute;
    width:36px;
	height:36px;
   left:276px;
	border-radius: 0px 300px 300px 0px;
	font-size:20pt;
	font-weight:bold;
	line-height:36px;
	text-align: center;
	border:1px solid #e6e6e6;
	cursor:pointer;
	background-color: #fff;
	color:#9a9a9a;
}

#search_bar_btn:hover,
#search_bar_btnactive,
#search_bar_btn:focus{
	background-color:#F3F3F3;
	}

#search_bar_result{
    display: none;
	float:left;
	margin-bottom:10px;
	padding: 10px 10px;
    padding-left:60px;
    width: 100%;
    line-height: 18px;
	font-size:10pt;
	font-weight: normal;
    background-color: #f6f6f6;
}

#search_close{
	position: absolute;
    height: 36px;
    width: 36px;
    margin-top: 2px;
    margin-left:-49px;
    background-color: #fff;
	border: 1px solid #e6e6e6;
    color: #9a9a9a;
    font-size: 19pt;
    line-height: 20px;
    text-align:center;
    border-radius: 100px;
	cursor:pointer;
}

#search_close:hover,
#search_close:focus{
	background-color:#f3f3f3;
	}


/*--------------------- Button control ---------------------*/

.one_btn{
	width: auto;/*fit-content*/
	/*max-width: 242px; 220*/
    margin: auto;
    /*padding: 10px 40px !important;*/
}

.two_btn{
	float:left;
	max-width: 120px;
    margin: 0px 3px;
}

/*--------------------- CSS hide div menu ---------------------*/

.logo_desktop{
	display:none; /* hide by non-desktop */
    position: fixed;
    height: 44px;
    width: 220px;
    z-index: 1000;/*10000*/
    left: 0;
	top: 0;
    background-size: contain;
    background-position: center;
	background-repeat:no-repeat;
	background-image:url(img/logo_acube_vertical.png);
    background-color: rgba(255,255,255,0.6);

	-webkit-box-shadow: -6px 0px 8px #ccc;
	-moz-box-shadow: -6px 0px 8px #ccc;
    box-shadow: -6px 0px 8px #ccc;
	}
.logo_another{
	position: absolute;
    z-index: 1000;
	height: 44px;
	width: 150px;
	margin-left: 68px;
    background-size: contain;
    background-repeat: no-repeat;
	}

.btn_menu_title{
    padding: 16px 4px;
    text-align: center;
    line-height: 22px;
    color: #9a9a9a;
	border-bottom:1px solid #e6e6e6;
    cursor: pointer;
    /* border-radius: 6px; */
    /* border-bottom: 1px solid black; */
    /* -webkit-box-shadow: 0px 0px 8px #E4E4E4; */
    /*-moz-box-shadow: 0px 0px 8px #E4E4E4;*/
    /* box-shadow: 0px 0px 8px #E4E4E4; */
	}

.btn_menu_title:hover,
.btn_menu_title:focus{
	background-color:#f3f3f3; /*E2E2E2*/
	/*-webkit-box-shadow: inset 1px 0px 8px 0px #ccc;
	-moz-box-shadow: inset 1px 0px 8px 0px #ccc;
	box-shadow: inset 1px 0px 8px 0px #ccc;*/
}

.btn_menu_title_selected{
    padding: 16px 4px;
    text-align: center;
    line-height: 22px;
    color: #9a9a9a;
	border-bottom:1px solid #e6e6e6;

	background-color: #ececec;
	cursor:default;
	}





.btn_menu_item{
    /*height: 44px;
    line-height: 44px;*/
    margin: 6px 6px;
	padding: 14px 4px;
    padding-left: 30px;
    border-radius: 6px;
    text-align: left;
    color: #9a9a9a;
	background-position-y: 16px;
	border:1px solid #fff;
    cursor: pointer;
	}

.btn_menu_item:hover,
.btn_menu_item:active,
.btn_menu_item:focus {
	background-color:#f3f3f3;
	border:1px solid #dddddd;
}

.btn_menu_item-selected {
    margin: 6px 6px;
	padding: 14px 4px;
    padding-left: 30px;
    border-radius: 6px;
    text-align: left;
    color: #9a9a9a;
	background-position-y: 16px;
    cursor: pointer;

	background-color: #ececec;
	border:1px solid #dddddd;
	cursor:default;
}

.btn_menu_list{
	display: none;
    position: relative;
    background-color: #f6f6f6;
    width: 100%;
    padding: 2px 0;
    border: 1px solid #dddddd;
	border-radius: 6px;
    top: -5px;
    /* margin-left: 9px; */
    /* margin-bottom: -25px; */
    /* border-radius: 0 0 6px 6px; */
    /* -webkit-box-shadow: 0px 0px 8px #E4E4E4; */
    /* -moz-box-shadow: 0px 0px 8px #E4E4E4; */
    /* box-shadow: 0px 0px 8px #E4E4E4; */
	}

.btn_menu_list:after, .btn_menu_list:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.btn_menu_list:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #f6f6f6;
	border-width: 10px;
	margin-left: -10px;
}
.btn_menu_list:before {
	border-color: rgba(221, 221, 221, 0);
	border-bottom-color: #dddddd;
	border-width: 11px;
	margin-left: -11px;
}

.menu_item{
	width:100%;
	}

.txt_menu_staffid{
	line-height:34px;
	}

/* ------------------ header --------------------- */

.menu_header_L1{
	background:url(img/menu_header_L1.png) no-repeat center;
	height:10px;
	background-size:cover;
	}

.menu_header_L2{
	background-repeat:no-repeat;
	background-position:center;
	background-size:contain;
	height:78px;
	}

.menu_header_L2_mask{
	background:url(img/menu_header_L2.png) no-repeat center;
	height:78px;
	background-size:cover;
	}

.menu_header_L3{
	background:url(img/menu_header_L3.png) no-repeat center;
	/*height:132px;*/
	background-size:220px 100%;
	color:#fff;
	padding-top: 4px;
	padding-bottom: 10px;
	line-height:22px;
	text-align: center;
	}

.btn_select_company{
	border-radius: 6px;
    opacity: 0.8;
    /*font-weight: 600;*/
    padding: 9px 9px;
    background-color: #F6921E;
    color: #fff;
    width: 196px;
    border: 1px solid #ffad38;
    margin: 0 auto;
    line-height: 1.15;
	cursor:pointer;
	}

.btn_select_company:hover,
.btn_select_company:focus{
    background-color: #ffad38;
	}

.icon_num{
	position: absolute;
    height: 18px;
    width: 18px;
    margin-left: 20px;
    color: #fff;
    font-size: 8pt;
    line-height: 14pt;
    border-radius: 100px;
	font-weight:bold;
	}

.filter_icon_num{
	/*float: right;
    margin-top: -6px;
    margin-left: -40px;
    padding: 0px 6px;
    color: #fff;
    font-size: 8pt;
    line-height: 15pt;
    border-radius: 6px;
    font-weight: bold;
    border: 1px solid #fff;
    text-align: center;*/

	position: absolute;
    right: 0;
	margin:0;
	top:0;

	/*float: right;
    margin-left: -42px;*/

	/*margin-top: -36px;
    margin-right: -14px;*/
    color: #fff; /*4d4d4d*/
    border-radius: 6px;

    font-size: 8pt;
    line-height: 12pt;
    font-weight: bold;
    text-align: center;

	padding-left: 10px;
	background:url(img/btn_num_corner.png);
	width:32px;
	height:32px;
	background-size:cover;
	}

.header_bar{
	background-color:rgba(255,255,255,0.8);
	height:44px;
	width:100%;
	min-width: 264px;
	position:fixed;
	top:0; left:0;
	z-index:100;
	-webkit-box-shadow: 0px 0px 8px #ccc;
	-moz-box-shadow: 0px 0px 8px #ccc;
	box-shadow: 0px 0px 8px #ccc;
	padding-right:10px;
	}

.header_bar_icon{
	margin-left:10px;
	margin-right:2px;
	margin-top:4px;
	width:36px;
	height:36px;
	border-radius:100px;
	line-height:34px;
	text-align: center;

	background-size:20px;
	background-repeat:no-repeat;
	background-position:center;

	/*border:1px solid #e6e6e6;*/
	cursor:pointer;
	}

.header_bar_icon:hover,
.header_bar_icon:focus {
	border:1px solid #e6e6e6;
	background-color:#F3F3F3;
	}

.dropdown_selected{
   	background-color: #F3F3F3;
	}

.btn_small_cross_back{
	position: absolute;
    line-height: 20pt;
    margin-left: -10px;
	cursor:pointer;
	}

.btn_switch_w{
	background-image: url(img/btn_switch_w.png);
	}

.btn_small_cross_back img{
	width:10px;
	}

.pp1{
	background-image:url(img/header_logout.png);
	}

#p1, #toolbarSettingBtn{
	background-image:url(img/header_setting.png);
	}

#p2{
	background-image:url(img/header_notice.png);
	}

#p3{
	background-image:url(img/header_outstand.png);
	}

.icon_dashboards_management{
	background-image:url(img/s_dashboards_management.png);
	}

.icon_change_personal_photo{
	background-image:url(img/s_changepersonalphoto.png);
	}

.icon_change_password{
	background-image:url(img/s_changepw.png);
	}

.icon_languages{
	background-image:url(img/s_lang.png);
	}

.icon_system_intro{
	background-image:url(img/s_intro.png);
	}

.icon_change_personal_photo{
	background-image:url(img/s_changepersonalphoto.png);
	}

.icon_current_version{
	background-image:url(img/s_version.png);
	}

.icon_terms_and_conditions{
	background-image:url(img/s_tc.png);
	}

.icon_about_flexSystem{
	background-image:url(img/s_aboutus.png);
	}

.icon_zoom{
	background-image:url(img/icon_zoom.png);
	}

.icon_cross{
	background-image:url(img/btn_cross_grey.png);
	}

/* ----------------------- pop functions ---------------------- */

.popup_refresh_position{

	    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-top: 1px solid #e6e6e6;
    color: #9a9a9a;
    line-height: 40px;
    margin-top: 10px;
    text-align: center;
    border-radius: 0 0 6px 6px;

	/*
    position: absolute;
	width:270px;
	line-height:40px;
    bottom: 0;
    background: red;
    width: 100%;
    padding-left: 12px;*/
	}

.popup_refresh_position .title_bar_icon{
	line-height: 36px;
    margin-top: 2px;
	margin-right: 10px;
	}

.alert_box{
	display:none;
	position: absolute;
    right: 4px;
    top: 42px;
	width:248px;
    z-index:2;
	}

.arrow_position_1{
	background-position: 168px 0;
	}

.arrow_position_2{
	background-position: 184px 0;
	}

.arrow_position_3{
	background-position: 136px 0;
	}

.arrow_position_4{
	background-position: 57px 0;
	}

.arrow_position_5{
	background-position: 8px 0;
	}

.alert_box_2{
	display:none;
	position: absolute;
    right: 4px;
    top: 42px;
	width: 312px;
	z-index:2;
	}

.alert_box_2 .alert_box_content_2_item_m{
	max-height: 340px;
	overflow: auto;
	padding: 8px;
	padding-bottom:40px;
	}

.alert_box_2 .box_1_staff{
	margin-top:0;
	margin-left: 0;
	margin-right: 0;
	}

.alert_box_2 .box_1_staff_name{
	margin-top: 30px;
	width: 180px;
	}

.alert_box_list{
	display:none;
	position: absolute;
   	margin-top:42px;
	margin-left:3px;
   	z-index:2;
	}

.alert_box_arrow{
	position: relative;
	background-image:url(img/list_arrow.png);
	background-repeat:no-repeat;
	background-size:contain;
	height:8px;
}

.alert_box_content{
	background-color:#fff;
	border-radius: 6px;
    margin-top: 10px;
    text-align:left;
	-webkit-box-shadow: 0px 0px 20px #ccc;
	-moz-box-shadow: 0px 0px 20px #ccc;
	box-shadow: 0px 0px 20px #ccc;
}

.alert_box_content_2{
	background-color:#fff;
	border-radius: 6px;
    margin-top: 10px;
	/*margin-left: -92px;*/
	-webkit-box-shadow: 0px 0px 20px #ccc;
	-moz-box-shadow: 0px 0px 20px #ccc;
	box-shadow: 0px 0px 20px #ccc;
}

.alert_box_content_title{
	height: 28px;
	line-height: 28px;
	background-color:#9a9a9a;
	text-align:left;
	color:#fff;
	padding:0 10px;
   	margin-top: -10px;
   	border-radius: 6px 6px 0 0;
}

.alert_box_content_item{
	height: 44px;
    line-height: 42px;
	padding:0 10px;
	cursor:pointer;
}

.alert_box_content_item_line{
	border-top:1px solid #e6e6e6;
}

.alert_box_content_item img{
	margin-bottom: 2px;
    margin-right: 10px;
}

.alert_box_content_item:hover,
.alert_box_content_item:focus{
    background-color: #F3F3F3;
}

#toolbarSettingPopup .alert_box_content_item,
#p1_content .alert_box_content_item{
	background-repeat:no-repeat;
	background-size:16px;
	background-position: 10px center;
	padding-left:32px;
}

/* ----------------------- pop-up (black) ---------------------- */

.mat-dialog-container {
	/* display: none !important; */
	padding: 0px !important;
	transition: 1s ease;
}

.dialog-responsive {
	width: 0;
}

@media only screen and (max-width: 767px) {
    .dialog-responsive {
		width: 100%;
    }
}

.pop_functions_div{
	 position:fixed;
	 top:0px;
	 right:0px;
	 width:100%;
	 height:100%;
	 background-color:rgba(0,0,0,0.5);
	 z-index:10000;
	 display:none;

	 /*filter: alpha(opacity=40);   For IE8 and earlier */
}

.pop_functions_close{

	position: fixed;
    _position:absolute;
    _z-index:100;/*
	_bottom:-20px;
    top:0;
    right:0;*/

    _position:absolute;
	z-index: 100;
	left: calc(100%/2 + 606px/2 - 58px);
    right: 8px;
    width: 30px;
    height: 30px;
	margin-top: 4px;

    border: 1px solid #fff;
    color: #fff;
    border-radius: 50px;
    font-size: 16pt;
    line-height: 18pt;
    text-align: center;

    -webkit-box-shadow: 0px 0px 10px #ccc;
    -moz-box-shadow: 0px 0px 10px #ccc;
    box-shadow: 0px 0px 10px #ccc;
	opacity:0.8;
	}

.pop_functions_close:hover, .pop_functions_close:focus{
    background-color: #FFAD38;
	cursor:pointer;
}

.pop_functions_approval{
	bottom: calc(100%/2 - 613px/2 - 76px); /* - 60px */
    width: 606px;
    /*height: 43px;*/

	position: fixed;/*
    _position: absolute;
    _z-index: 10;
    _position: absolute;*/

    z-index: 10;
    left: calc(100%/2 - 606px/2);
    right: 8px;
    margin-top: 4px;
    text-align: center;
	}

.pop_functions_box_size_tc .pop_functions_close{
    /*float: right;
	position: static;
    right: auto;*/

	position:absolute;
	right:10px;

    z-index: auto;
    left: auto;
	margin-top:auto;
}

.pop_functions_box_autoheight_hv_limit .pop_functions_close{
    left: calc(100%/2 + 606px/2 - 45px);
	margin-top:0;
}

.pop_functions_profile_amend_inquiry .pop_functions_close{
	position:absolute;
	right:10px;

    float: right;
    z-index: initial;
    left: initial;
	margin-top:initial;
}

.pop_functions_profile_amend_inquiry .profile_amend_inquiry_box_content{
	text-align:left;
	}

.pop_functions_profile_amend_inquiry .pop_functions_box_content_tc{
	padding: 4px;
    padding-left: 38px;
	height: calc(100% - 48px);
	}

.leave_submitted_content .pop_functions_box_content_tc{
	height: calc(100% - 48px - 148px);
	}

.pop_functions_box_size_company .pop_functions_close{
    /*float: right;
	position: relative;
    left: initial;*/
	position:absolute;
	left: calc(100% / 2 + 104px);
    z-index: 1;
    right: initial;
	margin-top:initial;
}

.list_next_approvers .pop_functions_close{
	position:absolute;
	right:10px;
	}

.list_next_approvers .pop_functions_box_content_tc{
	height: calc(100% - 48px);
    margin-top: 10px;
	}

.list_next_approvers .box_1_staff{
    height: initial;
	}

.list_next_approvers .box_1_staff_name{
	width: calc(100% - 92px);
	}

.pop_functions_box{
    top:50%;
	left:50%;
	padding: 10px 10px;
	background-color:#fff;
	position: absolute;

    border-radius:6px;
    text-align: center;

	-webkit-box-shadow: 0px 0px 20px #666666;
	-moz-box-shadow: 0px 0px 20px #666666;
	box-shadow: 0px 0px 20px #666666;

	/* scrolling touch
	-webkit-overflow-scrolling: touch;
	-webkit-transform: translate3d(0,0,0);*/
	}

.pop_functions_box_size_image_preview{
	z-index: 999;
	width: 1096px;
	height: 663px;
	margin-top: -15%;
	margin-left: -30%;
}

.pop_functions_box_size_1{
	width:300px;
	z-index: 999;
	/*height:216px;*/
	margin-top: calc( -216px / 2 );
  	margin-left: -150px;
}

.pop_functions_box_size_msgauto{
	width:300px;
	z-index: 999;
	/*height:152px;*/
	margin-top: calc( -152px / 2 );
  	margin-left: -150px;
	}

.pop_functions_box_size_company{
	height:348px;/*272*/
	overflow:auto;
	z-index: 999;
	width:300px;
	min-height: 198px;
	margin-top: calc( -348px / 2 );
  	margin-left: -150px;
	/*padding-bottom:20px;*/
	}

.pop_functions_box_size_company .box_1_staff,
.alert_box_content_2_item_m .box_1_staff{
	height:auto;
	}
.pop_functions_box_size_company .box_1_staff_name,
.alert_box_content_2_item_m .box_1_staff_name{
	/*margin-top: 30px;*/
	margin: 0;
    padding: 4px 0;
    width: 156px;
    width: 100%;
    text-align: center;
	}

.pop_functions_box_size_tc{
	z-index: 999;
	width: 600px;
    height: 400px;
    margin-top: calc( -400px / 2 );
    margin-left: calc( -600px / 2 );
	}

.pop_functions_box_size_jobposition{
	z-index: 999;
	text-align: center;
	/*padding-bottom: 44px;*/
	overflow-y: auto;
	height: auto;

	width: 300px; /* ==== block the width of "pop_functions_box_size_tc" ==== */
    margin-left: calc( -300px / 2 );

	overflow:hidden;
	}

.pop_functions_box_content_tc{
	height: calc(100% - 80px); /*88    height: 302px;*/
	margin-top: 10px;

	overflow-y:auto;
	background-color:#f3f3f3;
	padding:10px;
	border-radius: 6px;
	border:2px solid #f3f3f3;
	text-align: left;
	}

.pop_functions_box_content_tc p{
	overflow: initial;
    text-overflow: initial;
    white-space: initial;
	}

.pop_functions_one_btn_center{
	margin:12px auto;
	width:100%;
	max-width:240px;
	}

.pop_functions_two_btn_center{
	margin:12px auto; /* 12px 18px */
	width:260px;
	}
.pop_functions_two_btn_right{
	float: right;
    width: 252px;
	}

.two_btn_fixed_bottom{
	width: 288px;
    padding: 10px 0;
    background-color: #fff;
    border-top: 1px solid #e6e6e6;
	}

.pop_functions_box_size_2column{
    width: 606px;    /*400*/
    height: 614px; /*600 692*/
    margin-left: calc( -606px / 2 );
    margin-top: calc( -613px / 2 );
    overflow-y: auto;
    overflow-x: hidden;
	text-align:left;
	z-index:0; /* for ie 11 *** */
	}

.pop_functions_approval{
	background-color:#fff;
	border-radius:6px;
	margin-bottom:4px;
	}

@media only screen and (max-height: 416px){
	.pop_functions_box_size_company{
		height:272px;
		margin-top: calc( -272px / 2 );
	}
}

.pop_msg{
	z-index: 100001;
	}

.txt_popup_staffname{
	font-size: 14pt;
	}

.txt_popup_position{
	opacity: 0.8;
    /*font-weight: 600;*/
	}

.txt_popup_staffid{
	font-size: 9pt;
    opacity: 0.8;
    /*font-weight: 600;*/
    line-height: 30px;
	}

.txt_popup_refid{
	position: absolute;
    right: 10px;
    top: 46px;
    font-size: 9pt;
    opacity: 0.8;
    line-height: 30px;
    color: #fff;
	}

.txt_copyright{
	position: absolute;
    bottom: 18px;
	}

/*--------------------- mask_balance_inquiry ---------------------*/

.mask_balance_inquiry{
	margin-top: calc( -467px / 2 );
	height:auto;
	}

.mask_balance_inquiry .detail_box_bottom_area{
	min-height: 330px;
	}

    /* ======= no datepicker ======= */

.mask_balance_inquiry .detail_box_other > div:nth-child(2){
	margin-top: 0px;
	}

.mask_balance_inquiry .detail_box_other > div:nth-child(3){
	margin-top:20px;
	}

.mask_balance_inquiry .detail_box_other > div:nth-of-type(3){
	margin-top:22px;
	}

    /* ======= have datepicker ======= */

#pop_LeaveBalance .detail_box_other > div:nth-of-type(2){
	font-size: initial;
    padding: 3px 0;
    float: none;
	}

#pop_LeaveBalance .detail_box_other > .input_new_calendar{ /* simon calendar */
	margin-top: 4px;
	}

.mask_balance_inquiry .detail_box_other > .input_field_auto{
	margin-top: 2px;
	}

#pop_LeaveBalance .detail_box_other > div:nth-of-type(3){
	margin-top:20px;
	}

/* ------------ pop-up : pop_functions_box_size_1column adjust ------------ */
/*
.pop_functions_box_size_1column{
    width: 340px;
    height: 613px;
    margin-left: calc( -340px / 2 );
    margin-top: calc( -613px / 2 );
    overflow-y: auto;
    overflow-x: hidden;
	}

.pop_functions_box_size_1column .pop_functions_close{
	left: calc(100%/2 + 340px/2 - 46px);
	}

.pop_functions_box_size_1column .detail_box_bottom_area{
	width: 320px;
	}

.pop_functions_box_size_1column .detail_box_name{
	}

.pop_functions_box_size_1column .detail_box_type{
	height:24px;
	overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}*/

	.pop_functions_box_size_1column{
		width: 312px;
		max-height: calc( 100% - 8px);
		height: 614px; /*570*/
		margin-left: calc( -312px / 2 );
		/* margin-top: calc( -570px / 2 ); */
		overflow-y: auto;
		overflow-x: hidden;
		/* border-radius: 6px 6px 0 0; */
	}

	.pop_functions_box_size_1column .pop_functions_close{
    	left: calc(100%/2 + 312px/2 - 50px);
	}

	.pop_functions_box_size_1column .detail_box_bottom_area{
		min-height:auto;
		margin-left: -10px;
    	width: 312px;
		margin-bottom: -10px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.pop_functions_box_size_1column .detail_box_top{
		height: initial;
		padding-left: 0;
		padding-top: 0;
	}

	.pop_functions_box_size_1column .detail_box_name{
		position: static;
		width: 100%;
		text-align: center;
		padding-top: 96px;
		white-space: inherit;
	}

	.pop_functions_box_size_1column .detail_box_top_photo_mask {
		left: calc(50% - 78px / 2);
		margin-top: 16px;
		background: url(img/leave_detail_box_top_photo_mask_m.png) no-repeat left;
		background-size: contain;
	}

	.pop_functions_box_size_1column .detail_box_top_photo{
		top: 0;
		height: 78px;
		width: 78px;
		left: calc(50% - 78px / 2);
		margin-top: 16px;
		margin-left: 0;
	}

	.pop_functions_box_size_1column .list_1_box{
		margin: 0;
		padding: 4px;
		width:100%;
		border:none;
		border-bottom: 1px solid #e6e6e6;
		}

    /* ------- pop-up : pop_functions -------- */
.pop_functions_box_size_1column + .pop_functions_approval{
	width: 312px;
	margin-left: calc( 312px / 2 - 10px);
	/*bottom: calc(100%/2 - 570px/2 - 50px);*/
	}
.pop_functions_box_size_1column .detail_box_claim_record_area,
.pop_functions_box_size_1column .detail_box_approver_remarks_area{
	height:100%;
	overflow-y: hidden;
	min-height: 100%;
	}

    /* ------- switch on-off reset (to Simon) --------
.pp_qualification_content .am-date-picker__input {
	margin: 0;
	padding: 0;
}*/


/* ----------------------- loading ---------------------- */

.loadingDiv{
	 position:fixed;
	 top:0px;
	 right:0px;
	 width:100%;
	 height:100%;
	 background-color:rgba(102,102,102,0.4);
	 z-index:10000;
	 filter: alpha(opacity=40);  /* For IE8 and earlier */

	 display:none;
 }

.loading_box{
	width:100px;
	height:100px;
	background-color:#fff;
	position: absolute;

    top:50%;
	left:50%;
	margin-top: -50px;
  	margin-left: -50px;

    color: #B2B2B2;
    border-radius:6px;
    text-align: center;
    line-height: 142px;
    background-image:url(img/loader.gif);
    background-repeat:no-repeat;
	background-position: 50% 35%;

	-webkit-box-shadow: 0px 0px 20px #666666;
			-moz-box-shadow: 0px 0px 20px #666666;
			box-shadow: 0px 0px 20px #666666;
	}


/*--------------------- login page ---------------------*/

.pop_index_msg{
	position: fixed;
	text-align:center;
	overflow: auto;
	padding: 4px;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #f3f3f3;
    border-top: 1px solid #ccc;
    z-index: 4;
	}
/*.pop_index_msg > .pop_functions_close{
    position: absolute;
	left: calc(100% - 46px);
	margin:0;
	}*/
.pop_index_msg > .col_3{
	float:left;
    width:calc(100% / 3);
	}

.btn_txt_icon_right{
	width: 88%;
	padding-left: 10px;
	text-align: left;
	}

.input_txt_icon_right{
	padding-right: 36px;
	}

.input_login_position{
	width:250px;
	margin:0 auto;
	}

.login_txt_area{
	margin:0 auto;
	width:250px;
	height: 200px;
    overflow: auto;
}

.btn_signin_position{
	width:158px;
	margin:10px auto;
	}

.icon_arrow_r{
	background-image:url(img/right_arrow_w.png);
	background-repeat:no-repeat;
	background-size:18px;
	background-position:calc(100% - 10px) center;
	}

.btn_fp{
	width:50%;
	line-height:45px;
	float:left;
	border-top:1px solid #e6e6e6;
	border-right:1px solid #e6e6e6;
	transition: all .6s ease 0s;
	}

.btn_fp:hover,
.btn_fp:focus{
	background-color:#f3f3f3;
	cursor:pointer;
	}

.btn_tc{
	width:50%;
	line-height:45px;
	float:left;
	border-top:1px solid #e6e6e6;
	cursor:pointer;
	}

.btn_tc:hover,
.btn_tc:focus{
	background-color:#f3f3f3;
	}

.alert_box_statement_btn{
	width:100%;
	height:30px;
	cursor:pointer;
	}

.alert_box_statement{
	display:none;
	width:100%;
	height:200px;
	}

.login_box_abs{
    position: absolute;
    margin:0;
    width:400px;
    top: calc(50% - 542px / 2);
    left: calc(50% - 400px / 2);

    /*margin-top: calc(-533px / 2);
    margin-left: calc(-400px / 2);*/
    border-radius: 6px;
    text-align: center;
    background-color: #fff;
	border-radius:6px;
    -webkit-box-shadow: 0px 0px 100px #ccc;
    -moz-box-shadow: 0px 0px 100px #ccc;
    box-shadow: 0px 0px 100px #ccc;
}
.login_disclaimer{
	position: absolute;
    width: 100%;
    left: 0;
    top: calc(50% + 670px / 2);
    margin: 0;
	padding: 10px;
}
.login_disclaimer > div{
	margin-top:10px;
}
.login_disclaimer b{
	color:#4d4d4d;
}

.cpw_box{
	/*height:400px;*/
	top: calc(50% - 400px / 2);
	}

.login_box_size{
	width: 300px;
	margin: 0 auto;
	}

.login_box{
	margin: 0 auto;
	margin-top:100px;
    /* height: auto; */
    padding: 10px 10px;
    background-color: #fff;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* margin-top: -150px; */
    /* margin-left: -150px; */
    margin-bottom: 30px;
    border-radius: 6px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 8px #ccc;
    -moz-box-shadow: 0px 0px 8px #ccc;
    box-shadow: 0px 0px 8px #ccc;

	/*
	width:300px;
	height:auto;
	padding: 10px 10px;
	background-color:#fff;
	position: absolute;

    top:50%;
	left:50%;
	margin-top: -150px;
  	margin-left: -150px;

	margin-bottom: 30px;

    border-radius:6px;
    text-align: center;

	-webkit-box-shadow: 0px 0px 8px #ccc;
	-moz-box-shadow: 0px 0px 8px #ccc;
	box-shadow: 0px 0px 8px #ccc;*/
	}

.login_bg{
	height:100px;
	background-image:url(img/login_login_bg.png);
	background-repeat:no-repeat;
	background-size:cover;
	margin-bottom:20px;
	text-align:center;

	padding-top: 30px;
	}

.login_bg img{
	max-width:90%;
	}

.box_grid_two{
	/*float:left;
	margin-left: 20px;
	padding:4px 4px;*/
	text-align:center;
	width: 50%;
    float: left;
	}

.jobposition_content{
	height: calc(100% - 42px);
	max-height:280px;
    overflow-x: auto;
    padding: 10px;
    border-radius: 6px;
}

.jobposition_content button{
    margin-bottom: 10px;
}

.languagesposition_content{
	/*height: calc(100% - 42px);*/
	max-height:180px;
    overflow-x: auto;
    padding: 10px;
    border-radius: 6px;
}

.languagesposition_content button{
    margin-bottom: 8px;
}

#hideMe{
	visibility:hidden;

	-moz-animation: cssAnimation 0s ease-in 3.2s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 3.2s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 3.2s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 3.2s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;



    position: fixed;
    top: 0px;
    right: 0px;
    margin-left: -210px;
    margin-top: -468px;
    left: 50%;
    top: 50%;
    width: 400px;
    height: 800px;
    background-color: #fff;
    background-image: url(img/ani_v1.gif);
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000;
}


@keyframes cssAnimation {
	to {
        width:0;
        height:0;
        visibility:hidden;
    }
}

@-webkit-keyframes cssAnimation {
to {
        width:0;
        height:0;
        visibility:hidden;
    }

}

/*--------------------- about flexsystem ---------------------*/
.login_lang{
    width:100%;
    position:absolute;
    padding:10px;
	}
.login_lang > .btn{
	border: 1px solid #e6e6e6;
    background-color: #fff;
    background-size: 18px;
    background-position: 10px;
    border-radius: 50px;
    color: #9a9a9a;
    padding-left: 35px;
	width: 112px;
    /*max-width: fit-content;
    min-width: 112px !important;*/
    -webkit-box-shadow: 0px 0px 8px #e6e6e6;
    -moz-box-shadow: 0px 0px 8px #e6e6e6;
    box-shadow: 0px 0px 8px #e6e6e6;
	}
.login_lang > .btn:hover,
.login_lang > .btn:focus{
    color: #9a9a9a;
    background-color: #f3f3f3;
	border: 1px solid #fff;
	}
/*--------------------- about flexsystem ---------------------*/

.flexsystem_banner_a{
	height:200px;
	width:100%;
	background-image:url(img/banner_a.png);
	background-repeat:no-repeat;
	background-size:100%;
	}

.flexsystem_banner_b{
	height:200px;
	width:100%;
	background-image:url(img/banner_b.png);
	background-repeat:no-repeat;
	background-size:100%;
	}

@media only screen and (max-width: 600px){
.flexsystem_banner_a, .flexsystem_banner_b{
	height:89px;
	}
}

/*--------------------- powered_by ---------------------*/
.powered_by{
	position: fixed;
	z-index:3;
    clear: both;
    color: #9a9a9a;
    font-size: 9pt;
    padding: 4px;
	width:146px;/*170*/
    /*margin-left: 220px;*/

    border-radius: 6px 0 0 0;
    bottom: 0;
    right: 0;
    background-color: rgba(242,242,242,0.8);
	border: 1px solid #e6e6e6;

	background-image:url(img/logo_fsl_powered.png);
	background-repeat:no-repeat;
	background-size: auto 18px;
    background-position: 74px 2px;/*74px center*/
	}

@media only screen and (max-width : 767px) {
	/*.powered_by{

		margin-left: 0px;
		position:static;
		background-color:transparent;
		border:none;
		margin: 0 auto;
		margin-top:-38px;
	}*/
}

.powered_by img{
	vertical-align: middle;
	height: 18px;
	}

	/*------------ powered_by_nologin ------------*/
.powered_by_nologin{
	position: fixed;
	z-index:3;
    clear: both;
    color: #9a9a9a;
    font-size: 9pt;
    padding: 4px;
	width:145px;

    border-radius: 6px 0 0 0;
    bottom: 0;
    right: 0;
    background-color: rgba(242,242,242,1);
	border: 1px solid #e6e6e6;

	background-image:url(img/logo_fsl_powered.png);
	background-repeat:no-repeat;
	background-size: auto 18px;
    background-position: 74px center;
	}

.powered_by_nologin img{
	vertical-align: middle;
	height: 18px;
	}

@media only screen and (max-height : 618px){
	.login_box_abs,
	.cpw_box + .powered_by_nologin{
	    /*top: 10px;*/
	}

	.powered_by_nologin{
		/*position: absolute;
		bottom: 0;
		left: calc(100% / 2 - 170px / 2);
		background-color: transparent;
		border: none;*/

		/*position:static;
		margin: 0 auto;
		margin-top: 569px;
		background-color:transparent;
		border:none; --------- temp to delete for server's problem */
		}
}

		/* ========= powered_by_nologin_claim =========== */

.powered_by_nologin_claim{
	position: fixed;
    clear: both;
    color: #9a9a9a;
    font-size: 9pt;
    padding: 2px;
    text-align: center;
    width: 100%;
    bottom: 0;
    background-color: rgba(242,242,242,1);
	border: 1px solid #e6e6e6;
	}

.powered_by_nologin_claim img{
	vertical-align: middle;
	height: 18px;
	}

@media only screen and (max-height : 526px) {
.powered_by_nologin_claim{
	position:static;
	margin-top: 512px;
	}
}

/*--------------------- dropdown-menu : reset company select ---------------------
.dropdown-menu{
	width:100%;
	}
.dropdown-menu li a{
	padding:12px;
	white-space:normal;
	}

.dropdown-toggle .caret	{
	border-top: 8px solid;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
	}

.btn-group>.btn:first-child{
	padding-right:30px;
	}

.dropdown-menu>li>a{
	color:#9a9a9a;
	}*/

/*--------------------- common ---------------------*/

#layout{ /*reset bg*/
	background-image: url(img/bg.png);
    background-repeat: repeat;
	}

.header{
	border: none;
	}

.btn{
	padding:10px 10px; /* 10px 14px */
	font-size: 15px;
	line-height: 1.6;
	/*overflow: hidden;
    text-overflow: ellipsis;*/
    white-space: nowrap;
	transition:all .6s ease 0s;
	}

.btn:hover, .btn:focus, .btn:active, .btn:visited{
	color:#fff;
	}

.btn_org{
	width: 44px;
    height: 44px;
    text-align: center;
    padding: 12px;
	}
.btn_org:hover,
.btn_org:focus{
	background-color:#f3f3f3;
	border-radius:6px;
	transition:all .6s ease 0s;
	cursor: pointer;
	}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn,
#datepicker-1[disabled], #datepicker-2[disabled], input[disabled]{
	cursor: not-allowed;
    background-color: #eee;
    opacity: 1;
    color: #9a9a9a;
    background-image: none;
    border: 1px solid #E1E1E1;
	}

.icon_msg_alert{
	background-image: url(img/msg_alert.png);
	width:48px;
	height:48px;
    margin: 0 auto;
	background-repeat: no-repeat;
    background-size: 48px;
    background-position: center;
	}

.icon_btn_tick_w{
	background-image: url(img/btn_tick_w.png);
	background-repeat: no-repeat;
    background-size: 18px;
    background-position: 32px center;
	padding-left: 26px;
	}

.icon_btn_cross_w{
	background-image: url(img/btn_cross_w.png);
	background-repeat: no-repeat;
    background-size: 18px;
    background-position: 32px center;
	padding-left: 26px;
	}

div,button,a,ul,li,input,textarea{
	outline:0;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
	}

input[type="radio"], input[type="checkbox"]{
	display:none;
	}

input{
	line-height:1.15;
	}

.btn-group {
    margin-bottom: 12px; /* company dropdown space of menu */
	}

.block_ie8_position{
	display:block;
	background-color:#fff;
	padding: 30px;
	}

.content{
	/*background-image: url(img/bg.png);
    background-repeat: repeat;  */
    margin: 0;
    padding-top: 24px;
	height:100%;
	margin-bottom:164px;

	/* scrolling touch
	-webkit-overflow-scrolling: touch;
	-webkit-transform: translate3d(0,0,0);*/
	}

.reset_width{
	width:inherit;
	}

.reset_height{
	height:inherit;
	}

.visibility_hidden{
	visibility:hidden;
	}

.company_logo_center{
	margin-left: calc(100% / 2 - 70px);
	}

textarea{
	resize:none;
	}

.ml0{
	margin-left:0;
	}

.ml{
	margin-left:10px;
	}

.mr{
	margin-right:10px;
	}

.m0{
	margin:0;
	}

.pl10{
	padding-left:10px;
	}

.w100{
	width:100% !important;
	}

.txtcenter{
	text-align:center;
	}

.txtleft{
	text-align:left;
	}

#menu {
    margin-top: 0px; /* menu : nono-desktop */
	}

hr{
    border-top: 1px solid #e6e6e6;
	/*margin-left:-36px;
    border-top: 1px solid #e6e6e6;*/
	}

.hr_profile{
	margin: 14px 0;
    margin-left: -36px;
    margin-right: -10px;
    border-top: 1px solid #e6e6e6;
	}

.hr_detail_box_1{
	margin: 14px 0;
    border-top: 1px solid #e6e6e6;
	}

img{
	vertical-align:text-bottom;
	}

p{
	line-height:15pt;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

a{
	text-decoration:none;
	color:inherit;
	}

a:hover, a:focus, a:active, a:visited{
  	text-decoration:none;
	color:inherit;
	}

.float_none{
	float:none;
	}

.margin_center{
	margin: 0 auto;
	}

.cur_pointer{
	cursor:pointer;
	}

ul img{
	margin-right:10px;
	}

.txt_box_1{
    /*margin-top: 4px;*/
	height: 70px;
    padding: 2px 8px;
    overflow-y: auto;
    border: 1px solid #E1E1E1;
	background-color:#F1F1F1;
    border-radius: 6px;

	word-wrap: break-word;
	}

.bg_none{
	background-color:#fff;
	}

.bg_transparent{
	background-color: transparent;
	}

.department_area txt_box_1 span{
	display:inline;
	}

.txt_box_label_name{
    margin-top: 4px;
    /*width: 100%;
	max-width:98px;*/
    max-height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

.txt_normal_grey_touch{
	margin: 10px 0;
	cursor:pointer;
	width: calc(100% - 20px);
	}

.txt_normal_grey_touch:hover,
.txt_normal_grey_touch:focus{
   color:#f6921e;
	}

.txt_small_grey{
	color:#9a9a9a;
	font-size:9pt;
	}

.txt_bold{
	font-size: 11pt;
	font-weight: bold;
	font-family: sans-serif;
	}

.txt_10pt{
	font-size:10pt;
	}

.txt_opacity_1{
	opacity:0.8;
	/*font-weight:600;*/
	}

.txt_orange_1{
    color:#f6921e;
	font-size:18pt;
	}

.txt_orange_2{
    color:#f6921e;
	font-size:11pt;
	}

.txt_blue_1{
    color:#31A2F3;
	}

.txt_grey_1{
    color:#4d4d4d;
	}
.txt_grey_2{
    color:#9a9a9a;
	}
.txt_grey_3{
    color:#D3D3D3;
	}

.txt_size_0{ /* title fonts */
    font-family: sans-serif;
    font-weight: lighter;
	font-size: 16pt;
	}

.txt_size_1{
	font-size:14pt;
	}

.txt_size_dashboards_management{
	font-size:13pt;
	}

.txt_size_2{
	font-size:11pt;
	word-wrap: break-word;
	}

.txt_size_3{
	font-size:9pt;
	}

.detail_box_other{ /*pop detail font FOCUS reset*/
	font-size:11pt;
	word-wrap: break-word;

	overflow: auto;
    height: 258px;
	}

.detail_box_other .am-date-picker__input{ /*pop detail font FOCUS reset*/
	font-size:11pt;
	}

.bg_grey_1{
    background-color:#4d4d4d;
	}

.bg_grey_2{
    background-color:#9a9a9a;
	}

.bg_grey_2b{
    background-color:#b4b4b4;
	}

.bg_grey_2c{
    background-color:#E7E7E7;
	}

.bg_grey_3{
    background-color:#ececec;
	}

.bg_grey_4{
    background-color:#f3f3f3;
	}

.txt_label{
	/*color:#fff;*/
	padding:0px 4px; /*1px 2px*/
	border-radius:4px;
	white-space: nowrap;
	}

.bc_grey{
	border:1px solid #4d4d4d;
	color:#4d4d4d;
	}

.bc_grey_2{
	border:1px solid #CDCECF;
	color:#9a9a9a;
	background-color:#fff;
	}

.bc_red{
	border:1px solid #d6545b;
	color:#d6545b;
	}

.bc_blue{
	border:1px solid #3597d4;  /* #65addb */
	color:#3597d4;
	}

.bc_maincolor{
	border:1px solid #F6921E;  /* #65addb */
	color:#F6921E;
	}

.bc_green{
	border:1px solid #37c77f;
	color:#37c77f;
	}

.bc_brown{
	border:1px solid #c88c4e;/* #d1a275 */
	color:#c88c4e;
	}

.bc_purple{
	border:1px solid #9b59b6;  /* #b88dcb */
	color:#9b59b6;
	}

.color_label_pending{
	background-color:#ECD41F;
	border:2px solid #FFFF5F;
	color:#fff;
	}

.color_label_cancel_pending{
	background-color: #fff;
    border: 1px solid #F6921E;
    color: #F6921E;
	}

.color_label_approved{
	background-color:#2ECC71;
	border:2px solid #61FFA4;
	color:#fff;
	}

.color_label_rejected{
	background-color:#9A9A9A;
	color:#fff;
	}

.txt_label_profile_action{
	position: absolute;
    right: 4px;
	padding:0 2px;
    /*top: 13px;*/

	border-radius: 4px;
    white-space: nowrap;
	}

.txt_label_profile_action_front{
	position: static;
	}

.color_label_keep{
	color:#9a9a9a;
	border:1px solid #9a9a9a;
	}

.color_label_new{
	color:#3597d4;
	border:1px solid #3597d4;
	}

.color_label_new_edit{
	color:#3597d4;
	border:1px solid #3597d4;
	right:40px;
	}

.color_label_update{
	color:#9b59b6;
	border:1px solid #9b59b6;
	}

.color_label_remove{
	color:#d6545b;
	border:1px solid #d6545b;
	}

.content_box{
	position:relative;
	padding: 10px;
	margin: 20px 0;
	max-width:884px;
	min-width:320px;
	background-color:#fff;
	border-radius:6px;
    overflow: hidden;

   -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

	/*float: left;
    margin-right: 16px;*/
	}

.content_box_no_overflow{
	position:relative;
	padding: 10px;
	margin: 20px 0;
	max-width:884px;
	min-width:320px;
	background-color:#fff;
	border-radius:6px;

	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

	/*float: left;
	margin-right: 16px;*/
	}

.content_box_approval_log{ /* = bigger content_box */
	padding: 10px;
	margin: 20px 0;
	max-width:1076px; /* 940 */
	background-color:#fff;
	border-radius:6px;

   -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	}

.list_result_area{
	position:relative;
	left: -400px;
	opacity:0;
	display:none;
	min-width:320px;

	/*float:left;*/
	}

.title_reset{
   margin-left:20px;
   text-align:left;
}

.list_bar_mt{
   position: absolute;
   margin-top:-50px;
}

.back_top{
   display:block;
   position: fixed;
   z-index:100;
   bottom:32px;/*148*/
   right:-65px;/*0*/
   width:40px;
   height:40px;
   line-height:38px;
   text-align: center;
   background-color:rgba(255,255,255,0.7);
   border-radius: 100px;
	border:1px solid #CDCDCD;
   -webkit-box-shadow: 0px 0px 8px #ccc;
	-moz-box-shadow: 0px 0px 8px #ccc;
	box-shadow: 0px 0px 8px #ccc;
	cursor:pointer;
}

.back_top:hover,
.back_top:focus{
   background-color:#F3F3F3;
	}

.list_icon_border{
	border: 1px solid #e6e6e6;
	}

.mt0{
	margin-top:0;
	}

.mt16{
	margin-top:16px;
	}

.fl{
	float:left;
	}

.fr{
	float:right;
	}

.tl{
	text-align:left;
	}

.tr{
	text-align: right;
	}

.input_icon_1{/*
	position: relative;
    top: -41px;
    right: -96px;*/
	position: absolute;
    width: 18px;
    height: 18px;
    margin-top: -32px;
    margin-left: 219px;
}

.txt_small_link{
	font-size:10pt;
	color:#8E9093;
	cursor:pointer;
	}

.txt_small_link:hover, .txt_small_link:focus{
	color:#5B5D60;
	}

.txt_big{
	font-size:18pt;
	color:#9a9a9a;

	}

.logo_acube_top_right{
	position: fixed;
    right: 100px;
    height: 44px;
    width: 125px;
    background-size: 224px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(img/logo_acube_vertical.png);
}

h1{
	font-size:28pt;
}

h2{
	font-size:18pt;
    margin: 0;
    padding: 0;
    line-height: 26pt;
}

.t_orange{
	color:#f6921e;
}

.t_grey{
	color:#9a9a9a;
}

.t_black{
	color:#4d4d4d;
}

.btn_opacity:hover,
.btn_opacity:focus{
	opacity: 0.6;
	cursor:pointer;
	}

.btn img{
	margin-right: 6px;
	margin-bottom: 2px;
	}

.btn_red{
	background-color:#D4030B;
	color:#fff;
	}

.btn_red:hover,
.btn_red:focus{
	background-color:#FF363E;
	color:#fff;
	}

.btn_grey{
	background-color:#9a9a9a;
	color:#fff;
	cursor:pointer;
	}

.btn_grey:hover,
.btn_grey:focus{
	background-color:#b4b4b4;
	}

.btn_grey_2b{
	background-color:#b4b4b4;
	color:#fff;
	cursor:pointer;
	}

.btn_grey_2b:hover,
.btn_grey_2b:focus{
	background-color:#9a9a9a;
	}

.btn_grey_3b{
	background-color:#e6e6e6 !important;
	color:#fff;
	box-shadow:none !important;
	-webkit-box-shadow:none !important;
	cursor:pointer;
	}

.btn_grey_3b:hover,
.btn_grey_3b:focus{
	background-color:#D9D9D9;
	}

.btn_linestyle{
	width: 30%;
    float: left;
    margin: 4px;
    background-color: white;
    color: #9a9a9a;
	border:1px solid #e6e6e6;

	padding: 10px 10px;
    line-height: 1.471;
    border-radius: 6px;
	}

.notice_txt_touch{
    padding: 6px 4px;
    margin: 0px;
    line-height: 22px;
	text-align:left;
	}

.notice_txt_touch div{
	width:100%;
	height:20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

.notice_txt_touch:hover,
.notice_txt_touch:focus{
    background-color: #F3F3F3;
	border-radius:6px;
	cursor:pointer;
	}

.box_1 .txt_size_2 .notice_txt_touch{
	margin-left:36px;
}

.content_box_show{
	opacity: 1;
	left: 0px;
	display: block;
	}

.detail_staff_remark_input{
	max-width:248px; /* 250 */
	height: 98px; /*66*/
	resize: none;
	margin-top: 0px;
	padding:4px 10px;
	background-color:#fff;
	}
select + .detail_staff_remark_input{
	height: 54px;
	}

.btn_grey_linewhite{
	float:left;
	margin-left: 6px;
    background-color: #FFF;
    color: #9a9a9a;
    border: 1px solid #e6e6e6;
    padding: 9px 9px;
    line-height: 1.471;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    text-transform: none;
    overflow: visible;
    margin: 0;
    font: inherit;
	}

.btn_grey_linewhite:hover{
	background-color: #F3F3F3;
    border: 1px solid #e6e6e6;
	}

.pop_functions_box_autoheight_hv_limit{
	height:auto;
	max-height:614px;
	}

[aria-label~=Sunday] {
  color: red;
}

@font-face {
	font-family: ourfont;
	src: url(font/OpenSans-Regular.ttf);
	}

body{
	font-family: ourfont;
	font-size: 11pt;
   	color:#4d4d4d;
	font-weight:normal;
    margin: 0;
    padding: 0;
	image-orientation: none;
	line-height: normal;
	}

html{
	position: relative; /* For my demo*/
	}

html button,
html div,
html li,
html a{
	/*transition: all 0.3s ease-in 0s;*/
	}

html, body, body > .ng-scope{ /* , .container-fluid, .row  */
	/*position: relative;*/
	min-height: 530px;/*636  100%*/
  	height: 100%;
    margin: 0;
    padding: 0;

	background-image: url(img/bg.png);
    background-repeat: repeat;

	/*-webkit-overflow-scrolling: touch;
	-webkit-transform: translate3d(0,0,0);*/
}

body > .md-dialog-container{ /* reset ng-amDatePicker  */
	background-image:none;
	}


/*--------------------- @media only screen ---------------------*/





/* ==================== mult-select : approval area  ==================== */
@media only screen and (max-width : 940px) {
.mult_select_approval_area{
    text-align: center;
	padding-left:0;
	}
.m_w100{
	width:100% !important;
	}
}

@media only screen and (max-width : 767px) {
.mult_select_approval_area{
	left:0;
	width:100%;
	}
}

/* ==================== pop date  ==================== */
@media only screen and (max-device-width : 1024px) {

#ui-datepicker-div{
	width:310px;
	}

.ui-datepicker-calendar td{
	line-height:44px;
	}
}

/* ==================== pop alert function (dropdown)  ==================== */
@media only screen and (max-height : 416px) {

.alert_box_content_2{
    max-height: 238px;
    padding: 10px 0;
    margin: 0;
	}
.alert_box_content_2 .alert_box_content_title{
    width: 100%;
	}

.alert_box_content_2 .alert_box_content_2_item_m{
	overflow:auto;
	height:200px;/*153*/
	}

.alert_box_content{
    max-height: 238px;
    padding-top: 10px;
    margin: 0;
	}

.alert_box_content .alert_box_content_title{
    width: 100%;
	}

.alert_box_content .alert_box_content_item_m{
	overflow:auto;
	height:200px;
	}
}

/* ==================== pop box  ==================== */
@media only screen and (max-height : 556px) {  /*526px ------ for login_box_abs < 506px (20px is margin-top concern) */
.login_box_abs{
	/*position:relative;
	top: 0;
    margin: 10px 0;
	margin-left: calc(-400px / 2);

	margin-bottom: 34px;  for show space for power_by*/
	}
}

@media only screen and (max-height : 450px) {  /* for login_box_abs < 400px (50px is "X" margin) */
.pop_functions_box_content_tc{
	height:calc(100% - 68px - 10px);
	}
}

/* ==================== pop box :  pop_functions_box_size_tc ==================== */

@media only screen and (max-width : 600px) {  /* for login_box_abs < 506px (20px is margin-top concern) */
.pop_functions_box_size_tc{
	width: 300px;
    height: 400px;
	max-height:100%;
    margin-top: calc( -400px / 2 );
    margin-left: calc( -300px / 2 );
	}
.pop_functions_box_size_jobposition .jobposition_content{
	max-height:100%;
	}
.pop_functions_profile_amend_inquiry .pop_functions_box_content_tc .same_line{
	width: 100%;
	}
}

@media only screen and (max-height : 450px) {  /* for login_box_abs < 400px (50px is "X" margin) */
.pop_functions_box_size_tc{
	top: 0;
    margin-top: 4px;
	height:calc(100% - 8px);
	}
.pop_functions_box_size_jobposition{
	overflow:auto;
	}
.pop_functions_box_size_jobposition .jobposition_content{
	max-height:100%;
	}
}

/* ==================== pop detail box : 2column ==================== */

@media only screen and (max-height:710px) {  /*max-height:626px*/

.pop_functions_approval{
	bottom: 0;
	/*border-radius:0 0 6px 6px;*/
	}

.pop_functions_box_size_2column{
	top: 4px;
	/*margin: 4px 0;*/
	margin-bottom:8px;
	margin-left: calc(-606px / 2);
	margin-top: 0px;
	width: 606px;
	height: calc( 100% - 8px);
    max-height:calc(100% - 8px);/*calc(100% - 12px - 66px)*/
	border-radius:6px;
	/*border-radius:6px 6px 0 0;*/
	}

.pop_functions_box_size_2column_approval{
	height: calc( 100% - 8px - 70px);
	}

.pop_functions_box_size_1column{ /* pop-up : pop_functions_box_size_1column adjust */
	width:312px;
	margin-left: calc(-312px / 2);
	}

.pop_functions_box_size_1column + .pop_functions_approval{
	width: 312px;
	margin-left: calc( 312px / 2 - 9px);
	}

.pop_functions_box_size_1column .pop_functions_close{ /* pop-up : pop_functions_box_size_1column adjust */
	    left: calc(100%/2 + 312px/2 - 50px);
	}

.pop_functions_box_size_1column .detail_box_name{/* pop-up : pop_functions_box_size_1column adjust */
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /*width: 148px;*/
	}

.pop_functions_box_size_1column .detail_box_bottom_area{/* pop-up : pop_functions_box_size_1column adjust */
	width:290px;
	}

.two_btn_fixed_bottom{

    position: fixed;
    _position:absolute;
    _z-index:10;

	width: 288px;
    bottom: 0px;
    padding: 10px 0;
	margin:initial;
	margin-bottom:4px;
    background-color: #fff;
    border-top: 1px solid #e6e6e6;
	}

.mask_balance_inquiry{
	height:auto;
	top:calc(100% / 2 - 236px);
	}

}

@media only screen and (max-height:487px) {

.mask_balance_inquiry{
	height: calc(100% - 8px);
    top: 4px;
	}
}


@media only screen and (max-width:704px){
.pop_functions_close{
	left:calc(100%/2 + 312px/2 - 50px);
	}

.pop_functions_box_size_1column + .pop_functions_approval{
	width: 312px;
	margin-left: calc( 312px / 2 - 10px);
	}

.pop_functions_box_size_1column .pop_functions_close{ /* pop-up : pop_functions_box_size_1column adjust */
	left: calc(100%/2 + 312px/2 - 50px);
	}

.pop_functions_box_autoheight_hv_limit .pop_functions_close{
	left: calc(100%/2 + 312px/2 - 45px);
	}

.pop_functions_box_size_1column .detail_box_bottom_area{/* pop-up : pop_functions_box_size_1column adjust */
	width:290px;
	}

.pop_functions_box_size_2column{
	width: 312px;

    max-height: calc( 100% - 8px);
	height:614px; /*570*/
    margin-left: calc( -312px / 2 );
    /*margin-top: calc( -570px / 2 );*/
    overflow-y: auto;
    overflow-x: hidden;
	/*border-radius:6px 6px 0 0;*/
	}
.pop_functions_box_size_2column .detail_box_other{
	height: 100%;
	}

.pop_functions_approval{
	width: 312px;
    margin-left: calc( 312px / 2 - 9px);
	/*bottom: calc(100%/2 - 613px/2 - 73px);*/
	border-radius: 6px;
	/*bottom: calc(100%/2 - 613px/2 - 30px);*/
	/*border-radius:0 0 6px 6px;*/
	}

.mask_balance_inquiry{
	margin-top: calc( -570px / 2 );
	border-radius:6px;
	}

.pop_functions_two_btn_center{
	margin:12px auto;
	}

.pop_functions_box_size_2column_approval{
    max-height: calc( 100% - 12px - 66px);
	}

.mt0_m{
	margin-top:0;
	}

.detail_box_bottom_area{
	min-height:inherit;
	margin-left: -10px;
	width: 312px;
	padding-left: 20px;
	padding-right: 20px;
	}

.mask_balance_inquiry .detail_box_bottom_area{
	min-height:inherit;
	}

.detail_box_name{
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 174px; /*148*/
	}

.detail_box_approver_remarks_area,
.detail_box_attachment_area,
.approver_staff_remark,
.detail_box_claim_record_area{
	height:100%;
	overflow-y:hidden;
	}

.approver_staff_remark_2{
	height:auto;
	border:none;
	background-color:transparent;
	padding:0;
	}

	/* ====== staff photo area ======*/

.detail_box_top_photo_mask{
    left: calc(50% - 78px / 2);
    margin-top: 16px;
	background: url(img/leave_detail_box_top_photo_mask_m.png) no-repeat left;
	background-size:contain;
	}

.detail_box_top_photo{
	top: 0;
    height: 78px;
    width: 78px;
    left: calc(50% - 78px / 2);
    margin-top: 16px;
	margin-left:0;
	}

.detail_box_top{
	height:auto;
	padding-left:0;
	padding-top:0;
	}

.detail_box_name{
    position: static;
    width: 100%;
	text-align: center;
	padding-top: 96px;
	white-space:initial;
	}

.detail_box_name .txt_popup_refid{
    position: static;
    width: 100%;
	background-color:#ffad38;
	text-align: center;
	white-space:initial;
	}

	/* ==============================*/

}

@media only screen and (max-width:704px) and (max-height:700px){  /*max-height:626px*/
/*.pop_functions_box_size_2column{
	max-height:calc(100% - 12px - 66px);
	margin-top: 4px;
	top: 8px;
	height: calc(100% - 21px);
	}*/

.mask_balance_inquiry{
	top: 4px;
	/*height: calc(100% - 17px);*/
	margin-top: inherit;
	}

.pop_functions_approval{
	bottom: 0;
	}
}

/* ==================== box_1 control ==================== */

@media (max-width: 1173px) {
.box_1{
	width:312px;
	margin: 8px;
	}

.box_1_full{
	width: 640px;
	min-height:none;
	}

.box_1 .bar_chart_name{
	width: calc(100% - 60px);/*248px*/
	}

.box_1 .txt_size_2 .notice_txt_touch{
	margin-left:0;
	}

}

@media (max-width: 948px) {
.box_1{
    float:none;
	margin: 20px auto;
	}

.box_1_full{
	width:312px;
	}

.title_reset{
	width:312px;
	margin: 4px auto;
	padding: 0px 8px;
	}
}

/* ==================== list_1_box : claim - SHK ==================== */
@media (max-width: 1173px) {
	.page_claim_application_inquiry_shk .txt_type{
		width: 100%;
		}
}

/* ==================== list_1_box : other ==================== */
@media (max-width: 1173px) {
	.page_list_line_7 .list_1_box{
		height: 188px !important;
		}
}

/* ==================== list_1_box : ot ==================== */
@media (max-width: 1173px) {
	.page_ot_application_inquiry .txt_name,
	.page_ot_application_inquiry .txt_date{
		margin-bottom: 10px;
		}
	.page_ot_application_inquiry .list_1_box,
	.page_pending_approval .f_preot .list_1_box,
	.page_pending_approval .f_postot .list_1_box{
		padding-top: 26px;
		height: 324px;
		}
	.page_ot_application_inquiry .txt_date,
	.page_ot_application_inquiry .txt_type,
	.page_ot_application_inquiry .txt_status,
	.page_pending_approval .f_preot .txt_date,
	.page_pending_approval .f_postot .txt_date{
		white-space:normal !important;
		margin-top:0;
		}
	.page_pending_approval .f_preot .txt_date,
	.page_pending_approval .f_postot .txt_date{
		margin-top: 10px;
		}

	.page_pending_approval .f_preot{
		height:192px !important;
		}
	.page_pending_approval .f_postot{
		height:288px !important;
		}

}

/* ==================== list_1_box : in / out time ==================== */
@media (max-width: 1173px) {
	.page_punch_inquiry .txt_jobposition,
	.page_punch_inquiry .txt_type,
	.page_punch_inquiry .txt_status{
		width:100%;
		}
}

/* ==================== list_1_box : " approval_log " control ==================== */

	                                             /* =list name control (for pad) = */
@media (max-width: 1366px) {

.content_box_approval_log{
	/*padding: 2px 4px;*/
	}

.content_box_approval_log .list_bar{
	border:none;
   	padding-bottom: 0;
	}

.content_box_approval_log .list_1_box{
   	float:left;
	width:312px;
	margin: 4px 4px;
	padding:4px;

	border: 1px solid #e6e6e6;
	border-radius:6px;

	padding-top:28px; /* for pad */
	height: 188px;
	}

.page_approval_log .list_1_box:hover,
.page_approval_log .list_1_box:focus{
	border: 1px solid #999999;
	border-radius: 6px;
	}

.page_approval_log .f_cancellation{ /* = list_1_box */
	padding-top:28px;
	height: 150px;
	}

.page_approval_log .txt_content_area_1line{
	width:calc(100% - 48px - 5px); /* 249 */
	margin-top:4px;
	}

.page_approval_log .txt_content_area_2line{
	width:249px;
	margin-top:4px;
	}

.page_approval_log .txt_date{
    width: 250px; /*260px*/
    padding-right: 0px;
	white-space:nowrap;
	margin-right:0;
	}

.page_approval_log .txt_type{
	width: 100%;
	margin-right:0;
	}

.page_approval_log .txt_days{
	float: left;
	width: 100%;
	margin-right:0;
	}

.page_approval_log .txt_status{
	width: 100%;
	margin-right:0;
	}

.page_approval_log .txt_approvaldate{
	float: left;
	width: 100%;
	}

.page_approval_log .txt_name {
	max-height: 40px;
	white-space: initial;
	}

.page_approval_log .txt_add_time_area{
	width:initial;
	}

.txt_add_time_area .txt_add_time{
	width:initial;
	}

}

@media (max-width: 1173px) {
.f_appointment .txt_content_area_2line > .txt_type{ /* for approval_log.html */
	float: left;
    width: 100%;
	}

.txt_add_time_area{
	width:initial;
	}
}


@media (max-width: 940px) {

.content_box_approval_log{
	/*padding: 1px 0px;*/
	max-width:322px;
    margin: 20px auto;
	}

.content_box_approval_log .box_content_inquiry{
	margin-left:23px;
	}

.content_box_approval_log .txt_result{
	margin-left:6px;
	}

.content_box_approval_log .list_1_box{
   	float:none;
	width:312px;
	margin: 4px auto;
	margin-left: -5px;
	padding:4px;
	border:none;
	border-bottom: 1px solid #e6e6e6;
	border-radius:0;

	height: initial;
	padding-top:28px;
	padding-bottom:28px;
	}

.page_approval_log .list_1_box,
.page_approval_log .txt_name{
	max-height: initial;
	}

.page_approval_log .title_reset_2{
	width:312px;
	margin: 4px auto;
	padding: 0px 8px;
	}

}

/* ==================== list_1_box : " normal " control ==================== */

@media (max-width: 1173px) { /*1154*/

.content_box{
	/*padding: 2px 4px;*/
	}

.list_bar{
	border:none;
   	padding-bottom: 0;
	}

.list_1_box{
   	float:left;
	width:312px;
	margin: 4px 4px;
	padding: 6px 4px;

	border: 1px solid #e6e6e6;
	border-radius:6px;
	}

.txt_content_area_1line{
	margin-top:4px;
	}

.page_report_pdf .txt_content_area_1line{
	margin-top: 10px; /* reset same as desktop */
	}

.txt_content_area_2line{
	margin-top:4px;
	}

.txt_content_area_2line .txt_date{
    width: 100%; /*260px*/
    padding-right: 0px;
	white-space:nowrap;
	overflow: initial;
	}

.txt_content_area_2line .txt_add_time_area .txt_date{
    width: auto;
	clear:both;
	}

.txt_days {
	float: left;
	width:82px;
	margin-right:0;
	/*width: auto;
	margin-right: 6px;*/
	}

.txt_type{
	/*float: right;*/
	width: 166px;
    text-align: left;
	margin-right:0;
	}

.txt_fullname{
	margin-right:0;
	width:calc(100% - 69px);
	}

.txt_jobposition{
	margin-right:0;
	width:calc(100% - 69px);
	}

.txt_a_balance{
	top: 30px;
    right: -41px;
    margin-left: initial;
    position: absolute;
	/*margin-top: -22px;
	margin-right:4px;
	margin-left:initial;*/
	}

.mult_select_checkbox{
	margin-top: 44px;
    margin-left: 14px;
	}

.page_approval_application_inquiry .txt_date{
    width: initial;
	}

.page_approval_application_inquiry .txt_type{
	float:left;
	width:100%;
	}

/*.page_pending_approval .txt_type{
	width: 166px;
	}*/

	/* ========= approval_application_inquiry_mark / calendar ========= */

.approval_application_inquiry_mark .txt_type{
   	float:left;
	width:100%;
	}

.approval_application_inquiry_mark .c_appointment .txt_type{
   	float:left;
	width:100%;
	}

	/* ========= profile_amend_inquiry ========= */

.page_profile_amend_inquiry .txt_content_area_2line{
	margin-top:0;
	}

    /* ========= list name control (for pad) ========= */

.txt_content_area_2line .txt_name,
.txt_content_area_2line .txt_name_withlabel{
	white-space:initial;
	}

.txt_name, .txt_name_withlabel{
	max-height: 38px;/*40*/
	}

.txt_name_withlabel{
	padding-right: 80px;
	}

.page_leave_application_inquiry .list_1_box{
	height:122px;
	}

.page_leave_application_inquiry .txt_status,
.page_appointment_application_inquiry .txt_status,
.page_calendar .txt_status,
.page_calendar .txt_type,
.page_approval_application_inquiry .txt_status{
	width:100%;
	margin-right: initial;
	}

.page_leave_balance_inquiry .txt_content_area_1line .txt_name{
	width: calc(100% - 68px);
	margin-right:0;
	/*width: 100%;*/
	white-space:initial;
	}

.page_leave_balance_inquiry .txt_content_area_1line .txt_jobposition{
	width: calc(100% - 68px);
	}

.page_leave_balance_inquiry .list_1_box{
	height:77px;/*96*/
	/*width:100%; outstanding width*/
	}

.page_appointment_application_inquiry .list_1_box{
	height:144px;
	}

.page_appointment_application_inquiry .txt_type{
	float: initial;
	width:100%;
	}

.page_calendar .list_1_box{
	padding-top:28px;
	height: 164px;
	}

.page_pending_approval .list_1_box{
	padding-top:28px;
	height: 162px;
	}

.page_pending_approval.claim .list_1_box{
	height: 208px;
	}

.page_pending_approval .f_appointment{ /* =list_1_box */
	height: 164px;
	}

.page_pending_approval .f_cancellation{ /* =list_1_box */
	padding-top:28px;
	padding-bottom:28px;
	height: initial;
	}

.page_approval_application_inquiry .list_1_box{
	padding-top:28px;
	height: 164px;
	}

}

@media (max-width: 940px) {

.content_box{
	/*padding: 1px 0px;*/
	max-width:322px;
    margin: 20px auto;
	}

.box_content_inquiry{
	margin-left: 8px;
    margin-right: 8px;
	}

.date_to_line{
	border:none;

    /*border-top: none;
	border-left: 2px solid #F6921E;
    height: 6px;
    margin-left: 128px;
    margin-top: 44px;*/

	/*border-right: 2px solid #F6921E;
    border-bottom: 2px solid #F6921E;
    height: 54px;
	margin-left: 248px;
    border-radius: 0 6px 6px 0;*/
	}

.txt_result{
	margin-left:6px;
	}

.list_bar{
	/*padding-bottom: 8px;*/
	}

.list_1_box{
   	float:none;
	height: initial;
	width:100%;
	max-width:312px; /* for small screen fit page 100% auto */
	margin: 4px auto;
	margin-left:-5px;
	padding:4px;
	border: 1px solid #ffffff;
	border-bottom: 1px solid #e6e6e6;
	border-radius:0;
	}

.title_reset_2{
	width:312px;
	margin: 4px auto;
	padding: 0px 8px;
	}

#btn_balance_inquiry_search{
	display:none;
	}

#btn_balance_inquiry_search_content{
	visibility:hidden; /* temp hide when resizing windows */
	}

hr{/*
	margin-left:-24px;*/
	}

.btn_two_margin_left_m{
	margin-left:0;
	}

	/* ========= approval_application_inquiry_mark / calendar =========
.approval_application_inquiry_mark .list_1_box{
	height:auto;
	}*/

    /* ========= list name control (for mobile) ========= */

.txt_name,
.page_approval_log .list_data .txt_name{
	max-height: initial;
	}

.page_leave_balance_inquiry .txt_name,
.page_pending_approval .txt_jobposition,
.page_punch_inquiry .txt_jobposition,
.page_punch_inquiry .txt_type,
.page_punch_inquiry .txt_status{
	width: 100%;
	}

.page_leave_application_inquiry .list_1_box,
.page_leave_balance_inquiry .list_1_box,
.page_approval_application_inquiry .list_1_box,
.page_appointment_application_inquiry .list_1_box,
.page_claim_staff_maintenance .list_1_box,
.page_claim_application_inquiry .list_1_box,
.page_ot_application_inquiry .list_1_box,
.page_ot_application_inquiry .f_preot,
.page_ot_application_inquiry .f_post{
	height:auto !important;
	}

.txt_a_balance{
	margin: auto;
    right: 8px;
	top: 5px;
    bottom: 10px;
	}

.page_pending_approval .list_1_box,
.page_calendar .list_1_box,
.page_approval_application_inquiry .list_1_box{
	height: initial !important;
	padding-bottom:28px;
	}

.page_ot_application_inquiry .list_1_box,
.page_ot_application_inquiry .f_preot,
.page_ot_application_inquiry .f_postto{
    padding-top: 0;
}

}

/* ==================== iphone 4 ==================== */

@media only screen and (max-width :320px) {  /* iphone 4 */

.content{
	padding:0;
	padding-top:22px;
	}

.content_box{
	border:none;
	border-radius:0;
	/*
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;*/
	}

.box_1{
	width:100%;
	border-radius:0;
	}

}

/* ==================== other ==================== */

@media (max-width: 767px) {    /* max-width: 767px (<767)  */

.logo_acube_top_right{
	display:none;
	}

.login_box_abs{
	width:300px;
	height:auto;
	min-height: 356px;
    left: calc(50% - 300px / 2);

	margin-bottom: 34px; /* for show space for power_by*/
	}

.login_bg{
	padding-top: 20px;
	margin-bottom:0;
	}

#hideMe{
	visibility:visible;
	}

.hide_m {
    display: none;
    }

.box_1 .txt_size_2 .notice_txt_touch{
	margin-left:inherit;
	}

.pop_index_msg > .col_3{
	width:100%;
	padding: 4px;
	}
}

/* ==================== other ==================== */


@media (min-width: 48em)  { /* menu : desktop */
#menu {
    margin-top: 44px;
	}
.logo_desktop{
	display:block;
	}
.logo_another{
    margin-left: 0;
	}
.m_hide{
	display:none !important;
	}
}

/*@media screen and (max-width: 550px), screen and (max-device-width: 550px), screen and (max-device-height: 520px){  Block small screen (for roster title bar) */
/*@media screen and (max-width: 430px), screen and (max-height: 400px)*/
/*, screen and (max-device-width: 1280px), screen and (max-device-height: 800px)*/
@media screen and (max-width: 405px), screen and (max-height: 360px){ /* Block tablet and small screen (for roster title bar) */
	.error_roster_screen_content{
		display:block;
		}
	/*.roster_list_title_bar{
		display:none;
		}*/
	}


/* Block mobile only (need to hide above ALL @media, then show this code.)

@media (max-width: 1200px) {
body{
	min-width:1159px;
	}

  }*/


/* ==================== more ==================== */

/* http://stephen.io/mediaqueries/#iPhone6 */
@media only screen and (max-device-width : 736px) {  /* mobile */
}
 /* all table and mobile : @media only screen and (max-width : 1024px)  */


@media (min-width:320px) { /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px) { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px) { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) {  /*hi-res laptops and desktops */ }


/* ==================== brower bar : mobile ==================== */

@media only device{  /* mobile */
	/*Scroll bar nav*/
	div::-webkit-scrollbar {
		width: auto;
	}

	/* Track */
	div::-webkit-scrollbar-track {
		-webkit-box-shadow: auto;
		-webkit-border-radius: auto;
		border-radius: auto;
		background:auto;
	}

	/* Handle */
	div::-webkit-scrollbar-thumb {
		-webkit-border-radius:auto;
		border-radius:auto;
		background:auto;
		-webkit-box-shadow:auto;
	}
	div::-webkit-scrollbar-thumb:window-inactive {
		background: auto;
	}
}

/* ==================== brower bar : Desktop ==================== */


div textarea::-webkit-scrollbar {
    width: 14px;
}
div textarea::-webkit-scrollbar-track {
    background-color: #f6f6f6;
    border-left: 1px solid #ececec;
	border-radius: 6px;
}
div textarea::-webkit-scrollbar-thumb {
    background-color: #ececec;
    border: 1px solid #cccccc;
	border-radius: 6px;
}
div textarea::-webkit-scrollbar-thumb:hover {
	background-color: #cccccc;
}


div::-webkit-scrollbar {
    width: 14px;
}
div::-webkit-scrollbar-track {
    background-color: #f6f6f6;
    border-left: 1px solid #ececec;
	border-radius: 6px;
}
div::-webkit-scrollbar-thumb {
    background-color: #ececec;
    border: 1px solid #cccccc;
	border-radius: 6px;
}
div::-webkit-scrollbar-thumb:hover {
	background-color: #cccccc;
}


body::-webkit-scrollbar {
    width: 14px;
}
body::-webkit-scrollbar-track {
    background-color: #f6f6f6;
    border-left: 1px solid #ececec;
	border-radius: 6px;
}
body::-webkit-scrollbar-thumb {
    background-color: #ececec;
    border: 1px solid #cccccc;
	border-radius: 6px;
}
body::-webkit-scrollbar-thumb:hover {
	background-color: #cccccc;
}

/* ==================== .form-control placeholder reset ==================== */

.form-control::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
	color: #CDCDCD;
	}
.form-control:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: #CDCDCD;
	opacity:  1;
	}
.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: #CDCDCD;
	opacity:  1;
	}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #CDCDCD;
	}

/* ==================== list_1_box-selected reset ==================== */

.list_1_box-selected{
	border: 1px solid #F6921E;
	border-radius: 6px;
	opacity: 0.5;
	}

/*--------------------- Claim ---------------------*/

	/*----- placeholder -----*/
input #ApplicationNo::placeholder{
	text-transform: capitalize;
	}

	/*----- common -----*/
.menu_header_L2_default_img{
	background-image:url(img/photo_sample_big.png);
	}

.txt_label_claim_attachment{
	margin-top: -2px;
    margin-bottom: 5px;
	}

.txt_label_claim_attachment > span{
	padding:0px 4px;
	border-radius:4px;
	white-space: nowrap;
	border:1px solid #F6921E;
	color:#F6921E;
	}

.txt_label_claim_attachment img{
	margin-bottom: 2px;
	}

.btn_dropdown_withremarks{
    color: #fff;
    width: 100%;   /* 196px */
	max-width: 248px;
	height:44px;
    line-height: 24px;
	text-align: left;
	-webkit-appearance:none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
	padding-right:24px;
	border-radius:6px 6px 0 0;

	background-image:url(img/list_arrow_down.png);
	background-repeat:no-repeat;
	background-size:12px;
	background-position:95% 50%;
}

.btn_dropdown_withremarks + textarea{
	border-radius:0 0 6px 6px;
}

.swal2-container.swal2-shown {
	background-color: rgba(0, 0, 0, 0.75);
	}

	/*----- hide photo mask box -----*/
.page_maintenance_2field_1label .p_normal,
.page_2lv_1fieldbig_2fieldsmall .p_normal{
	background-image:none;
	}

	/*----- datepicker_new -----*/
.datepicker_new{
	width: 248px;
	position: relative;
	}
.datepicker_new > .input_icon_date{
	position: absolute;
	top: 10px;
	right: 10px;
	}

	/*----- pop_functions_two_btn_center + different btn area -----*/
.pop_functions_2circle_2square .btn{
	width: 96px;
    margin-left: 8px;
    margin-right: 0;
	}
.pop_functions_1circle_2square{
    margin: 10px 0;
	margin-right:10px;
	}
.pop_functions_1circle_2square .btn{
	width: 96px;
    margin-left: 8px;
    margin-right: 0;
	}

	/*----- content_box : ALL claim area -----*/
.content_box_claim{
	display:none;
	}

.content_box_claim + a + .list_result_area{
	display:block;
	position: relative;
    left: 0;
    opacity: 1;
	}

.txt_result_number{
	float: right;
    line-height: 33px;
	}

	/*----- list_1_box result : page_claim_application_inquiry -----*/
.page_claim_application_inquiry .txt_date{
	width: 194px;
    margin-right: 10px;
	}

.page_claim_application_inquiry .txt_days{
	width: 184px;
	/*color:#F6921E;*/
	}
/*
.page_claim_application_inquiry .txt_status{
	width: 188px;
	}

.page_claim_application_inquiry .txt_file_num{
	float:left;
	width: 162px;
	margin-right:10px;
	}	*/

.page_maintenance_2field_1label .txt_content_area_2line{
	padding-top:8px;
	}

.page_maintenance_2field_1label .txt_name{
	width: calc(100% - 500px);
	}

.page_maintenance_2field_1label .txt_name_description{
	float:left;
	width: 500px;
	}

.page_maintenance_2field_1label .txt_label_area{
	position: absolute;
    width: calc(100% - 48px - 5px);
    top: 4px;
    right: 4px;
	}

.page_maintenance_2field_1label .txt_label_area > div{
	float: right;
    padding: 0 4px;
    border-radius: 6px;
	margin-left: 4px;
    /*color: #9a9a9a;
    border: 1px solid #e6e6e6;*/
	}

@media (max-width: 1173px) {
	.page_claim_application_inquiry .list_1_box{
		height:166px;
		}

	.page_claim_application_inquiry .txt_date,
	.page_claim_application_inquiry .txt_type,
	.page_claim_application_inquiry .txt_status,
	.page_maintenance_2field_1label .txt_name{
		width:100%;
		margin-right:0;
		}

	.page_maintenance_2field_1label .txt_name_description{
		white-space:normal;
		width: 254px;
    	height: 44px;
		}

	.page_claim_application_inquiry .txt_days,
	.page_claim_application_inquiry .txt_file_num{
		width: calc(50% - 0px);
		margin-right: 0;
		}

	.page_maintenance_2field_1label .list_1_box{
		height: 108px;
		padding-top:28px;
		}

	.page_maintenance_2field_1label .txt_content_area_2line{
		margin-top:0;
		}
}

@media (max-width: 940px) {
	.page_maintenance_2field_1label .list_1_box{
		height: auto;
		padding-bottom: 28px;
		}

	.page_maintenance_2field_1label .txt_name_description{
		width: auto;
    	height: auto;
		white-space:normal;
		}
}

	/*----- list_1_box result : page_claim_staff_maintenance -----*/
.page_claim_staff_maintenance .txt_position{
	width: 248px;
    margin-right: 10px;
	}

.page_claim_staff_maintenance .txt_department{
	float:left;
	width: 248px;
	margin-right:10px;
	}

.page_claim_staff_maintenance .txt_staff_id{
	float:left;
	width: 152px;
	margin-right:10px;
	}

.page_claim_staff_maintenance .txt_gender{
	float: left;
	width: 98px;
	}

.page_claim_staff_maintenance .txt_label_area{
	position: absolute;
    width: calc(100% - 48px - 5px);
    top: 4px;
    right: 4px;
	}

.page_claim_staff_maintenance .txt_label_area > div{
	float: right;
    padding: 0 4px;
    border-radius: 6px;
	margin-left: 4px;
    /*color: #9a9a9a;
    border: 1px solid #e6e6e6;*/
	}

@media (max-width: 1173px) {

	.page_claim_staff_maintenance .txt_position,
	.page_claim_staff_maintenance .txt_type,
	.page_claim_staff_maintenance .txt_department,
	.page_claim_staff_maintenance .txt_name{
		width:100%;
		margin-right:0;
		}

	.page_claim_staff_maintenance .txt_staff_id,
	.page_claim_staff_maintenance .txt_gender{
		margin-top: 10px;
		}

	.page_claim_staff_maintenance .txt_staff_id{
		width: 138px;
		}

	.page_claim_staff_maintenance .txt_gender{
		width: 100px;
		}

	.page_claim_staff_maintenance .list_1_box{
		height: 156px;
		padding-top:28px;
		}

	.page_claim_staff_maintenance .txt_content_area_2line{
		margin-top:0;
		}
}

@media (max-width: 940px) {
	.page_claim_staff_maintenance .list_1_box{
		height: auto;
		padding-bottom: 28px;
		}
}

	/*----- list_1_box result : page_2lv_1fieldbig_2fieldsmall -----*/

.page_2lv_1fieldbig_2fieldsmall .txt_department{
	float:left;
	width: 430px;
	margin-right:10px;
	}

.page_2lv_1fieldbig_2fieldsmall .txt_ana_code{
	float:left;
	width: 166px;
	margin-right:10px;
	}

.page_2lv_1fieldbig_2fieldsmall .txt_label_area{
	position: absolute;
    width: calc(100% - 48px - 5px);
    top: 4px;
    right: 4px;
	}

.page_2lv_1fieldbig_2fieldsmall .txt_label_area > div{
	float: right;
    padding: 0 4px;
    border-radius: 6px;
	margin-left: 4px;
    /*color: #9a9a9a;
    border: 1px solid #e6e6e6;*/
	}

@media (max-width: 1173px) {

	.page_2lv_1fieldbig_2fieldsmall .txt_ana_code,
	.page_2lv_1fieldbig_2fieldsmall .txt_department,
	.page_2lv_1fieldbig_2fieldsmall .txt_name{
		width:100%;
		margin-right:0;
		}

	.page_2lv_1fieldbig_2fieldsmall .txt_department{
		margin-bottom:10px;
		}

	.page_2lv_1fieldbig_2fieldsmall .list_1_box{
		height: 156px;
		padding-top:28px;
		}

	.page_2lv_1fieldbig_2fieldsmall .txt_content_area_2line{
		margin-top:0;
		}
}

@media (max-width: 940px) {
	.page_2lv_1fieldbig_2fieldsmall .list_1_box{
		height: auto;
		padding-bottom: 28px;
		}
}






	/*----- page : claim application's field -----*/
.confirm_claim_application_position .txt_detail_sub_adjust{
	width:54px;
	}

	/*----- popup : claim inquiry detail -----*/
.pop_claim_inquiry_detail{
	display:block;
	}

.bg_dark_1{
	background-color: rgba(0,0,0,0.75);
	}

.pop_claim_inquiry_detail .detail_box_bottom_2point{
	background: url(img/claim_detail_box_bottom.png) repeat-x left top;
	background-size: contain;
    background-color: #fff;
	height: 170px;
	}

.pop_claim_inquiry_detail .detail_box_date_time1{
	margin-left: 152px;
	margin-top:-2px;
	}

.pop_claim_inquiry_detail .detail_box_sub_icon{
	margin-top: 12px;
	}

.pop_claim_inquiry_detail .detail_box_date_num{
	margin-top: -40px; /*42*/

	width: 144px;
    height: 26px;

    overflow: hidden;
    text-overflow: initial;
    white-space: initial;
    word-break: break-word;
	}

.pop_claim_inquiry_detail .detail_box_amount_unit{
    margin-top: -42px;
    margin-left: 108px;
    width: 40px;
    text-align: center;
	}

.pop_claim_inquiry_detail .detail_box_role{
	margin-top: -38px;
    margin-left: 152px;

	width: 150px;
    height: 42px;

    overflow: hidden;
    text-overflow: initial;
    white-space: initial;
    word-break: break-word;
	}

.detail_box_claim_record_area{
	height: 260px;
    margin-top: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #E1E1E1;
	}

.detail_box_claim_record_area > div{
	width:100%;
	}

.detail_box_claim_record_area > div > div:nth-child(1){
	margin-top:6px;
	}

.detail_control_record{
	border: 1px solid #E1E1E1;
    background-color: #F1F1F1;
	border-radius:6px;
	padding: 6px 10px;
    margin-bottom: 10px;
	}

@media only screen and (max-width: 704px){
	.detail_box_claim_record_area{
		height: 100%;
		overflow-y: hidden;
	}
}

	/*----- page number field -----*/
.pagination{
    margin-top: 20px;
    margin-bottom: 0px;
	}

.pagination>li>a, .pagination>li>span{
	color: #9a9a9a;
	line-height: 30px;
    width: 44px;
    text-align: center;
    border-radius: 6px;
    margin-right: 6px;
    margin-bottom: 6px;
	cursor:pointer;
	}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus{
	background-color: #F6921E;
	border-color: #F6921E;
	color: #fff;
	}

.PagedList-skipToFirst > a,
.PagedList-skipToPrevious > a,
.PagedList-ellipses > a,
.PagedList-skipToNext > a,
.PagedList-skipToLast > a{
    text-indent: 200%;
	overflow:hidden;
    color: transparent;
	width:44px;
	height:44px;
	}

.PagedList-skipToFirst > a{
	background: url(img/PagedList_skipToFirst.png) no-repeat center;
    background-size: contain;
    background-size: 18px;
	}

.PagedList-skipToPrevious > a{
	background: url(img/PagedList_skipToPrevious.png) no-repeat center;
    background-size: contain;
    background-size: 18px;
	}

.PagedList-ellipses > a{
	background: url(img/PagedList_ellipses.png) no-repeat center;
    background-size: contain;
    background-size: 18px;
	}

.PagedList-skipToNext > a{
	background: url(img/PagedList_skipToNext.png) no-repeat center;
    background-size: contain;
    background-size: 18px;
	}

.PagedList-skipToLast > a{
	background: url(img/PagedList_skipToLast.png) no-repeat center;
    background-size: contain;
    background-size: 18px;
	}

	/*----- mult-dropdown field -----*/
.field{
	width:248px;
	}

.ui.label{
	font-weight: normal;
	}

.ui.label>.close.icon, .ui.label>.delete.icon{
	position:absolute;
	left:4px;
	}

.ui.multiple.dropdown>.label{
	margin-left:20px;
	text-align: left;
    width: 90%;
    line-height: 23px;
	background-color:#fff;
	color:#4d4d4d;
	box-shadow:none;
	}

.ui.multiple.dropdown>.label:hover,
.ui.multiple.dropdown>.label:focus{
	background-color:#fff;
	color:#4d4d4d;
	cursor:default;
	}

.ui.label>.close.icon, .ui.label>.delete.icon{
	font-size: 1.6em;
    line-height: 12px;
	margin-left:0;
	margin-top:2px;
    opacity: 1;
    background-image: url(img/btn_cross_w.png);
    background-size: contain;
    background-repeat: no-repeat;
    color: transparent;
	cursor:pointer;
	}

.ui.label>.close.icon:hover, .ui.label>.delete.icon:hover,
.ui.label>.close.icon:focus, .ui.label>.delete.icon:focus{
    opacity: 0.5;
	}

.ui.fluid.dropdown{
	background-color:#F6921E;
	border:0;
	border-radius:6px;
	height:100%;
	line-height:22px;
	min-height: 44px;
	}

.ui.selection.active.dropdown .menu{
	box-shadow:none;
	border:none;
	-webkit-box-shadow: 0px 0px 4px #ccc;
    -moz-box-shadow: 0px 0px 4px #ccc;
    box-shadow: 0px 0px 4px #ccc;
	}

.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:focus{
	border-color:#F6921E;
	}

.ui.fluid.dropdown>.dropdown.icon{
	font-size: 16pt;
    color: #fff;
    opacity: 1;
    line-height: 4px;
    right: 12px;
	}

.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text{
	color:#fff;
	line-height: 22px;
	}

.ui.selection.visible.dropdown>.text:not(.default){
	color:#fff;
	}

.ui.dropdown>.text{
	color:#fff;
	}

.ui.fluid.dropdown>.dropdown.icon{
	top:20px;
	}

.ui.selection.dropdown .menu>.item{
	line-height: 21px;
	}

	/*----- popup : claim maintenance -----*/

.pop_functions_box_size_2column_approval.maintenance .detail_control_record_area{
    padding: 8px 36px;
    margin: 0px -24px;
	}
.pop_functions_box_size_2column_approval.maintenance .detail_control_record_area > .form_content_input_field > .input_field_large{
    width: 260px;
	}
.pop_functions_box_size_2column_approval.maintenance .detail_control_record_area > .form_content_input_field:first-child{
	margin-right:24px;
	}
.pop_functions_box_size_2column_approval.maintenance .detail_box_bottom_area{
	background-color:transparent;
	}

.pop_functions_box_size_2column.maintenance .form_content_list_field_2 > .title_n_btn{
	position:relative;
	}
.pop_functions_box_size_2column.maintenance .form_content_list_field_2 > .title_n_btn > div:first-child{
	position:absolute;
	top:-16px;
	right:0;
	}
.pop_functions_box_size_2column.maintenance .form_content_list_field_2 .list{
	overflow:auto;
	height:228px;
	border: 1px solid #e1e1e1;
	border-radius:6px;
	}
.pop_functions_box_size_2column.maintenance .form_content_list_field_2 .list > div{
	border-left: none;
	border-right: none;
	}
.pop_functions_box_size_2column.maintenance .box_content_inquiry > .form_content_input_field{
	float:left;
	margin-right:10px;
	}

.form_content_input_area_2field{
	margin-left: 34px;
	min-height: 72px;
	margin-bottom: 10px;
	display: block;
	}

.form_content_input_area_2field .form_content_input_field{
	float: left;
	width: 248px;
    min-height: 72px;
    margin-right: 12px;
    margin-bottom: 10px;
	}

.detail_control_record_area{
    background-color: #f6f6f6;
	padding: 8px 46px;
    margin: 0 -12px;
	}
.detail_control_record_area > .form_content_input_field:first-child{
	margin-right:14px;
	}

.form_content_input_area_2field .mh_auto{
	min-height: 100%;
	}

@media only screen and (max-width: 704px){
	.form_content_input_area_2field{
		margin-left: 10px;
		}

	.detail_control_record_area{
		margin-left: -20px;
		padding-left: 20px;
		}
}

	/*----- autofill reset -----*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}




/*--------------------- roster ---------------------*/

.box_title_roster{
    line-height: 13pt;
	visibility:hidden;
	}

.content_box_team_roster{
	margin-bottom: 56px;
	max-width:none;
	}

.content_box_team_roster .list_bar_mt{
	margin-top:-100px;
	}

.content_box_commission{
	max-width:none;
	}

.control_bar_roster{
    left: calc(100%/2 - 220px + 40px + 270px/2);
    position: fixed;
    _position: absolute;
    _z-index: 10;
    _position: absolute;
    bottom: 54px;
    z-index: 3;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 8px #ccc;
    -moz-box-shadow: 0px 0px 8px #ccc;
    box-shadow: 0px 0px 8px #ccc;
    background-color: rgba(255,255,255,0.9);
	}

.content_box_commission .control_bar_roster{
    bottom: 10px;
	}

.mark_keep_key{
	position: absolute;
    width: 18px;
    height: 42px;
    margin-top: 2px;
    margin-left: -19px;
    background-image: url(img/icon_key_w.png);
    background-repeat: no-repeat;
    background-position: 3px 16px;
    background-size: 12px;
    font-size: 7pt;
    font-weight: bold;
    padding: 2px 4px;
    color: yellow;
    background-color: #ffad38;
    border-radius: 6px 0 0 6px;

    /*width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #F6921E transparent transparent transparent;*/
	}

.roster_employment_data > span{
	padding: 0px 4px;
    margin: 0;
    border: 1px solid #D7D7D7;
    border-radius: 6px;
    line-height: 0;
    font-size: 10px;
    margin-left: 6px;
    text-align: right;
	}

.roster_position{
	display:none;
	}

.duty_roster_area{
	margin-top:20px;
	}

.btn_reset_roster ul#filter_options{
	bottom: 0;
    position: fixed;
    max-width: none;
    width: calc(100% - 220px);
    z-index: 4;
    margin: 0;
    left: 220px;
    -webkit-box-shadow: 0px 0px 8px #ccc;
    -moz-box-shadow: 0px 0px 8px #ccc;
    box-shadow: 0px 0px 8px #ccc;
    border-top: 1px solid #CDCDCD;
    border-radius: 0;

	display:none;
	opacity: 0;
	bottom: -76px;
	}

.btn_reset_roster ul#filter_options li {
    margin-right: 0;
    width: calc(100% / 4 - 0.5px);
    /*border-right: 1px solid #F0F0F0;*/
	}

.btn_reset_roster ul#filter_options li span{
	white-space:normal;
	}

.input_shift_display{
	z-index:1;
	font-size: 14px;
	padding-top:3px;
	height:100% !important;
	}

.one_line{
    line-height:42px !important;
	}

.pop_pending_approval{
	position: fixed;
    z-index: 2;
	padding:10px;
    top: 44px;
    right: -320px;
    height: 100%;
    width: 320px;
    padding-bottom: 44px;
    background-color: #fff;
	transition: all 0.3s ease-in 0s;
	}
.pop_pending_approval .content_box{
	box-shadow: none;
	-webkit-box-shadow: none;
	}
.pop_pending_approval .inf_pending_box{
	overflow: auto;
    margin: 0;
    height: calc(100% - 206px) !important;
    border: 1px solid #ececec !important;
    border-radius: 6px;
	}

	/*
@media (max-width: 1060px){
	.btn_reset_roster ul#filter_options > li > a > div > span {
		font-size:9pt;
		}
	}

@media (max-width: 963px){
	.btn_reset_roster ul#filter_options > li > a > div > span {
		display: none;
		}
	}*/
@media (max-width: 767px){
	.btn_reset_roster ul#filter_options {
		left:0;
		margin-left: 0;
		width: 100%;
		}
	.control_bar_roster{
		left:calc(100%/2 - 270px/2);
		}
}

.roster_list_left{
	float:left;
	/*width:200px;  --- let it auto width size --- */
	}

.roster_list_left > div{
	padding:0;/*4px*/
	border-bottom:1px solid #e6e6e6;
	border-right:1px solid #F6921E;
	}

.roster_list_left > div:nth-child(1){
	/*border: none;*/
	border-right:1px solid #F6921E;
	border-top:1px solid #e6e6e6;
	}

.roster_list_left_tool{
	background-color:#fff;
	height:94px; /* --- from auto size --- */
	width:262px; /* --- from auto size --- */
	}

.roster_list_left .cell_roster_left{
	position:relative;
	height:52px;/*49 or 115*/
	width:262px;
	}

.roster_list_left .txt_photo_area_medium{
	display:none;
	margin-top:1px;
	}

.roster_list_left .cell_roster_left .txt_content_area_2line{
	width: calc(100% - 162px);/*100% - 156px*/
    margin-top: 2px;
    margin-left: 2px;
    padding-right: 2px;
    margin-bottom: 0;
	}

.roster_list_left .cell_roster_left .txt_content_area_2line .txt_name{
	max-height: 28px;
	white-space: normal;
	font-size: 9pt;
	}

.roster_list_left .cell_roster_left .txt_content_area_2line > div:nth-child(3),
.roster_list_left .cell_roster_left .txt_content_area_2line > div:nth-child(4){
	font-size: 8pt;
	}

.roster_list_left_tool .calendar_date_bar{
	margin-left:6px;
	}

.roster_list_left_tool .list_bar{
	margin:0px;
	margin-left:6px;
	}

input[type=text]#myInp_roster:focus,
input[type=text]#myInp_roster:hover,
input[type=text]#myInp_roster:active{
	position: absolute;
    width:150px;
    background-color: #fff;
	outline: 0;
	cursor:text;

   border-radius: 300px;
}

.roster_list_left .btn_search{
	margin-left:130px;
	}

.roster_list_title_bar{
	top:-200px; /*hide it default*/
	opacity:0; /*hide it default*/

	position:fixed;
	overflow:auto;
	top:44px;
	z-index:2;/*1*/
	padding-top: 44px;
	overflow-y:hidden;

	margin-left: -10px;
	width:calc(100% - 298px + 20px);/*100% 860px*/
    border-right: 10px solid #fff;
    border-left: 10px solid #fff;
    background-color: rgba(256, 256, 256, 0.9);
	border-bottom: 1px solid #F6921E;
	}

.roster_list_time_sheet{/*must*/
	display:none;
	position:fixed;
	bottom:50px;
	width:calc(100% - 220px - 64px);/*78*/
	height: 200px;
	overflow: auto;
	background:#fff;
	border-top:1px solid #F6921E;
	resize: vertical;
	}
.roster_list_time_sheet .roster_list_right{/*must*/
	height:auto;
	}
.roster_list_time_sheet .roster_list_left .cell_roster_left{
	/*height:25px; ONLY*/
	height: 20px;
    font-size: 9pt;
    line-height: 20px;
	}
.roster_list_time_sheet .cell_roster_time{
	line-height:28px;
	background-position: 80px 4px;/*70px 7px*/
	}
.roster_list_time_sheet .roster_list_right > div > .data .cell_data_view{
	line-height:20px;/*24*/
	}
.roster_list_time_sheet .roster_list_right > div > .data > div{/*.data div*/
	height:20px;/*24.97*/
	}

	/* -- reset to show top bar item
.roster_list_title_bar .roster_list_left_tool,
.roster_list_title_bar .roster_list_day{
	display:block;
	}*/

@media (max-width: 767px) {/*48em*/
	.roster_list_title_bar,
	.roster_list_time_sheet{
		width:calc(100% - 60px + 20px);
		}
	}

.roster_list_title_bar_left_reset{
	position:fixed;
	z-index:1;

	padding: 2px;
    /*border: 1px solid #e6e6e6;*/
    border-right: 1px solid #F6921E;
	}

.roster_list_title_bar_left_reset .roster_list_left_tool{
	border-bottom: none;
	}

.roster_list_title_bar_right_reset{
	padding-left:262px;
	width:calc((34px * 31) + 262px);  /* --- from auto size --- */
	}

.roster_list_right{
	float:left;
	height:100%;
	width:calc(100% - 262px);
	white-space: nowrap;
	overflow-y:hidden;
	overflow-x:hidden;
	}

.roster_list_right > div:nth-child(1){
	width:calc(108px * 34); /*3674*/  /* --- from auto size --- */
	height:100%;
	border-top: 1px solid #e6e6e6;
	display: flex;
	flex-direction: column;
	}

.roster_list_right > div:nth-child(1) > div{ /* --- auto break line --- */
	clear:both;
	}

.roster_list_right > div > .data > div{ /* --- data cell control --- */
	/*position: relative;*/
	float:left;
	width:34px;  /*follaw auto width*/
	height:52.97px;  /*follaw auto height*/
	/*padding:4px;*/
	text-align:center;
	font-size:12px;
	border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;

	overflow:hidden;
	/*text-overflow: ellipsis;
    white-space: nowrap;*/
    word-wrap: break-word;
	}
.roster_list_right > div > .data > div:nth-last-child(1){
    border-right: 1px solid #e6e6e6;
	}

.content_box_commission .roster_list_right > div > .data div{
	width:calc(34px * 2);
	}

.roster_list_right > div > .data .cell_data_view{
	line-height:48px;/*104*/
	}

.roster_list_right > div > .data .input_shift{
	position:relative;
	}

.roster_list_right > div > .data .input_shift .input_duty_code_reset{
	position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
	display:none;
    padding: 1px;
	line-height: 16px;
	text-align: center;
	background:none;
	border-radius: 0;
    overflow: hidden;
	height:100%;
	background-color:#fff;

	/*border: 2px dashed #F6921E;*/
	/*position: absolute;
    border: none;
	background: transparent;
    width: 100%;
    margin: 0;
    text-align: center;
    height: 100%;
    border-radius: 0;
	padding:0;*/
	}

.roster_list_right > div > .data .input_shift:hover,
.roster_list_right > div > .data .input_shift:focus,
.roster_list_right > div > .data .input_remark:hover,
.roster_list_right > div > .data .input_remark:focus{
	color: #9a9a9a;/*F6921E*/
    border: 1px solid #ffad38;
	}

.roster_list_right > div > .data .cell_data_view .input_duty_code_reset{
	position: absolute;
    border: none;
	background: transparent;
    width: 100%;
    margin: 0;
    text-align: center;
    height: 100%;
    margin-left: -5px;
    margin-top: -4px;
    border-radius: 0;
	color:#4d4d4d;
	cursor:default;
	}

.roster_list_right > div > .data .btn_duty_code_reset{
    position: absolute;
	z-index:1;
	display:none;
	padding:0;
	bottom: 1px;
    left: calc(50% - 24px / 2);
	/*left: calc(50% - 24px/2);
    bottom: 2px;*/
    width: 24px;
    height: 12px;
    border-radius: 4px;
    border: 1px solid #CDCECF;
    background-color: #fff;
    color: #4d4d4d;
    background-image: url(img/list_arrow_down_g.png);
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: center;

    text-indent: 100%;
    white-space: nowrap;
	overflow: hidden;

	/*position: absolute;
    display: none;
    bottom: 8px;
    width: 44px;
    height: 34px;
    background-position: center;
    left: calc(50% - 22px);

	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;*/
	}

.roster_list_right > div > .data .btn_duty_code_reset:hover,
.roster_list_right > div > .data .btn_duty_code_reset:focus{
    background-color: #F3F3F3;
	}
	/*
.roster_list_right > div > .data .btn_duty_code_reset option[selected]{
    color:red;
	}*/

.content_box_commission .roster_list_right > div > .data .input_remark{
    width: calc((34px * 2) * 4);
	}

.roster_list_right > div > .data .input_remark textarea{
	font-size: 11pt;
	text-align: center;
    padding-top: 12px;
	width:100%;
	height:100%;
	border:none;
	background-color:transparent;
	}

.roster_list_day{
	position:relative;
	}

.roster_list_day > div{
	float:left;
	width:34px;/*108*/
	height:94px; /*--- can let it auto size --- */
	padding:2px;/*4*/
	text-align:center;
	border-right:1px solid #e6e6e6;
	background-color: #f3f3f3;
	}

.content_box_commission .roster_list_day > div{
	width:calc(34px * 2);
	}

.roster_list_day > .txt_day_with_num > div:nth-child(1){
	color: #9a9a9a;
	font-size:9pt;
	}

.roster_list_day > .txt_day_with_num > div:nth-child(1),
.roster_list_day > .txt_day_with_num > div:nth-child(2){
	float:none;
	width:auto;
	height:auto;
	padding:0px;
	}

.roster_list_day > .txt_single_center{

    /* Firefox */
    display:-moz-box;
    -moz-box-pack:center;
    -moz-box-align:center;

    /* Safari and Chrome */
    display:-webkit-box;
    -webkit-box-pack:center;
    -webkit-box-align:center;

    /* W3C */
    display:box;
    box-pack:center;
    box-align:center;

	overflow:hidden;
	/*text-overflow: ellipsis;
    white-space: nowrap;*/
    word-wrap: break-word;
	}

.content_box_commission .roster_list_day > .txt_single_center_3box{
	width: calc((34px * 2) * 4);
	}

.roster_list_day > .txt_single_center > div:nth-child(1){
	font-size:9pt;
	color:#9a9a9a;
	}

.roster_list_day > .txt_shift_with_time{
	padding:0;
	}

.roster_list_day > .txt_shift_with_time div{
	height:calc(100% / 2);
	line-height: 46px;
	}

.roster_list_day > .txt_shift_with_time > div:nth-child(2){
    margin: 0;
	border-top: 1px solid #e6e6e6;
	font-size: 9pt;
	background-color:#fff;
	color: #9a9a9a;
	}

.roster_list_day > .txt_shift_with_time .date div{
	line-height: 1.15;
	background-color: #f3f3f3;
	}

.roster_list_day > .txt_shift_with_time .date div:nth-child(1){
    font-size: 9pt;
	padding-top: 4px;
	color:#9a9a9a;
	}

.roster_list_day > .txt_shift_with_time .key_open_close{
	margin: 0 -4px;
	padding: 3px;/*6*/
	background-color: #fff;
	}
	/*
.roster_list_day > .txt_shift_with_time .key_open_close > div > div{
    background-image: url(img/icon_key.png);
    background-repeat: no-repeat;
    background-size: 12px;
	background-position:2px 2px;

	padding-left:16px;
	text-align:left;
	overflow:hidden;
	}*/

.roster_list_day > .txt_shift_with_time .key_open_close > div:nth-child(1),
.roster_list_day > .txt_shift_with_time .key_open_close > div:nth-child(2){
    background-image: url(img/icon_key.png);
    background-repeat: no-repeat;
    background-size: 12px;
	background-position:2px 2px;

	padding-left:16px;
	text-align:left;
	overflow:hidden;

	line-height: 18px;
	border-radius:6px;
	font-size:8pt;

	/*margin-right:4px;
	width:calc(100% / 2 - 2px);
	float:left;*/
	}

.roster_list_day > .txt_shift_with_time .key_open_close > div:nth-child(1){
	border: 1px solid #3597d4;
    color: #3597d4;
	}

.roster_list_day > .txt_shift_with_time .key_open_close > div:nth-child(2){
	margin-top:3px;
	border: 1px solid #37c77f;
    color: #37c77f;
	/*margin-right:0;*/
	}

.roster_list_day > .txt_shift_with_time .key_open_close > div > div{
	line-height: 1.15;
	height: 100%;
	}
/*
.duty_roster_name{
	float:left;
	width:calc(100% - 64px + 8px);
	background:red;
	}*/

.duty_roster_summary_area{
    float: left;
	margin-top: 2px;
	width: 160px;/*151*/

	/*margin: -4px;
    padding: 2px;
    width:64px;
	position: absolute;
	width:100%;
	background:#c0c0c0;*/
	}

.duty_roster_summary_area img{
	width:10px;
	vertical-align: baseline;
    margin-right: 1px;
	}

.duty_roster_summary_area > div{
	float: left;
	width: 56px;/*51*/
    /*margin-right: 2px;*/
    margin-bottom: 2px;
    padding: 0px 2px;
    line-height: 19px;
    /*border-radius: 4px;*/
    white-space: nowrap;
    font-size: 8pt;

	padding-left: 14px;
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: 1px 3px;

	overflow:hidden;
	/*text-overflow: ellipsis;
    white-space: nowrap;*/
    word-wrap: break-word;
	}

.duty_roster_summary_area > div > span > span{
	font-size: 7pt;
	}

.duty_roster_summary_area > div:nth-child(1){
	border-right: 1px solid #D7D7D7;
    border-left: 1px solid #D7D7D7;
    color: #9a9a9a;
	font-size: 8pt;
    line-height: 11px;
	padding: 1px;
	width: 48px;/*43*/
	}
.duty_roster_summary_area > div:nth-child(1) > div{
	border-bottom: 1px solid #D7D7D7;
	padding: 1px;
	}
.duty_roster_summary_area > div:nth-child(1) > div:last-child{
	border-bottom: none;
	}
.duty_roster_summary_area > div:nth-child(1) > div > span{
	color: #000;
	}

.duty_roster_summary_area > div:nth-child(2){
	/*border: 1px solid #9b59b6;*/
	border-right: 1px solid #D7D7D7;
	border-bottom: 1px solid #D7D7D7;
    color: #9b59b6;
	background-image: url(img/icon_roster_target.png);
	}
.duty_roster_summary_area > div:nth-child(3){
	/*border: 1px solid #c88c4e;*/
	border-bottom: 1px solid #D7D7D7;
    color: #c88c4e;
	background-image: url(img/icon_roster_full_day.png);
	}
.duty_roster_summary_area > div:nth-child(4){
	/*border: 1px solid #3597d4;*/
	border-right: 1px solid #D7D7D7;
    color: #3597d4;
	background-image: url(img/icon_roster_open.png);
	}
.duty_roster_summary_area > div:nth-child(5){
	/*border: 1px solid #37c77f;*/
    color: #37c77f;
	background-image: url(img/icon_roster_closed.png);
	}
.duty_roster_summary_area > div:last-child{
	float:none;
    margin: 0;
	width:0;
	padding:0;
	background:blue;
	}

.roster_list_day .txt_leave_category_title{
	position:absolute;
    padding: 0;
	width: calc(34px * 13);
    height: 49px;
	color: #9a9a9a;
	font-size:9pt;
	}
.roster_list_day .txt_leave_category_title div{
	float: left;
    height: 48px;
	/*border: 1px solid #e6e6e6;*/
	background-color: #f3f3f3;
    line-height: 48px;
	}
.roster_list_day .txt_leave_category_title div:nth-child(1){
	width:calc(34px * 4 - 0px);
	}
.roster_list_day .txt_leave_category_title div:nth-child(2){
	width:calc(34px * 4 - 0px);
	border-left: 1px solid #4d4d4d;
	}
.roster_list_day .txt_leave_category_title div:nth-child(3){
	width:calc(34px * 5 - 1px);
	border-left: 1px solid #4d4d4d;
	}

.chart_display_area{
    margin: 20px 20px;
    padding: 6px 6px;
	}

.chart_display_area > .chart{
    width: 100%;
	height:400px;
	}

a[name=list_chart_result-shift_schedule_summary],
a[name=list_chart_result-shift_code_summary],
a[name=list_chart_result-working_hour_summary]{
    position: absolute;
    margin-top: -150px;
	}

.roster_list_left > .btn_roster_name:hover,
.roster_list_left > .btn_roster_name:focus{
	border: 1px solid #999999;
	cursor:pointer;
	}

.cell_roster_time{
    font-size: 11pt;
    font-weight: bold;
    font-family: sans-serif;
    line-height: 48px;
    text-align: center;

	background-image: url(img/icon_time.png);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: 70px 18px;
	}

	/* ---- roster filter item reset ----- */

.f_shift_schedule_summary,
.f_shift_code_summary,
.f_working_hour_summary,
.f_leave_summary{
	display:none;
	}

.f_shift_schedule_summary .txt_day_with_num{
	padding-top: 28px;
	}

.f_shift_schedule_summary .roster_list_right > div:nth-child(1){
	width:3650px;
	}

.f_shift_schedule_summary .roster_list_title_bar_right_reset{
	width:3650px;
	}

.f_shift_schedule_summary .roster_list_right > div > .data .cell_data_view .input_duty_code_reset:hover,
.f_shift_schedule_summary .roster_list_right > div > .data .cell_data_view .input_duty_code_reset:focus{
	cursor:pointer;
	opacity:0.5;
	}

.f_leave_summary .roster_list_day > .txt_shift_with_time > div:nth-child(2){
	color:#4d4d4d;
	}

	/* ---- roster filter grid reset ----- */
.f_shift_schedule_summary .roster_list_right > div:nth-child(1){
	width:calc(34px * 31);
	}
.f_shift_schedule_summary .roster_list_title_bar_right_reset{
	width: calc((34px * 31) + 262px);
	}
.f_shift_code_summary .roster_list_right > div:nth-child(1){
	width:calc(34px * 11);
	}
.f_shift_code_summary .roster_list_title_bar_right_reset{
	width: calc((34px * 11) + 262px);
	}
.f_working_hour_summary .roster_list_right > div:nth-child(1){
	width:calc(34px * 10);
	}
.f_working_hour_summary .roster_list_title_bar_right_reset{
	width: calc((34px * 10) + 262px);
	}
.f_leave_summary .roster_list_right > div:nth-child(1){
	width:calc(34px * 13);
	}
.f_leave_summary .roster_list_title_bar_right_reset{
	width: calc((34px * 13) + 262px);
	}
.f_commission .roster_list_title_bar_right_reset{
	width: calc(((34px * 2) * 7) + 262px);
	}

	/* ---- cell color  ----- */
.roster_list_day > .bg_roster_cell_sun,
.roster_list_day > .bg_roster_cell_sun > div:nth-child(1),
.roster_list_day > .bg_roster_cell_sun > .date > div,
.roster_list_day > .bg_roster_cell_sun > .date > div:nth-child(1),
/*.roster_list_right > div > .data > .bg_roster_cell_sun > .input_shift_display,*/
.bg_roster_cell_sun > input,
.roster_list_right > div > .data .bg_roster_cell_sun > input{
	background-color:#FFE5D5;
	color:#FF6656;
	/*border-bottom: 1px solid #fff;*/
	}
.bg_roster_cell_sun{
	background-color:#FFE5D5;
	color:#FF6656;
	}

.roster_list_day > .txt_shift_with_time > .date > div:nth-child(1),
.roster_list_day > .txt_shift_with_time > .date > div:nth-child(2),
.roster_list_day > .bg_roster_cell_sun > div:nth-child(1){
	border: none;
	}

.bg_yellow,
.roster_list_day > .bg_yellow{ /*old name: bg_cell_leave*/
	background-color:#FFFFE3; /*------ Temp hide -----*/
	}

.bc_left_black,
.roster_list_right > div > .data > .bc_left_black,
.roster_list_day > .bc_left_black{
	border-left:1px solid #4d4d4d;
	}

.bc_left_orange,
.roster_list_right > div > .data > .bc_left_orange,
.roster_list_day > .bc_left_orange{
	border-left:1px solid #F6921E;
	}

	/* ---- popup ----- */
.pp_day_roster_content .form_content_input_area_2field{
	margin-left:18px;
	}
.pp_day_roster_content .name_area{
	margin-left:-36px;
	}
.pp_day_roster_content .name_area .detail_box_name{
	padding:0;
	color: #9a9a9a;
	}
.pp_day_roster_content .name_area .detail_box_name span:nth-child(1){
	color: #4d4d4d;
	}

	/* ---- popup roster ----- */
.input_shift_detail{
	float:none !important;
	width:0 !important;
	height:0 !important;
	border:0 !important;
	font-size:14px !important;
	}
.popup_roster_click .close_popup_roster_click{
    position: absolute;
    right: 0;
    padding: 2px !important;
    width: 24px;
    height: 24px;
    /*margin: 3px;*/
    background-size: 14px;/*16*/
    background-position: center;
    margin-right: 8px;
    background-color: transparent;
    border: 0;
	}
.popup_roster{
	display:none;
	position: fixed !important;
	z-index:4;
	width: 250px !important;/*218*/
	height: auto !important;
	background: #fff;
	border: 1px solid #e6e6e6;
    border-radius: 6px;
	-webkit-box-shadow: 0px 0px 20px #9a9a9a;
    -moz-box-shadow: 0px 0px 20px #9a9a9a;
    box-shadow: 0px 0px 20px #9a9a9a;
	}
.popup_roster_mouseover{
	z-index:5;
	-webkit-box-shadow: 0px 0px 8px #ccc;
    -moz-box-shadow: 0px 0px 8px #ccc;
    box-shadow: 0px 0px 8px #ccc;
	}
.popup_roster_click{
	/*z-index:0;*/
	}
.popup_roster_left_top{
	}
.popup_roster_left_bottom{
	margin-left: calc(-250px - 33px * 1);
    margin-top: 48px;
	}
.popup_roster_right_top{
	}
.popup_roster_right_bottom{
	}

.inf_box{
	width: 100% !important;
    height: 100% !important;
	border:none !important;
	font-size:9pt;
	}
.inf_box > div{
	width: 100% !important;
    float: none !important;
	border-bottom: 1px solid #f3f3f3 !important;
	}
.inf_box > div > div{
	float: left;
	border: none !important;
    /*border-bottom: 1px solid #f3f3f3 !important;*/
    text-align: left !important;
	}
.inf_box > div > div:last-child{
	float: none;
	border: none;
	}
.inf_box > div > div > div{
    float: none !important;
	border:none !important;
	width: 100% !important;
    text-align: left !important;
	}
.inf_box > .head,
.inf_pending_box > .head{
	height: auto;
    background: #ffad38;
    color: #fff;
	height: 100% !important;
	line-height:24px;/*30*/
	padding: 0 6px;/*0 10px*/
    text-align: initial;
	}
.inf_box > div > .code{
	width: calc(100% - 145px) !important;
	padding:2px 6px !important;/*10px*/
	}
.inf_box > div > .time{
	color: #9a9a9a;
	width: 105px !important;
	padding:2px 6px !important;/*10px*/
	}
.inf_box > div > .status{
	width: 40px !important;
	height: 22px !important;/*40px*/
	padding:10px 0 !important;
	background-size:16px !important;/*24px*/
	}

.inf_pending_box{
	width: 100% !important;
    height: 100% !important;
	border:none !important;
	margin-top: 6px;/*16*/
	text-align:left;
	line-height: 15px !important;
	font-size:9pt;
	}

.inf_pending_box > .record{
	position: relative;
	border-bottom:1px solid #e6e6e6;
	}
.pop_pending_approval .inf_pending_box > .record:hover,
.pop_pending_approval .inf_pending_box > .record:focus{
	/*border:1px solid #F6921E;*/
	background-color:#FFFFEE;
	cursor:pointer;
	}
.inf_pending_box > .record > div{
	margin: 0;
    padding: 6px;/*10*/
	}
.inf_pending_box > .record .mult_select{
	display:none;
    position: absolute;
	top: 61px;/*86*/
	}
/*.inf_pending_box > div > .mult_select{
	position: absolute;
	display:none;
    right: 10px;
    bottom: 60px;
    width: 32px;
    height: 32px;
    background: url(img/icon_tick_green.png) no-repeat center;
    background-size: 32px;
	}*/
.inf_pending_box > div > .create{
	margin: 0 6px;/*0 10px*/
	margin-bottom:6px;/*10*/
    padding: 4px 6px;/*4px 10px*/
    margin-left: 30px;
    border-left: 5px solid #cdcdcd;
    background: #f3f3f3;
	}
.inf_pending_box > div > .control{
	display:none;
	}
.inf_pending_box > div > .control button{
	max-width: none;
	width: calc(50% - 6px);
	}
.inf_pending_box .pop_functions_two_btn_center{
	margin: 10px 4px;
	}
.inf_pending_box button{
	width: calc(50% - 18px);/*calc(50% - 32px)*/
	}
.inf_pending_box .content .msg{
	padding-left:20px;/*24*/
	background:url(img/icon_change.png) no-repeat left;
	background-size:15px;/*20*/
	margin-top: 1px;/*6*/
    white-space: normal;
	}
.inf_pending_box .mult_select_approval_area{
	display:none;
	position: absolute;
    bottom: 92px;
    width: 320px;
    right: 0;
    padding: 0;
    left: initial;
    box-shadow: none;
	}

	/* ---- pop roster tight ----- */
.inf_pending_box_tight{
	line-height: 13px !important;
	}

	/* ---- pop shift code ----- */
.pop_shift_code{
	display:none;
	position: fixed;
    z-index: 3;
    right: 48px;
    bottom: 56px;
	width: 264px;

    height: 422px;
    max-height: 422px;

    overflow: auto;
    background: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 20px #9a9a9a;
    -moz-box-shadow: 0px 0px 20px #9a9a9a;
    box-shadow: 0px 0px 20px #9a9a9a;
	/*resize: vertical;*/
	cursor: move;
	}
.pop_shift_code > div{
	padding:4px 10px;
	}
.pop_shift_code > div > .code,
.pop_shift_code > div > .info{
	float:left;
    padding: 10px;
	}
.pop_shift_code > div > .code{
	width:68px;
    text-align: center;
	border:1px solid #e6e6e6
	}
.pop_shift_code > div > .info{
	width:calc(100% - 68px);
    text-align: left;
	}

	/* ---- roster status ----- */

.icon_corner_top_left,
.icon_corner_top_right,
.icon_corner_bottom_left,
.icon_corner_bottom_right{
	position: absolute;
	width: 12px;
	height: 12px;
	background-size: 12px !important;
	}

.icon_corner_top_left{
	top: 0;
	left:0;
	background:url(img/icon_r_leaveancellation.png) no-repeat top left;
	}
.icon_corner_top_right{
	top: 0;
	right:0;
	background:url(img/icon_r_timeoff.png) no-repeat top right;
	}
.icon_corner_bottom_left{
	bottom: 0;
	left:0;
	background:url(img/icon_r_appointmet.png) no-repeat bottom left;
	}
.icon_corner_bottom_right{
	bottom: 0;
	right:0;
	background:url(img/icon_r_ot.png) no-repeat bottom right;
	}
.icon_corner_top_left_s{
	background:url(img/icon_r_leaveancellation_s.png) no-repeat top left;
	background-size: 10px;
	}
.icon_corner_top_right_s{
	background:url(img/icon_r_timeoff_s.png) no-repeat top left;
	background-size: 10px;
	}
.icon_corner_bottom_left_s{
	background:url(img/icon_r_appointmet_s.png) no-repeat top left;
	background-size: 10px;
	}
.icon_corner_bottom_right_s{
	background:url(img/icon_r_ot_s.png) no-repeat top left;
	background-size: 10px;
	}
	/* ---- roster box status ----- */
.icon_r_s_pending{
	background:url(img/icon_r_s_cancelpending.png) no-repeat center;
	}
.icon_r_s_cancelpending{
	background:url(img/icon_r_s_pending.png) no-repeat center;
	}
.icon_r_s_approved{
	background:url(img/icon_r_s_approved.png) no-repeat center;
	}

	/* ---- roster change status ----- */
.r_c_saved{
	border:2px solid #4d4d4d !important;
	}
.r_c_rejected{
	border:2px solid #E5573F !important;
	}
.r_c_approved{
	border:2px solid #88BD52 !important;
	}
.r_c_submitpending{
	border:2px solid #FFE432 !important;
	}
.r_c_saved,
.r_c_rejected,
.r_c_approved,
.r_c_submitpending{
    border-width: 4px !important;
	/*border-style: ridge !important;*/
	font-size: 13px !important;
    line-height: 15px !important;
	}
.r_c_saved .one_line,
.r_c_rejected .one_line,
.r_c_approved .one_line,
.r_c_submitpending .one_line{
    line-height: 30px !important;
	font-size: 13px !important;
	}
.r_c_saved .input_duty_code_reset,
.r_c_rejected .input_duty_code_reset,
.r_c_approved .input_duty_code_reset,
.r_c_submitpending .input_duty_code_reset{
	font-size: 14px;
    line-height: 12px !important;
	}

	/* ---- roster status ----- */
/*  sl absent mrl rd employment_end site_movement */
.r_al{
	background-color:#FFA6E4 !important;
	color:#fff !important;
	}
.r_sl{
	background-color:#C9C9C9 !important;
	color:#fff !important;
	}
.r_abs{
	background-color:#FF6656 !important;
	color:#fff !important;
	}
.r_mrl{
	background-color:#f1c40f !important;/*FFAC38*/
	color:#fff !important;
	}
.r_rd{
	background-color:#fff !important;
	color:#FF6656 !important;
	}
.r_employment_end{
	background-color:#4d4d4d !important;
	color:#fff !important;
	}
.r_site_movement{
	background-color:#9a9a9a !important;
	color:#fff !important;
	}
.r_empty{
	background-color:#FF6656 !important;
	color:#fff !important;
	}
.r_sh_ph{
	background-color:#FFAC38 !important;
	color:red !important;
	}

/*--------------------- appraisal ---------------------*/
.page_appraisal_inquiry .txt_date{
	width: 296px;
	}
.page_appraisal_inquiry .txt_days{
	width: 242px;
	}
.page_appraisal_inquiry .txt_type{
	width: 80px;
	}
.pop_functions_box_size_large{
    /*margin: 10px;*/
	width: 90%;
    height: calc(80% - 28px);
    top: calc(80% / 2 - 33%);
    left: calc(90% / 2 - 41%);
	}
.pop_functions_appraisal .appraisal_form{
	height: calc(100% - 84px);
    overflow-y: auto;
    overflow-x: hidden;
	padding:2px;
	}
.pop_functions_appraisal .appraisal_form .box_1_staff{
	margin-left: 10px;
	margin-top: 18px;
	}
.pop_functions_appraisal .appraisal_form .box_1_staff .box_1_staff_name{
	margin-top: 8px;
	}
.pop_functions_appraisal .appraisal_form .list_bar{
	position:relative;
	}
.pop_functions_appraisal .appraisal_form .info{
	position:relative;
	background-color:#F6F6F6;
	border-radius:6px;
	text-align:left;
	}
.pop_functions_appraisal .appraisal_form .txt_content_area_2line div{
	white-space: normal;
	}
.pop_functions_appraisal .appraisal_form .info > .box_1_staff{
	float:left;
	width:260px;
	}
.pop_functions_appraisal .appraisal_form .info > .btn_more{
	position: absolute;
    display: none;
    height: 22px;
    width: 44px;
	background-color: #fff;
    border: 1px solid #e6e6e6;
    bottom: -10px;
    left: calc(50% - 22px);
    border-radius: 6px;

    background-image: url(img/arrow_bottom.png);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center;
	}
.pop_functions_div .btn_rating_note{
    margin-top: 6px;
	padding: 0px 10px;
    padding-right: 20px;
    line-height: 26px;
    text-align: center;
    border-radius: 100px;
    border: 1px solid #e6e6e6;

    background-image: url(img/list_arrow_down_g.png);
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: calc(100% - 7px);
	}
.pop_functions_div .btn_rating_note + div{
	display:none;
	position: absolute;
    z-index: 1;
    top: 30px;
    right: 0;
    padding: 10px;
    text-align: left;
    border-radius: 6px;
    margin-top: 10px;
    background-color: #f6f6f6;
    border: 1px solid #e6e6e6;
	}
.pop_functions_appraisal .appraisal_form .btn_rating_note:hover,
.pop_functions_appraisal .appraisal_form .btn_rating_note:focus,
.pop_functions_appraisal .appraisal_form .info > .btn_more:hover,
.pop_functions_appraisal .appraisal_form .info > .btn_more:focus,
.pop_functions_div .btn_rating_note:hover,
.pop_functions_div .btn_rating_note:focus{
	border: 1px solid #e6e6e6;
    background-color: #F3F3F3;
	cursor:pointer;
	}
.pop_functions_appraisal .appraisal_form .info > .detail{
	float:left;
	width:calc(100% - 270px);
	}
.pop_functions_appraisal .appraisal_form .info > .detail > div{
	float:left;
	padding:10px;
	width:262px;
	min-height:62px;
	}
.pop_functions_appraisal .appraisal_form .txt_content_area_2line{
	width: 100%;
	margin:0;
	}
.pop_functions_appraisal .appraisal_form .f_feedback_comments .txt_content_area_2line{
	width: calc(100% - 54px);
	margin-left:10px;
	}
.pop_functions_appraisal .appraisal_form .f_feedback_comments .txt_type{
	width: 65%;
	}
.pop_functions_appraisal .appraisal_form .f_feedback_comments .txt_status{
	float: right;
    margin-top: -30px;
	width: 39px;
    height: 40px;
	}
.pop_functions_appraisal .appraisal_form .txt_type{
	width: calc(100% - 194px - 10px);
	}
.pop_functions_appraisal .appraisal_form .txt_status{
	width: 194px;
	height: 24px;
	margin:0;
	background-repeat:no-repeat;
	background-size:contain;
	}
.pop_functions_appraisal .appraisal_form .unanswered{
	border: 1px solid #e6e6e6;
    border-radius: 10px;
    text-align: center;
    color: #9a9a9a;
	font-size: 12px;
	}
.pop_functions_appraisal .list_1_box,
.pop_functions_appraisal .list_1_box > div{
	max-width:100% !important;
	width:100% !important;
	margin:0;
	}
.pop_functions_appraisal .list_1_box{
	border: 1px solid #ffffff !important;
	border-bottom: 1px solid #e6e6e6 !important;
	padding: 12px 8px;
	border-radius:0;
	cursor:default;
	}
.pop_functions_appraisal .f_open_end_questions .list_1_box{
	padding:8px;
	}
.pop_functions_appraisal .list_1_box .point{
	float: left;
    width: 72px;
	color: #9a9a9a;
	}
.pop_functions_appraisal .list_1_box .txt{
	float: left;
    width: calc(100% - 72px);
	}
.pop_functions_appraisal .list_1_box.lv0{
	padding: 0 10px;
	}
.pop_functions_appraisal .list_1_box.lv1{
	cursor:pointer;
	/*padding: 10px 4px;
	color:#9a9a9a;*/
	}
.pop_functions_appraisal .list_1_box.lv1 .txt{
	font-size: 12pt;
	}
.pop_functions_appraisal .list_1_box.lv2{
	cursor:pointer;
	/*padding: 4px;
	background-color:#f6f6f6;
	color:#9a9a9a;*/
	}
.pop_functions_appraisal .list_1_box.no_title{
	background-color:#f6f6f6;
	cursor:default;
	}
.pop_functions_appraisal .list_1_box.lv2 .point{
	padding-left:8px;
	}
.pop_functions_appraisal .list_1_box.lv3 .point{
	padding-left:16px;
	}
.pop_functions_appraisal .list_1_box.lv1:hover,
.pop_functions_appraisal .list_1_box.lv1:focus,
.pop_functions_appraisal .list_1_box.lv2:hover,
.pop_functions_appraisal .list_1_box.lv2:focus,
.pop_functions_appraisal .list_1_box.lv3:hover,
.pop_functions_appraisal .list_1_box.lv3:focus,
.pop_functions_appraisal .list_1_box.lv_touch:hover,
.pop_functions_appraisal .list_1_box.lv_touch:focus{
	border: 1px solid #999999 !important;
	border-radius: 6px !important;
	cursor:pointer;
	}
.pop_functions_appraisal .list_1_box.no_title:hover,
.pop_functions_appraisal .list_1_box.no_title:focus{
	border: 1px solid #ffffff !important;
	border-bottom: 1px solid #e6e6e6 !important;
	border-radius: 0!important;
	cursor:default;
	}
.pop_functions_appraisal #filter_options{
	position:absolute;
	bottom:0;
	left:0;
	}
.pop_functions_appraisal .bar{
	position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 -10px;
    padding: 0;
	list-style: none;
    color: #9a9a9a;;
    background-color: #fff;
    border-top: 1px solid #CDCDCD;
	border-radius: 0 0 6px 6px;
	}
.pop_functions_appraisal .bar li{
	float:left;
    width: calc(100% / 3);
	height:100%;
	padding:6px;
	}
.pop_functions_appraisal .bar li:hover,
.pop_functions_appraisal .bar li:focus,
.pop_functions_appraisal .bar li.active{
	background-color: #ececec;
	cursor:pointer;
	}
.pop_functions_appraisal + .pop_functions_approval .pop_functions_two_btn_center{
	width:100%;
	}

.pop_functions_appraisal .remark{
	padding: 10px;
    border-radius: 6px;
	background-color:#f6f6f6;
	}
.pop_functions_appraisal .remark > .staff{
	float: left;
	margin-top: 8px;
    margin-left: 10px;
    margin-bottom: 12px;
	}
.pop_functions_appraisal .remark > .rate_bar > div{
	float:left;
	width:calc(100% / 6 - 5px);
    margin-left: 6px;
	height:44px;
	font-size: 11pt;
    line-height: 40px;
    text-align: center;
	border-radius:6px;
	border:1px solid #E1E1E1;
	background-color:#fff;
	}
.pop_functions_appraisal .remark > .rate_bar > div:hover,
.pop_functions_appraisal .remark > .rate_bar > div:focus,
.pop_functions_appraisal .remark > .rate_bar > div.selected{
	background-color:#31A2F3;
	color:#fff;
	cursor:pointer;
	}
.pop_functions_appraisal .remark > .rate_bar > div:nth-child(1){
    margin-left: 0;
	}
.pop_functions_appraisal .remark > .remark_bar{
	position:relative;
	}
.pop_functions_appraisal > .detail{
	margin-left:40px;
	margin-top:16px;
	}

	/*f_open_end_questions */
.pop_functions_appraisal .appraisal_form .f_open_end_questions .txt_answer{
	float: left;
	margin-top: 4px;
    margin-left: 46px;
	}
.pop_functions_appraisal .appraisal_form .f_open_end_questions .list_1_box .point{
	width: 40px;
	}
.pop_functions_appraisal .appraisal_form .f_open_end_questions .list_1_box .txt{
	width: calc(100% - 40px);
	}
.pop_functions_appraisal .appraisal_form .f_open_end_questions .txt_type{
	width: calc(100% - 36px - 10px);
	}
.pop_functions_appraisal .appraisal_form .f_open_end_questions .txt_status{
	width: 32px;
	height: 38px;
	margin-left: 4px;
	}

@media only screen and (max-width: 902px){
.pop_functions_appraisal .appraisal_form .info > .box_1_staff,
.pop_functions_appraisal .appraisal_form .info > .detail,
.pop_functions_appraisal .appraisal_form .txt_type{
	width: 100%;
	}
.pop_functions_appraisal .appraisal_form .info > .btn_more{
	display:block;
	}
.pop_functions_appraisal .appraisal_form .txt_status{
	margin-left: 60px;
	}
.pop_functions_appraisal .appraisal_form .list_1_box.lv3 .txt_status{
	margin-top:10px;
	margin-left:67px;/*70*/
	}
.pop_functions_appraisal .bar{
	height:44px;
	}
.pop_functions_appraisal .bar li{
    line-height: 32px;
	}
.pop_functions_appraisal .bar li span{
	display:none;
	}
.pop_functions_appraisal .appraisal_form .box_1_staff{
	margin-top: 10px;
	}
.pop_functions_appraisal .appraisal_form{
    height: calc(100% - 74px);
	}
.pop_functions_appraisal .appraisal_form .info > .detail{
	display:none;
	}
}
@media only screen and (max-width: 704px){
.pop_functions_box_size_large{
    height: calc(80% - 48px);
	}
.pop_functions_appraisal .pop_functions_close{
    left: calc(100% / 2 + 34%) !important;
	}
.pop_functions_appraisal .appraisal_form{
	margin:0 -6px;
	}
.pop_functions_appraisal .appraisal_form .f_open_end_questions .txt_type{
	width: calc(100% - 26px - 10px);
	}
}
/*--------------------- approval ---------------------*/
.pop_functions_box_size_large + .pop_functions_approval{
	margin:0 !important;
	width: 90% !important;
    left: calc(90% / 2 - 41%) !important;
	bottom: calc(100%/2 - 46%);
	}
.pop_functions_box_size_large .pop_functions_close{
    left: calc(100% / 2 + 39%);
	}


/*--------------------- calendar - staffs view ---------------------*/

	/*---- default ----*/
.content_box_calendar_staffs_view + .list_result_area{
	display: block;
    position: relative;
    left: 0;
    opacity: 1;
	}
.content_box_calendar_staffs_view .roster_list_title_bar{
	display: block;
	top:0;
	opacity:1;
	}
	/*-----------------*/

.content_box_calendar_staffs_view{
	margin-top:-8px;/*20*/
	}

.content_box_calendar_staffs_view .list_bar_mt{
	margin-top:-100px;
	}

.content_box_calendar_staffs_view .roster_list_left_tool{
	height:54px;
	}

.content_box_calendar_staffs_view .roster_list_day > div{
	height:54px;
	}

.content_box_calendar_staffs_view .txt_date > .date > div:nth-child(1){
	font-size: 9pt;
    padding-top: 4px;
	}
.content_box_calendar_staffs_view .txt_date > .date > div:nth-child(2){
	font-weight: bold;
    font-size: 12pt;
	}

.content_box_calendar_staffs_view .roster_list_left_tool .header_bar_icon{
	margin: 0;
	margin-top:8px;
	}

.icon_staff_sh{
	background: url(img/btn_hide_roster_detail.png) no-repeat center;
	}
.icon_last_month_c{
	background: url(img/btn_arrow_back.png) no-repeat center;
	}
.icon_next_month_c{
	background: url(img/btn_arrow_right.png) no-repeat center;
	}
.icon_filter{
	background: url(img/btn_filter.png) no-repeat center;
	}

.content_box_calendar_staffs_view .btn_pop_bar{
	display:none;
	}

	/* ---- layout structure ----*/
.content_box_calendar_staffs_view .roster_list_left_tool,
.content_box_calendar_staffs_view .roster_list_left .cell_roster_left{
	width:200px;
	}
.content_box_calendar_staffs_view .roster_list_title_bar_right_reset{
	padding-left:200px;
	width:calc((34px * 33) + 200px);  /* --- from auto size --- */
	}
/* .content_box_calendar_staffs_view .roster_list_right{
	width:calc(100% - 200px);
	} */
	/* --------------------------*/


.content_box_calendar_staffs_view .roster_list_left .txt_photo_area_medium{
	display:block;
	}
.content_box_calendar_staffs_view .roster_list_left .cell_roster_left .txt_content_area_2line{
	width: calc(100% - 44px);
	line-height:15px;
	/*padding-top: 8px;*/
	/*width:100%;*/
	}
/*.content_box_calendar_staffs_view .roster_list_left .cell_roster_left .txt_content_area_2line_width_auto{
	width: calc(100% - 44px);
	}*/

.content_box_calendar_staffs_view .roster_list_left .cell_roster_left .txt_content_area_2line .txt_name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}

.content_box_calendar_staffs_view .roster_list_right > div > .data .day_full:hover,
.content_box_calendar_staffs_view .roster_list_right > div > .data .day_full:active,
.content_box_calendar_staffs_view .roster_list_right > div > .data .day_apm:hover,
.content_box_calendar_staffs_view .roster_list_right > div > .data .day_apm:active{
	cursor:pointer;
	opacity:0.7;
	}

.content_box_calendar_staffs_view .roster_list_right > div > .data .day > div{
	height: 100%;
	line-height: 48px;
    font-size: 14px;
	}
.content_box_calendar_staffs_view .roster_list_right > div > .data .day_apm > div{
	height:24px;
    line-height: 16pt;
    font-size: 14px;
	}
.content_box_calendar_staffs_view .roster_list_right > div > .data .day_apm > div:nth-child(1){
	border-bottom: 1px solid #ffffff;
	}

.content_box_calendar_staffs_view .pop_name{
	display:none;
	position: absolute;
	margin-top: -47px;
    border: 0;
	background-color: rgba(247, 240, 188, 0.75);/*rgba(256, 256, 256, 0.6)*/
    padding: 2px;/*4px*/
    border-radius: 6px;
	line-height: 14px;
	}
.content_box_calendar_staffs_view .pop_name > div:nth-child(1){
	font-size:9pt;
	}
.content_box_calendar_staffs_view .pop_name > div:nth-child(2),
.content_box_calendar_staffs_view .pop_name > div:nth-child(3){
	font-size:8pt;
	}
.content_box_calendar_staffs_view .pop_name > div{
	clear:both;
	max-width: 172px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}
.content_box_calendar_staffs_view .calendar_date_current{
	display:none;
	position: relative;
	text-align:center;
	}
.content_box_calendar_staffs_view .calendar_date_current > div:nth-child(1){
	font-size: 9pt;
    padding-top: 4px;
	color:#9a9a9a;
	}
.content_box_calendar_staffs_view .calendar_date_current > div:nth-child(2){
	font-weight: bold;
    font-size: 12pt;
	color:#F6921E;
	}
.content_box_calendar_staffs_view .calendar_date_current > select{
	position: absolute;
    top: 0;
	left: 0;
    width: 100%;
    height: 100%;
	border: none;
    background: transparent;
	color: transparent;
	text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
	outline:0;
	}
.content_box_calendar_staffs_view .calendar_date_current > select option{
	color: #4d4d4d;
	}

	/* ---- remark_bar ----- */
.content_box_calendar_staffs_view .duty_roster_area{
	margin-top:2px !important;
	}
.content_box_calendar_staffs_view .remark_bar{
	margin-top:22px;
	}
.content_box_calendar_staffs_view .remark_bar > .department{
    position: relative;
	float: left;
	background: url(img/list_arrow_down.png) no-repeat center;
	background-size: 8px;
    background-position: calc(100% - 8px);
    background-color: #F6921E;
    border-radius: 6px;
    width: 200px;
    color: #fff;
    text-align: center;
	padding:4px 0;
	}
.content_box_calendar_staffs_view .remark_bar > .department > select{
    position: absolute;
	left: 0;
    top: 0;
    opacity: 0;
    background-image: none;
    background-color: transparent;
    height: 100%;
    width: 100%;
    padding: 0;
    overflow: hidden;
    border-radius: 0;
    text-align: center;
    text-align-last: center;
	color:#4d4d4d !important;
	}
.content_box_calendar_staffs_view .remark_bar > .remark{
	float: right;
    width: calc(100% - 206px);
	padding:4px 0;
	}
.content_box_calendar_staffs_view .remark_bar > .remark > div{
	float: right;
    margin-right: 4px;
	}
.content_box_calendar_staffs_view .remark_bar > .remark > .i{
	width:14px;
	height:14px;
	border-radius: 10px;
	}
@media (max-width: 767px){
.content_box_calendar_staffs_view .remark_bar{
	margin-top:0;
	}
.content_box_calendar_staffs_view .remark_bar > div{
	width:100% !important;
	}
}


	/* ---- leave status ----- */
.l_approved{
	background-color:#2dcc71 !important;
	color:#fff !important;
	}
.l_pending{
	background-color:#ecd41f !important;
	color:#fff !important;
	}

@media (max-width: 767px){

	/* ---- layout structure ----*/
	.content_box_calendar_staffs_view .roster_list_left_tool,
	.content_box_calendar_staffs_view .roster_list_left{
		width: 44px;
		overflow:hidden;
		}
	.content_box_calendar_staffs_view .roster_list_left_tool,
	.content_box_calendar_staffs_view .roster_list_left .cell_roster_left{
		width:44px;
		overflow:hidden;
		}
	.content_box_calendar_staffs_view .roster_list_title_bar_right_reset{
		padding-left:44px;
		width:calc((34px * 33) + 44px);  /* --- from auto size --- */
		}
	.content_box_calendar_staffs_view .roster_list_right{
		width:calc(100% - 44px);
		}

	/* ---- auto full screen ----*/
	.content_box_calendar_staffs_view {
		width: calc(100% + 40px);
		margin-left: -20px;
		}
	.roster_list_title_bar, .roster_list_time_sheet {
		width: calc(100% - 60px + 20px + 40px);
		}

	/* ---- name ----*/
	.content_box_calendar_staffs_view .roster_list_left_tool > div{
		display:none;
		}
	.content_box_calendar_staffs_view .roster_list_left_tool .calendar_date_current{
		display:block;
		}
	.content_box_calendar_staffs_view .roster_list_left_tool .btn_filter_inquiry_sh{
		display:none;
		}

	.content_box_calendar_staffs_view .btn_pop_bar{
		display:block;
		}
}

@media (max-width: 320px){

	/* ---- full screen ----*/
	.content_box_calendar_staffs_view {
		min-width: 100px;
		width:100%;
		margin-left: 0 !important;
		}
	.content_box_calendar_staffs_view .roster_list_title_bar, .roster_list_time_sheet {
		width: 100%;
		}

	/*.content_box_calendar_staffs_view .roster_list_right {
		width: calc(100% - 44px - 40px);
		}*/
}


/*--------------------- btn_pop_bar ---------------------*/

.btn_pop_bar{
   	position: fixed;
   	z-index:3;
   	right:10px;/*3*/
	bottom:70px;/*248*/
	transition: all 0.3s ease-in 0s;
	}
.btn_pop_bar > div{
	margin:10px 0;
	width:40px;
	height:40px;
	line-height:40px;
	text-align: center;
	background-size:20px;
	background-color:rgba(255,255,255,0.7);
	border-radius: 100px;/*100px 0 0 100px*/
	border:1px solid #CDCDCD;
	-webkit-box-shadow: 0px 0px 8px #ccc;
	-moz-box-shadow: 0px 0px 8px #ccc;
	box-shadow: 0px 0px 8px #ccc;
	cursor:pointer;
	}
.btn_pop_bar img{
	width:20px;
	}
.btn_pop_bar > div:hover,
.btn_pop_bar > div:focus{
	background-color:#F3F3F3;
	}

/*--------------------- show pending detail - full screen ---------------------*/
.btn_show_pending_approval{
	background: url(img/btn_sh_pendingapprovel_sh.png) no-repeat center;
	}

/*--------------------- show shift code ---------------------*/
.btn_show_shift_code{
   	bottom:384px;
	background: url(img/btn_code_remark.png) no-repeat center;
	background-size:20px;
	}

/*--------------------- show pending detail - full screen ---------------------*/
.btn_show_time_shift{
	background: url(img/btn_shift_sh.png) no-repeat center;
	}

	/* ---- toggle class ----- */

@media (min-width: 48em) {

.layout_withpanel{
	padding-left: 0 !important;
	margin-left: -16px !important;
	width: calc(100% - 320px);
	}
.roster_list_title_bar_withpanel,
.roster_list_time_sheet_withpanel{
	width: calc(100% - 380px) !important;
	}
.pop_pending_approval_withpanel{
	right: 0 !important;
	}
.btn_pop_bar_withpanel{
	right: 325px !important;
	}
}

/*--------------------- hide roster detail ---------------------*/

.btn_hide_roster_detail{
	background: url(img/btn_hide_roster_detail.png) no-repeat center;
	}
.roster_list_left .cell_roster_left .txt_content_area_2line_width_auto{
    width: calc(100% - 44px);/*50*/
    margin-top: 1px;
    padding-right: 1px;
	}
.roster_list_left .cell_roster_left .txt_content_area_2line_width_auto .txt_name{
	font-size:10pt;
	line-height: 16px;
	max-height: 31px;
	}

/*--------------------- full screen ---------------------*/

.btn_full_screen{
	background: url(img/btn_full_screen.png) no-repeat center;
	}

@media (max-width: 767px) {/*48em*/
	.btn_full_screen{
		display:none;
	}
}

	/* ---- toggle class ----- */

@media (min-width: 48em) {

/*.content_fullscreen{
    margin: 0;
    padding-top: 24px;
	height:100%;
	margin-bottom:120px;

	margin-left: -18px;
    margin-right: 0px;
    padding-right: 0;
    max-width: calc(100% - 6px);
	}*/

.menu_fullscreen{
	left:0 !important;
	}

#layout.active #menu {
    left: 220px !important;
    width: 220px;
	margin-top: 0;
	}

.logo_desktop_fullscreen{
	display:none;
	}

.menu-link_fullscreen{
	display:block;
	left:0;
	}

.layout_fullscreen{
	padding-left:0 !important;
	margin-left:-16px !important;
	width:100%;
	}

.control_bar_roster_fullscreen{
	left:calc(100%/2 - 270px/2);
	}

.roster_list_title_bar_fullscreen{
	width:calc(100% - 58px);/*calc(100% - 58px + 47px)*/
	}

.btn_reset_roster_fullscreen{
	width:100% !important;
	left:0 !important;
	}

.roster_list_time_sheet_fullscreen{
	width: calc(100% - 58px);
	}

}

	/* -------- For intertrust --------

.detail_box_bottom_2point .detail_box_session1{
	font-size: 7.5pt;
    margin-top: 1px;
	}
.detail_box_bottom_2point .detail_box_session2{
	font-size: 7.5pt;
    margin-top: 21px;
	}
.detail_box_bottom_2point .detail_box_type{
	font-size: 8.5pt;
    line-height: 1.15;
    margin-top: 0;
	}
ul.filter_calendar_reset{
	display:none;
	}*/

/*--------------------- for ie 11 ---------------------*/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/*.pop_functions_box_size_tc .pop_functions_close,
	.pop_functions_box_size_company .pop_functions_close{
		position:static;
		}
	.pop_functions_profile_amend_inquiry .pop_functions_close{
		position:absolute;
		}
	.list_next_approvers .pop_functions_close{
		position:absolute;
		}*/
	.checkbox_reset_txt{
		padding-left:25px;
		}
	.powered_by{
		right: 16px;
		border-radius: 6px 6px 0 0;
		}
	.powered_by_nologin{
		/*margin-top: 570px;  --------- temp to delete for server's problem */
		}

	.roster_list_right > div > .data .btn_duty_code_reset{
		font-size:0;
		/*width: 56px;
		height: 34px;
		background-position: 89% center;
		left: calc(50% - 56px / 2);
		padding: 0 10px;
		padding-right: 20px;*/
		}

	.roster_list_right > div > .data .btn_duty_code_reset:hover,
	.roster_list_right > div > .data .btn_duty_code_reset:focus{
		font-size:0;
		background-image:none;
		}

	.roster_list_day > .txt_single_center > div:nth-child(1){
		padding-top:28px;
		}

}

@supports (-ms-ime-align: auto) {

	.roster_list_right > div > .data .btn_duty_code_reset{
		font-size:0;
		/*width: 56px;
		height: 34px;
		background-position: 89% center;
		left: calc(50% - 56px / 2);
		padding: 0 10px;
		padding-right: 20px;*/
		}

	.roster_list_right > div > .data .btn_duty_code_reset:hover,
	.roster_list_right > div > .data .btn_duty_code_reset:focus{
		font-size:0;
		background-image:none;
		}

}

#colorbox, #cboxOverlay, #cboxWrapper {
    z-index:11000;
}

/*@media only screen and (max-width:704px) and (max-height:850px) and (min-height:711px){
	.pop_functions_box_size_2column {
		top: 42%;

	}
}
*/

/* Custom CSS NPM Multi Select */ 
.multiselect-dropdown .dropdown-btn {
	background-color: #F6921E;
	color: white;
}

.multiselect-dropdown .dropdown-btn .selected-item {
	border: unset !important;
	background: #e9e9e9 !important;
	color: black !important;
	max-width: unset !important;
}

.multiselect-dropdown .dropdown-btn .selected-item a {
	color: #F6921E !important;
}

.dropdown-list {
    background: #F6921E !important;
}

.dropdown-list .filter-textbox {
	color: black !important;
	background-color: white !important;
}

.dropdown-menu{
	width:100%;
}

.multiselect-item-checkbox input[type=checkbox] + div {
	color: white !important;
}

.multiselect-item-checkbox:hover {
	background-color: dodgerblue !important;
}



/* End of Custom CSS NPM Multi Select */ 